import * as _ from 'lodash';

const TimeQueue = () => {
  let API;
  const queue = [];
  let task = null;
  let tHandle;
  function next() {
    // executa a tarefa atual e cria um time para fim de tempo para executar a proxima
    if (task !== null) {
      task.func(); // executar
      task = null; // limpa a tarefa
    }
    if (queue.length > 0) {
      // se a fila ainda estiver cheia
      task = queue.shift(); // configura a proxima tarefa
      tHandle = setTimeout(next, task.time); // e execute-a com base no time definido pelo usuário
    } else {
      API.done = true;
    }
  }
  API = {
    add(func, time, which = 'default') {
      queue.push({ func, time, which });
    },
    qnt(select = 'default') {
      if (queue.length !== 0) {
        return _.filter(queue, (item) => item.which === select).length;
      }

      return 0;
    },
    start() {
      if (queue.length > 0 && API.done) {
        API.done = false; // set state flag
        tHandle = setTimeout(next, 0);
      }
    },
    clear() {
      task = null; // remove a task pendente
      queue.length = 0; // esvazia a fila
      clearTimeout(tHandle); // limpa o timeout
      API.done = true; // altera o status da flag
    },
    done: true,
  };

  return API;
};

export default TimeQueue();
