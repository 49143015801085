import moment from 'moment';
import { findCPF, formatarCPF } from '~/config/utils';

export const getColumns = (
  filtro,
  ActionList,
  corretores,
  equipes,
  ActionListCorretores
) => {
  if (filtro.includes('EQUIPES') && equipes) {
    return [
      {
        name: 'ID',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'Nome',
        selector: 'nm_gestor',
        sortable: true,
      },
      {
        name: 'CPF',
        selector: 'documento',
        sortable: true,
      },
      {
        name: 'Celular',
        selector: 'celular',
        sortable: true,
      },
      {
        name: 'Equipe',
        selector: 'ds_equipe',
        sortable: true,
      },
      {
        name: 'CriadoEm',
        selector: 'created_at',
        cell: (row) =>
          moment(row?.created_at || '').format('DD/MM/YYYY HH:mm:ss'),
        sortable: true,
      },
      {
        name: 'Ações',
        cell: (row) => ActionList(row),
      },
    ];
  }
  if (filtro.includes('CONSULTORES') && corretores) {
    return [
      {
        name: 'ID',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'Consultor',
        selector: 'user.nome_completo',
        sortable: true,
      },

      {
        name: 'CPF',
        cell: (row) =>
          formatarCPF(findCPF(row?.user?.documentos)?.[0]?.nr_documento || ''),
        sortable: true,
      },
      {
        name: 'E-mail',
        selector: 'email',
        sortable: true,
      },
      {
        name: 'Contato',
        selector: 'contato_cargo',
        sortable: true,
      },

      {
        name: 'Equipe',
        selector: 'equipe_vendas.ds_equipe',
        sortable: true,
      },
      {
        name: 'CriadoEm',
        selector: 'created_at',
        cell: (row) =>
          moment(row?.created_at || '').format('DD/MM/YYYY HH:mm:ss'),
        sortable: true,
      },
      {
        name: 'Ações',
        cell: (row) => ActionListCorretores(row),
      },
    ];
  }

  return [];
};
