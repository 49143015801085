import { Redirect, Switch } from 'react-router-dom';
import React from 'react';
import { usePrivate as Private } from '../../config/protected_route';

import Empresa from '../../views/empresa';
import Usuarios from '~/views/usuarios';
import EditarUsuario from '~/views/usuarios/editar-usuario';

import EquipeVendas from '~/views/equipe-vendas';
import AcompanharVendas from '~/views/acompanhar-vendas';
import CorretoresEquipe from '~/views/equipe-vendas/corretores';
import CorretorEditEquipe from '~/views/equipe-vendas/corretores/edit';
import LinkdsVenda from '../../views/links_venda';
import LinksVendaCorretorEquipe from '../../views/links_venda/equipe';
import Configuracoes from '~/views/confiiguracoes';
import Dashboard from '../../views/dashboard';

import { Container } from './styles';
import Sidebar from '~/views/layouts/sidebar';
import NavbarResponsivo from '~/views/layouts/navbar-responsivo';
import useAuth from '~/config/auth_provider/contexto';
import LoadingContent from '~/components/loading';
import BemVindo from '~/views/bem-vindo';
import VendaPme from '~/views/vendas-novas/pme';
import VendaEmpresarial from '~/views/vendas-novas/empresarial';
import MaterialApoio from '~/views/material-apoio';

export default function PrivateRouter() {
  const { routes } = useAuth();

  return (
    <>
      {' '}
      <LoadingContent loading={routes.loading} centralizado>
        <Container>
          <NavbarResponsivo />
          <Sidebar />
          <Switch>
            <Private path="/" exact component={BemVindo} />
            <Private path="/dashboard" component={Dashboard} />
            <Private path="/empresa" component={Empresa} />
            <Private path="/equipe-vendas" component={EquipeVendas} />
            <Private
              path="/acompanhamento-vendas"
              component={AcompanharVendas}
            />
            <Private
              path="/corretor/equipe-vendas/:equipe_venda_id/:content"
              component={CorretoresEquipe}
            />
            <Private
              path="/corretor/:equipe_venda_id/edit/:corretor_id/:content"
              component={CorretorEditEquipe}
            />
            <Private
              path="/corretor/links/:user_id/vendas/:cargo_id/:content"
              component={LinksVendaCorretorEquipe}
            />
            <Private path="/meus-links-venda" component={LinkdsVenda} />
            <Private path="/links-venda" component={LinkdsVenda} />
            <Private path="/venda-pme" component={VendaPme} />
            <Private path="/venda-empresarial" component={VendaEmpresarial} />

            <Private path="/usuarios" component={Usuarios} />
            <Private
              path="/editar-usuario/:user_id"
              parent="/usuarios"
              component={EditarUsuario}
            />
            <Private path="/configuracoes" component={Configuracoes} />

            <Private path="/material-apoio" component={MaterialApoio} />

            <Redirect to="/" />
          </Switch>
        </Container>{' '}
      </LoadingContent>
    </>
  );
}
