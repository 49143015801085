import React from 'react';

import Termos from '../termos';

import { Container } from './styles';

const AuthTermosGoogle = () => {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Container>
      <Termos show={modalShow} onHide={() => setModalShow(false)} />

      <a
        href="#termos"
        className="recuperar-senha"
        onClick={() => setModalShow(true)}
      >
        Política de privacidade e Termos de serviço
      </a>
    </Container>
  );
};

export default AuthTermosGoogle;
