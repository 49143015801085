import styled from 'styled-components';

import bg from '~/assets/img/backgrounds/bg1.png';

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: url(${bg}) no-repeat;
  background-size: cover;
  min-height: 100vh;
`;
