import styled from 'styled-components';

export const Loading = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 20px;
`;

export const ContentSoliciarSenha = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  margin: 45px 94px;
  height: 100%;

  form {
    display: flex;
    align-items: center;
    flex-direction: column;

    h4 {
      color: #0439d6;
      text-align: center;
    }

    strong {
      font-size: 16px;
      color: #000000de;
      text-transform: uppercase;
    }

    p {
      margin-top: 16px;
      align-self: flex-start;

      & + p {
      }
    }

    .rec-senha {
      width: 100%;
      margin-top: 16px;

      input {
        width: 100%;
        border: 1px solid #cdced3;
      }
    }

    div.input-control {
      width: 100%;
      margin-top: 13px;

      label {
        display: flex;
        margin: 0;
        align-items: center;

        input {
          margin-right: 8px;
        }
      }
    }

    button {
      width: 100%;
      margin-top: 28px;
    }

    a {
      margin-top: 32px;
      font-weight: 600;
    }
  }

  .content {
    a {
      text-align: center;
    }
  }

  @media (max-width: 1440px) {
    margin: 32px 48px;
  }

  @media (max-width: 600px) {
    margin: 34px 16px;
  }
`;

export const ContentCodigo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  margin: 45px 94px;
  height: 100%;

  figure {
    border: 1px solid #0b39d7;
    padding: 12px 22px;
    border-radius: 12px;
  }

  form {
    display: flex;
    align-items: center;
    flex-direction: column;

    h4 {
      font-size: 24px;
      color: rgb(4, 57, 214);
      font-weight: 100;
      margin-top: 16px;
    }

    strong {
      font-size: 16px;
      color: #000000de;
      text-transform: uppercase;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0;

      & + p {
      }
    }

    .rec-senha {
      width: 100%;
      margin-top: 16px;

      input {
        width: 100%;
        border: 1px solid #cdced3;
      }
    }

    div.input-control {
      width: 100%;
      margin-top: 13px;

      label {
        display: flex;
        margin: 0;
        align-items: center;

        input {
          margin-right: 8px;
        }
      }
    }

    button {
      width: 100%;
      margin-top: 16px;
    }

    a {
      margin-top: 8px;
      font-weight: 600;
    }
  }

  @media (max-width: 1440px) {
    margin: 32px 48px;
  }

  @media (max-width: 600px) {
    margin: 34px 16px;
  }
`;

export const InputControl = styled.div`
  margin: 16px 0;
  width: 100%;
`;
