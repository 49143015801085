import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #dce3f6;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  margin: 0 0 0 216px;
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;

  @media (max-width: 1530px) {
    margin: 0 0 0 96px;
  }

  @media (max-width: 1124px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 700px) {
    padding: 110px 32px;
    margin: 0;
  }

  @media (max-width: 414px) {
    padding: 110px 16px;
    margin: 0;
  }
`;

export const SideLeft = styled.div`
  h1 {
    font-size: 40px;
    color: #070387;

    b {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 20px;
    color: #070387;
    max-width: 430px;
    margin-bottom: 70px;

    b {
      font-size: 20px;
    }
  }

  p {
    display: flex;
    max-width: 400px;
    margin-bottom: 32px;
    gap: 16px;
    font-size: 18px;
    color: #070387;

    span {
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        color: #070387;
        font-size: 18px;
        padding: 6px;
        font-size: 16px;
        border-radius: 50%;
        height: 32px;
        width: 32px;
      }
    }
  }

  @media (max-width: 768px) {
    p {
      max-width: 100%;
    }
  }
`;

export const SideRight = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  width: 100%;

  figure {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    img {
      height: auto;
      width: 96%;

      @media (max-width: 1600px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 992px) {
    display: none;
  }
`;
