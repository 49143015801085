import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import CryptoJS from 'crypto-js';
import RootReducer from './rootReducer';
import RootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const encrypt = createTransform(
  (inboundState) => {
    if (!inboundState) {
      return inboundState;
    }

    const cryptedText = CryptoJS.AES.encrypt(
      JSON.stringify(inboundState),
      'put the key here'
    );

    return cryptedText.toString();
  },
  (outboundState) => {
    if (!outboundState) {
      return outboundState;
    }

    const bytes = CryptoJS.AES.decrypt(outboundState, 'put the key here');
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  }
);

const persistConfig = {
  whitelist: ['auth'],
  key: 'auth_data',
  storage,
  transforms: [encrypt],
};
const persistedReducer = persistReducer(persistConfig, RootReducer);
const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware, thunk)
);

const persistor = persistStore(store);
sagaMiddleware.run(RootSaga);

export { store, persistor, encrypt };
