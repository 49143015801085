import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
  LOGIN_REQUEST,
  SOLICITAR_SENHA_REQUEST,
  CADASTRO_CORRETOR_REQUEST,
} from '~/config/constantes';
import api from '~/config/api';
import {
  LoginSuccess,
  LoginFailed,
  SolicitarSenhaFailed,
  SolicitarSenhaSuccess,
  CadastroCorretorSuccess,
  CadastroCorretorFailed,
} from './actions';
import { RequestFailed } from '~/config/alert';

export function* AuthLogin(payload) {
  try {
    const token = payload?.payload?.token;
    api.defaults.headers.common.recaptcha = token;
    const response = yield call(
      api.post,
      '/clientes/corretor/login',
      payload.payload
    );
    delete api.defaults.headers.common.recaptcha;
    yield put(LoginSuccess(response));
  } catch (error) {
    yield put(RequestFailed(error));
    yield put(LoginFailed());
  }
}

export function* AuthRecuperarSenha(payload) {
  try {
    const token = payload?.payload?.token;
    api.defaults.headers.common.recaptcha = token;
    const response = yield call(
      api.post,
      '/empresarial/solicitar-nova-senha',
      payload.payload
    );
    delete api.defaults.headers.common.recaptcha;
    // eslint-disable-next-line no-unused-expressions
    payload?.payload?.callback?.();
    yield put(SolicitarSenhaSuccess(response));
  } catch (error) {
    yield put(SolicitarSenhaFailed());
    yield put(RequestFailed(error));
  }
}

export function* CadastroCorretor(payload) {
  const { callback } = payload.payload;
  try {
    const token = payload?.payload?.token;
    api.defaults.headers.common.recaptcha = token;
    const response = yield call(
      api.post,
      '/clientes/corretor/cadastrar',
      payload.payload
    );
    delete api.defaults.headers.common.recaptcha;
    yield put(CadastroCorretorSuccess(response));
    callback(response.data);
  } catch (error) {
    yield put(CadastroCorretorFailed(error));
    yield put(RequestFailed(error));
  }
}

export default all([
  takeLatest(LOGIN_REQUEST, AuthLogin),
  takeLatest(SOLICITAR_SENHA_REQUEST, AuthRecuperarSenha),
  takeLatest(CADASTRO_CORRETOR_REQUEST, CadastroCorretor),
]);
