import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import AuthReducer from './auth/index';
import UtilReducer from './utils';
import UsuarioReducer from './usuarios';
import EmpresaReducer from './empresa';

const rootReducer = combineReducers({
  toastr: toastrReducer,
  auth: AuthReducer,
  util: UtilReducer,
  usuarios: UsuarioReducer,
  empresa: EmpresaReducer,
});

export default rootReducer;
