import styled from 'styled-components';
import {
  red,
  green,
  blue,
  lightBlue,
  darkBlue,
  defaultButton,
} from '../../styles/colors';

function getBgColor(bgColor) {
  switch (bgColor) {
    case 'red':
      return red;
    case 'green':
      return green;
    case 'blue':
      return blue;
    case 'lightBlue':
      return lightBlue;
    case 'darkBlue':
      return darkBlue;
    case 'gray':
      return '#cbcbcb';
    default:
      return defaultButton;
  }
}

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 40px;
  padding: ${(props) => props.padding || '0 15px'};
  background-color: ${(props) => getBgColor(props.bgColor)};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 1.6px;
  color: ${(props) => props.color || '#FFF'};
  border: none;
  border-radius: ${(props) => props.radius || '4px'};
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 22px;
  &:hover,
  &:active {
    color: white;
    filter: brightness(0.9);
  }

  .btn-rounded {
    justify-content: center;
    border-radius: 22px;
  }

  select {
    height: 36px;
  }
`;

// export const Container = styled.button`
//   background: ${red};
//   color: #fff;
//   height: 36px;
//   border-radius: 24px;
//   border: 0;
//   padding: 0 36px;
//   width: 100%;
//   font-weight: 500;
//   transition: background 0.5s;

//   ${(props) =>
//     props.uppercase &&
//     css`
//       text-transform: uppercase;
//     `}

//   &:hover {
//     background: ${shade(0.2, '#166def')};
//   }
// `;
