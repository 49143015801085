import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  td.spanHash {
    word-break: break-all;
  }

  a.linkCopyHash {
    cursor: pointer;
  }

  @media (max-width: 1440px) {
    flex-wrap: wrap;
    padding: 0;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;

    .cardsRight {
      margin: 0 !important;
    }

    .cardsLeft {
      margin: 0 !important;
    }
  }

  .cardsRight {
    width: 100%;
    max-width: 506px;
    min-width: 420px;
    margin: 30px;

    @media (max-width: 1024px) {
      max-width: none;
      min-width: auto;
    }
  }
  .cardsLeft {
    width: 100%;
    max-width: 506px;
    margin: 30px;

    @media (max-width: 1024px) {
      max-width: none;
      min-width: auto;
    }
  }
`;

export const SubContentInlineEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

export const ContentFormulario = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CardImagemEmpresa = styled.label`
  display: flex;
  width: 100%;
  height: 221px;

  box-shadow: 0px 0px 10px -5px #00000055;
  border-radius: 8px;
  background: #f7f9ff;

  div.sideInput {
    border-right: 1px solid #0000001f;
    padding: 14px;
  }

  div.sideLogoEmpresa {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    span {
      color: #0439d6;
    }

    padding: 37px 26px;

    div.icon-info {
      span {
        color: #0439d6;
      }
      i {
        border: 1px solid #d2dbf6;
        font-size: 22px;
        border-radius: 24px;
        color: #d2dbf6;
        padding: 8px;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 32px;
      cursor: pointer;
    }
  }

  @media (max-width: 1440px) {
    max-width: 280px;
    min-width: 280px;
    height: 180px;

    div.sideLogoEmpresa {
      padding: 0;
    }
  }

  @media (max-width: 700px) {
    max-width: initial;
    width: 100%;
    min-width: 280px;
    height: 180px;

    div.sideLogoEmpresa {
      padding: 0;
    }
  }
`;
