/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-cycle */
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import * as DateItens from 'cpf-cnpj-validator';
import { isArray } from 'lodash';
import { getCleanedItemSize } from './util';

export const validaCPF = (document) => DateItens.cpf.isValid(document);

export const validaCNPJ = (document) => DateItens.cnpj.isValid(document);

export const formatarCNPJ = (document) => DateItens.cnpj.format(document);

export const formatarCPF = (document) => DateItens.cpf.format(document);

export const findCPF = (documentos) =>
  documentos.filter((item) => item?.tipo?.nm_tipo === 'CPF');

export function toDate(dateStr) {
  const parts = dateStr?.split('/');
  return new Date(parts?.[2], parts?.[1] - 1, parts?.[0]) || null;
}

export function toDateISO(dateStr) {
  return dateStr?.split?.('/')?.reverse?.()?.join?.('-') || null;
}

export function Meses(mes) {
  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  return meses[mes];
}

export function DownloadLayoutExcel({ title, object }) {
  const checkArray = isArray(object);
  let result = null;
  if (checkArray) {
    result = object;
  } else {
    result = [object];
  }
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(result);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `${title}.${fileExtension}`);
}

DownloadLayoutExcel.propTypes = {
  title: PropTypes.string.isRequired,
  object: PropTypes.object.isRequired,
};

export function ValidacaoImportacaoCustomizada(custom) {
  let validation = {};
  let layout = {};
  let campos = {};

  if (!custom) {
    // validacoes
    validation = {
      nome_completo: 'required|string|min:2|max:150',
      dt_nascimento: 'required|check_date:name',
      documento: 'required|check_cpf',
      doc_responsavel: 'nullable|check_cpf',
      email: 'email',
      telefone: 'nullable|min:10|max:15',
      celular: 'nullable|min:10|max:15',
      sexo: 'required|in_array:M,F',
      movimento: 'required|in_array:I,A,E',
      dt_inclusao: 'required|check_date:name',
    };

    // layout
    layout = {
      NomeCompletoUsuario: '',
      DataDeNascimento: '',
      Genero: '',
      CPFUsuario: '',
      TipoVinculo: '',
      CPFTitular: '',
      NumCelular: '',
      NumTelefoneFixo: '',
      Email: '',
      AcaoMovimentacaoCadastral: '',
      DataMovimentacaoCadastral: '',
    };
    // campos de validacao do excel
    campos = [
      'NomeCompletoUsuario',
      'DataDeNascimento',
      'Genero',
      'CPFUsuario',
      'TipoVinculo',
      'CPFTitular',
      'NumCelular',
      'NumTelefoneFixo',
      'Email',
      'AcaoMovimentacaoCadastral',
      'DataMovimentacaoCadastral',
    ];
  } else {
    validation = {
      nome_completo: 'required|string|min:2|max:150',
      dt_nascimento: 'required|check_date:name',
      documento: 'required|check_cpf',
      doc_responsavel: 'nullable|check_cpf',
      email: 'nullable|email',
      telefone: 'nullable|min:10|max:12',
      celular: 'nullable|min:10|max:12',
      sexo: 'required|in_array:M,F',
      movimento: 'required|in_array:I,A,E',
      dt_inclusao: 'required|check_date:name',
      dt_fim: 'nullable|check_date:name',
      nr_proposta: 'required',
      qnt_dia: 'required|number',
    };

    // layout
    layout = {
      NomeCompletoUsuario: '',
      DataDeNascimento: '',
      Genero: '',
      CPFUsuario: '',
      TipoVinculo: '',
      CPFTitular: '',
      NumCelular: '',
      NumTelefoneFixo: '',
      Email: '',
      AcaoMovimentacaoCadastral: '',
      DataMovimentacaoCadastral: '',
      DataFimVigencia: '',
      NumeroProposta: '',
      QtdDias: '',
    };
    // campos de validacao do excel
    campos = [
      'NomeCompletoUsuario',
      'DataDeNascimento',
      'Genero',
      'CPFUsuario',
      'TipoVinculo',
      'CPFTitular',
      'NumCelular',
      'NumTelefoneFixo',
      'Email',
      'AcaoMovimentacaoCadastral',
      'DataMovimentacaoCadastral',
      'DataFimVigencia',
      'NumeroProposta',
      'QtdDias',
    ];
  }

  return { validation, layout, campos, tamanho: campos.length };
}

export function getErrorsImportacaoCustomizada(custom, result) {
  let errors = {};
  if (!custom) {
    errors = {
      falha_coluna_A: result?.errors?.nome_completo?.[0] || '',
      falha_coluna_B: result?.errors?.dt_nascimento?.[0] || '',
      falha_coluna_C: result?.errors?.sexo?.[0] || '',
      falha_coluna_D: result?.errors?.documento?.[0] || '',
      falha_coluna_F: result?.errors?.doc_responsavel?.[0] || '',
      falha_coluna_G: result?.errors?.celular?.[0] || '',
      falha_coluna_H: result?.errors?.telefone?.[0] || '',
      falha_coluna_I: result?.errors?.email?.[0] || '',
      falha_coluna_J: result?.errors?.movimento?.[0] || '',
      falha_coluna_K: result?.errors?.dt_inclusao?.[0] || '',
    };
  } else {
    errors = {
      falha_coluna_A: result?.errors?.nome_completo?.[0] || '',
      falha_coluna_B: result?.errors?.dt_nascimento?.[0] || '',
      falha_coluna_C: result?.errors?.sexo?.[0] || '',
      falha_coluna_D: result?.errors?.documento?.[0] || '',
      falha_coluna_F: result?.errors?.doc_responsavel?.[0] || '',
      falha_coluna_G: result?.errors?.celular?.[0] || '',
      falha_coluna_H: result?.errors?.telefone?.[0] || '',
      falha_coluna_I: result?.errors?.email?.[0] || '',
      falha_coluna_J: result?.errors?.movimento?.[0] || '',
      falha_coluna_K: result?.errors?.dt_inclusao?.[0] || '',
      falha_coluna_L: result?.errors?.dt_fim?.[0] || '',
      falha_coluna_M: result?.errors?.nr_proposta?.[0] || '',
      falha_coluna_N: result?.errors?.qnt_dia?.[0] || '',
    };
  }

  return errors;
}

export function ConvertOrigial(custom, elemento) {
  let original = {};
  if (custom) {
    original = {
      NomeCompletoUsuario: elemento.nome_completo,
      DataDeNascimento: elemento.dt_nascimento,
      Genero: elemento.sexo,
      CPFUsuario: elemento.documento,
      TipoVinculo: elemento.doc_responsavel ? 'D' : 'T',
      CPFTitular: elemento.doc_responsavel,
      NumCelular: elemento.celular,
      NumTelefoneFixo: elemento.telefone,
      Email: elemento.email,
      AcaoMovimentacaoCadastral: elemento.movimento,
      DataMovimentacaoCadastral: elemento.dt_inclusao || '',
      DataFimVigencia: elemento.dt_fim || '',
      NumeroProposta: elemento.nr_proposta || '',
      QtdDias: elemento.qnt_dia || '',
    };
  } else {
    original = {
      NomeCompletoUsuario: elemento.nome_completo,
      DataDeNascimento: elemento.dt_nascimento,
      Genero: elemento.sexo,
      CPFUsuario: elemento.documento,
      TipoVinculo: elemento.doc_responsavel ? 'D' : 'T',
      CPFTitular: elemento.doc_responsavel,
      NumCelular: elemento.celular,
      NumTelefoneFixo: elemento.telefone,
      Email: elemento.email,
      AcaoMovimentacaoCadastral: elemento.movimento,
      DataMovimentacaoCadastral: elemento.dt_inclusao || '',
    };
  }

  return original;
}

export function MapearInformacoesBancoDados(custom, merge) {
  if (custom) {
    return {
      nome_completo: merge?.NomeCompletoUsuario,
      dt_nascimento: merge?.DataDeNascimento,
      documento: merge?.CPFUsuario,
      doc_responsavel: merge?.CPFTitular || null,
      email: merge?.Email || '',
      telefone: getCleanedItemSize(merge?.NumTelefoneFixo || '')?.clean || '',
      celular: getCleanedItemSize(merge?.NumCelular || '')?.clean || '',
      sexo: merge?.Genero || '',
      movimento: merge?.AcaoMovimentacaoCadastral,
      dt_inclusao: merge?.DataMovimentacaoCadastral,
      dt_fim: merge?.DataFimVigencia || '',
      nr_proposta: merge?.NumeroProposta || '',
      qnt_dia: merge?.QtdDias || '',
    };
  }
  return {
    nome_completo: merge?.NomeCompletoUsuario,
    dt_nascimento: merge?.DataDeNascimento,
    documento: merge?.CPFUsuario,
    doc_responsavel: merge?.CPFTitular || null,
    email: merge?.Email || '',
    telefone: getCleanedItemSize(merge?.NumTelefoneFixo || '')?.clean || '',
    celular: getCleanedItemSize(merge?.NumCelular || '')?.clean || '',
    sexo: merge?.Genero || '',
    movimento: merge?.AcaoMovimentacaoCadastral,
    dt_inclusao: merge?.DataMovimentacaoCadastral, // moment(merge?.DataMovimentacaoCadastral)?.format('YYYY-MM-DD') || '',
  };
}

export const checkIsCPF = (e) => {
  const { clean } = getCleanedItemSize(e);
  return !validaCPF(clean) ? 'Informe um CPF válido.' : undefined;
};

export const checkIsCNPJ = (e) => {
  const { clean } = getCleanedItemSize(e);
  return !validaCNPJ(clean) ? 'Informe um CNPJ válido.' : undefined;
};

export const translatePaymentStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Pendente';
    case 'paid':
      return 'Pago';
    case 'canceled':
      return 'Cancelado';
    default:
      return status;
  }
};

export const translateSignatureStatus = (signature) => {
  switch (signature) {
    case 'true':
      return 'Suspensa';
    case 'false':
      return 'Ativa';
    default:
      return signature;
  }
};
