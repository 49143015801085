import styled from 'styled-components';
import { borderInput, checkboxColor } from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputGroup = styled.div`
  display: block;
  position: relative;
  margin-top: 10px;

  label {
    position: relative;
    display: block;
    padding: 8px 8px 8px 36px;
    width: 100%;
    transition: color 0.5s ease-in;
    transition: background-color 0.7s ease-in;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid ${borderInput};
    border-radius: 22px;
    text-align: left;
    color: #212121;

    &:hover {
      background-color: ${checkboxColor};
      color: white;
    }

    &:after {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      font-family: 'Font Awesome 5 Pro';
      color: ${checkboxColor};
      background: #fff;
      content: '';
      border: 1px solid ${borderInput};
      border-radius: 50%;
      transition: all 0.5s ease-in;
      z-index: 2;
      cursor: pointer;
    }
  }

  input:checked ~ label {
    color: #fff;
    background: ${checkboxColor};

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      border-color: #fff;
      content: '\f00c';
      z-index: 4;
    }
  }

  input {
    width: 24px;
    height: 24px;
    order: 1;
    z-index: 2;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
`;
