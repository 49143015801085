/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Check = (Component, state) => (props) => {
  if (!Component) return null;

  if (state.auth.access_token !== '') return <Component {...props} />;

  const redirectProps = {
    to: {
      pathname: '/login',
      // eslint-disable-next-line react/destructuring-assignment
      // eslint-disable-next-line react/prop-types
      // eslint-disable-next-line react/destructuring-assignment
      state: { from: props.location },
    },
  };

  return <Redirect {...redirectProps} />;
};

export function usePrivate({ component, children, ...rest }) {
  const state = useSelector((st) => st);

  return <Route {...rest} render={Check(component, state)} />;
}

export function usePublic() {}
