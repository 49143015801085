import React, { useState } from 'react';
import ButtonRound from '~/components/button_round';
import CustomInputMask from '~/components/mask_input';
import InputForm from '~/components/input_form';
import useCEP from '~/config/useCEP';
import IfComponent from '~/components/if_component';
import { uriCliente } from '~/config/api_apollo';

export default function StepTwo({
  register,
  trigger,
  setShowForm,
  getValues,
  errors,
  setcontentForm,
  contentForm,
}) {
  const [cep, setCep] = useState('');
  const { data: dataCEP, loading } = useCEP(
    cep,
    uriCliente[process.env.NODE_ENV]
  );

  const buscarCEP = (e) => setCep(e.target.value.replace(/[^\d]+/g, ''));

  return (
    <>
      <InputForm
        labelName="CEP"
        required
        loading={loading}
        validacao={errors?.cep}
        mensagemDeErro="Informe o CEP"
      >
        <CustomInputMask
          name="cep"
          mask="99999-999"
          register={register({ required: true })}
          maskChar="_"
          onChange={buscarCEP}
          defaultValue={contentForm?.cep || ''}
          className="form-control"
          placeholder="Informe o CEP"
        />
      </InputForm>
      <IfComponent hide={!dataCEP?.buscarcep?.id}>
        <InputForm
          labelName="Endereço"
          required
          validacao={errors?.endereco}
          mensagemDeErro="Informe o endereço"
        >
          <input
            type="text"
            name="endereco"
            defaultValue={contentForm?.endereco || ''}
            className="form-control"
            value={dataCEP?.buscarcep?.te_logradouro || ''}
            ref={register({ required: true })}
            placeholder="endereco"
          />
        </InputForm>
        <InputForm
          labelName="Número"
          required
          validacao={errors?.numero}
          mensagemDeErro="Informe o número"
        >
          <input
            type="text"
            placeholder="Informe o número"
            className="form-control"
            defaultValue={contentForm?.numero || ''}
            name="numero"
            ref={register({ required: true })}
          />
        </InputForm>
        <InputForm
          labelName="Bairro"
          required
          validacao={errors?.bairro}
          mensagemDeErro="Informe o bairro"
        >
          <input
            type="text"
            className="form-control"
            name="bairro"
            readOnly
            defaultValue={
              dataCEP?.buscarcep?.te_bairro || contentForm?.bairro || ''
            }
            ref={register({ required: true })}
          />
        </InputForm>
        <InputForm
          labelName="Cidade"
          required
          validacao={errors?.cidade_id}
          mensagemDeErro="Informe a cidade"
        >
          <input
            type="text"
            name="cidade"
            readOnly
            className="form-control"
            value={
              dataCEP?.buscarcep?.cidade?.nm_cidade || contentForm?.cidade || ''
            }
            ref={register({ required: true })}
          />
          <input
            type="hidden"
            placeholder="-"
            name="cidade_id"
            className="form-control"
            readOnly
            value={
              dataCEP?.buscarcep?.cidade?.id || contentForm?.cidade_id || ''
            }
            ref={register({ required: true })}
          />
        </InputForm>
        <InputForm
          labelName="Estado"
          required
          validacao={errors?.estado}
          mensagemDeErro="Informe o estado"
        >
          <input
            type="text"
            placeholder="Estado"
            name="estado"
            className="form-control"
            readOnly
            value={
              dataCEP?.buscarcep?.cidade?.sg_cidade || contentForm?.estado || ''
            }
            ref={register({ required: false })}
          />
        </InputForm>
        <InputForm labelName="Complemento">
          <input
            type="text"
            defaultValue={contentForm?.complemento || ''}
            placeholder="Ponto de referência/Apartamento/Bloco"
            className="form-control"
            name="complemento"
            ref={register({ required: false })}
          />
        </InputForm>
      </IfComponent>
      <IfComponent hide={!dataCEP?.buscarcep?.id && !contentForm?.cidade}>
        <ButtonRound
          type="button"
          name="login-submit"
          id="login-submit"
          value="Log In"
          onClick={async () => {
            const goOn = await trigger();
            const values = await getValues();
            if (goOn) {
              setcontentForm((old) => ({ ...old, ...values }));
              setShowForm('three');
            }
          }}
        >
          avançar
        </ButtonRound>
      </IfComponent>
    </>
  );
}
