import styled from 'styled-components';

export const Container = styled.div`
  font-size: 14px;
  margin-top: 112px;

  > a {
    margin-right: 12px;
    font-size: 14px;
  }

  @media (max-width: 1440px) {
    margin-top: 48px;
  }

  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
