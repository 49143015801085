/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import {
  translatePaymentStatus,
  translateSignatureStatus,
} from '~/config/utils';

import {
  ContentModalCliente,
  AssinaturaHeader,
  Assinatura,
  ButtonsGroup,
  DivHistory,
  DivTable,
  Table,
  TableHeader,
} from './styles';

export default function ModalHistoricoAssinatura({
  elemento,
  show,
  handleClose,
}) {
  const [elements, setElements] = useState([]);

  const filterFaturas = () => {
    const newObject = [];

    // Clonando o objeto elemento para dentro da variavel teste, para não ser realizado o splice no array original
    const copy = elemento?.atualizacao_pagamento?.map((item) => ({ ...item }));

    copy?.map((item, key) => {
      // Faz o agrupamento dos objetos com o codigo de cobranca
      const filter = copy?.filter(
        (value) => value?.cod_cobranca === item?.cod_cobranca
      );
      // // Adiciona o grupo de objetos ao novo objeto
      newObject.push(filter);
      // // Verifica se foi encontrado um grupamento com mais de 1 elemento, e exclui o mesmo do objeto elemento
      filter.length !== 1 && copy.splice(key, 1);
      return item;
    });
    // // Seta o valor no estado "elements"
    setElements(newObject);
    return newObject;
  };

  useEffect(() => {
    console.log('elemento', elemento);
    filterFaturas();
  }, [elemento]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Histórico de faturas - Plano{' '}
          {elemento?.pedido?.itempedido?.assinatura?.subcontrato?.plano?.plano}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContentModalCliente>
          <Assinatura>
            <AssinaturaHeader
              status={translateSignatureStatus(
                elemento?.pedido?.itempedido?.assinatura?.suspenso
              )}
            >
              <p>Status atual desta assinatura: </p>
              <span>
                {translateSignatureStatus(
                  elemento?.pedido?.itempedido?.assinatura?.suspenso
                )}
              </span>
            </AssinaturaHeader>
          </Assinatura>
          <DivHistory>
            <DivTable>
              <Table>
                <TableHeader>
                  <th style={{ width: '250px' }}>Cód. Assinatura</th>
                  <th style={{ width: '250px' }}>Status do pagamento</th>
                  <th style={{ width: '200px' }}>Data da Fatura</th>
                </TableHeader>
                {elements?.map((fatura) => {
                  return (
                    <tr>
                      <td>
                        {elemento?.atualizacao_pagamento[0]?.cod_assinatura}
                      </td>
                      <td>{translatePaymentStatus(fatura[0]?.status)}</td>
                      <td>
                        {moment(fatura[0]?.created_at).format(
                          'DD/MM/YYYY hh:mm'
                        )}
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </DivTable>
          </DivHistory>
        </ContentModalCliente>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button
            variant="secondary"
            className="button-fechar"
            onClick={handleClose}
          >
            Fechar
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  );
}
