/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as S from './styles';

export default function FormSection({
  nome,
  LinkTo,
  buttonName,
  nomeLink,
  btnAction,
  className,
  children,
  formClass,
  info,
  alert,
  arrayBtnOn = false,
  arrayBtnArray = [],
}) {
  const renderItemsBtn = (arrayItems) =>
    arrayItems
      ?.filter((item) => item?.show)
      ?.map((itemBtn) => (
        <button
          key={JSON.stringify(itemBtn)}
          className={`link ${itemBtn.className}`}
          type="button"
          style={{ marginRight: '10px' }}
          onClick={itemBtn.btnAction}
        >
          {itemBtn.buttonName}
        </button>
      ));
  return (
    <S.Container className={`mb-4 ${formClass}`}>
      <S.Title>
        <h6>
          {nome}
          {info && <span className="info">{info}</span>}
          <span className="alerta">{alert}</span>

          <div className="links">
            {arrayBtnOn && renderItemsBtn(arrayBtnArray)}

            {btnAction ? (
              <button
                className={`link ${className}`}
                type="button"
                onClick={btnAction}
              >
                {buttonName}
              </button>
            ) : null}

            {LinkTo ? (
              <Link className="link" to={LinkTo}>
                {nomeLink}
              </Link>
            ) : null}
          </div>
        </h6>
      </S.Title>
      {children}
    </S.Container>
  );
}

FormSection.propTypes = {
  nome: PropTypes.string,
  info: PropTypes.string,
  alert: PropTypes.string,
  LinkTo: PropTypes.string,
  nomeLink: PropTypes.string,
  buttonName: PropTypes.string,
  btnAction: PropTypes.func,
  arrayBtnOn: PropTypes.bool,
  arrayBtnArray: PropTypes.array,
  className: PropTypes.string,
  formClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
  ]),
};

FormSection.defaultProps = {
  nome: '',
  info: '',
  alert: '',
  LinkTo: '',
  nomeLink: '',
  buttonName: '',
  className: '',
  formClass: 'form-section',
  btnAction: null,
  children: '',
  arrayBtnOn: false,
  arrayBtnArray: [],
};
