import { PURGE } from 'redux-persist';
import {
  CAD_USUARIO_REQUEST,
  CAD_USUARIO_SUCCESS,
  CAD_USUARIO_FAILED,
  UPDATE_USUARIO_REQUEST,
  UPDATE_USUARIO_SUCCESS,
  UPDATE_USUARIO_FAILED,
  CAD_USUARIO_CARGO_REQUEST,
  CAD_USUARIO_CARGO_SUCCESS,
  CAD_USUARIO_CARGO_FAILED,
  UPDATE_USUARIO_DADOS_REQUEST,
  UPDATE_USUARIO_DADOS_SUCCESS,
  UPDATE_USUARIO_DADOS_FAILED,
  UPDATE_USUARIO_FOTO_REQUEST,
  UPDATE_USUARIO_FOTO_SUCCESS,
  UPDATE_USUARIO_FOTO_FAILED,
  UPDATE_USUARIO_SENHA_REQUEST,
  UPDATE_USUARIO_SENHA_SUCCESS,
  UPDATE_USUARIO_SENHA_FAILED,
} from '~/config/constantes';

export const INITIAL_STATE_UTIL = {
  load_validar: false,
};

function UsuarioReducer(state = INITIAL_STATE_UTIL, action) {
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE_UTIL;

    case CAD_USUARIO_REQUEST:
      return {
        ...state,
        load_validar: true,
      };

    case CAD_USUARIO_SUCCESS:
      return {
        ...state,
        load_validar: false,
      };

    case CAD_USUARIO_FAILED:
      return {
        ...state,
        load_validar: false,
      };

    case UPDATE_USUARIO_REQUEST:
      return {
        ...state,
        load_validar: true,
      };

    case UPDATE_USUARIO_SUCCESS:
      return {
        ...state,
        load_validar: false,
      };

    case UPDATE_USUARIO_FAILED:
      return {
        ...state,
        load_validar: false,
      };

    case CAD_USUARIO_CARGO_REQUEST:
      return {
        ...state,
        load_validar: true,
      };

    case CAD_USUARIO_CARGO_SUCCESS:
      return {
        ...state,
        load_validar: false,
      };

    case CAD_USUARIO_CARGO_FAILED:
      return {
        ...state,
        load_validar: false,
      };

    case UPDATE_USUARIO_DADOS_REQUEST:
      return {
        ...state,
        load_validar: true,
      };

    case UPDATE_USUARIO_DADOS_SUCCESS:
      return {
        ...state,
        load_validar: false,
      };

    case UPDATE_USUARIO_DADOS_FAILED:
      return {
        ...state,
        load_validar: false,
      };

    case UPDATE_USUARIO_FOTO_REQUEST:
      return {
        ...state,
        load_validar: true,
      };

    case UPDATE_USUARIO_FOTO_SUCCESS:
      return {
        ...state,
        load_validar: false,
      };

    case UPDATE_USUARIO_FOTO_FAILED:
      return {
        ...state,
        load_validar: false,
      };

    case UPDATE_USUARIO_SENHA_REQUEST:
      return {
        ...state,
        load_validar: true,
      };

    case UPDATE_USUARIO_SENHA_SUCCESS:
      return {
        ...state,
        load_validar: false,
      };

    case UPDATE_USUARIO_SENHA_FAILED:
      return {
        ...state,
        load_validar: false,
      };

    default:
      return state;
  }
}

export default UsuarioReducer;
