import React from 'react';
import * as dotenv from 'dotenv';
import { ApolloProvider } from '@apollo/client';
import { ToastProvider } from 'react-toast-notifications';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from './modules/index';
import Routes from './routes/index';
import GlobalSyle from './styles/global';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-credit-cards/es/styles-compiled.css';
import ClienteApollo from './config/api_apollo';
import { AuthProvider } from './config/auth_provider';

dotenv.config();

const App = () => (
  <Provider store={store}>
    <ApolloProvider client={ClienteApollo}>
      <PersistGate loading={<span>Loading!</span>} persistor={persistor}>
        <ToastProvider autoDismissTimeout={9000}>
          <AuthProvider>
            <GlobalSyle />
            <Routes />
          </AuthProvider>
        </ToastProvider>
      </PersistGate>
    </ApolloProvider>
  </Provider>
);
export default App;
