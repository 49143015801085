import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup } from './styles';

export default function Checkbox({ children, label, for_id }) {
  return (
    <InputGroup>
      {children}
      <label htmlFor={for_id}>{label}</label>
    </InputGroup>
  );
}

Checkbox.propTypes = {
  for_id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};
