import React from 'react';
import ReactLoading from 'react-loading';

import { Container } from './styles';

const ButtonRound = ({
  children,
  type = 'button',
  className,
  onClick,
  padding,
  radius = '22px',
  bgColor = 'red',
  notLoad = false,
  ...props
}) => {
  const { disabled } = props;

  return (
    <Container
      type={type}
      disabled={disabled}
      className={className}
      padding={padding}
      radius={radius}
      bgColor={bgColor}
      onClick={!disabled && onClick}
      {...props}
    >
      {disabled && !notLoad ? (
        <ReactLoading type="bubbles" color="#ffff" />
      ) : (
        children
      )}
    </Container>
  );
};

export default ButtonRound;
