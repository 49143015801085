import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './styles.css';

export default function Captcha({
  callback,
  onExpired,
  onErrored,
  referencia,
  size = 'normal',
}) {
  return (
    <ReCAPTCHA
      size={size}
      className="captcha"
      onExpired={onExpired}
      onErrored={onErrored}
      sitekey={process.env.REACT_APP_RECAPTCHA}
      onChange={callback}
      ref={referencia}
    />
  );
}
