import React from 'react';
import { Modal } from 'react-bootstrap';
import FormSection from '../form_section';
import { ModalCard } from './styles';
import IfComponent from '../if_component';
import Button from '../button';

export default function ModalBase({
  title,
  show,
  callback,
  handleSubmit = () => {},
  loading = false,
  children,
  showButton = true,
  close = () => {},
  id = 'modal',
  tamanhoCard = '500px',
  nameBtn = 'Salvar',
}) {
  const onSubmit = (data) => {
    callback(data);
  };

  const handleClose = () => {
    close();
  };
  return (
    <ModalCard
      id={id}
      tamanhoCard={tamanhoCard}
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Body>
        <FormSection nome={title}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {[children]}

            <IfComponent hide={!showButton}>
              <Button
                load={loading}
                disabled={loading}
                radius="22px"
                type="submit"
              >
                {' '}
                {nameBtn}{' '}
              </Button>
            </IfComponent>
          </form>
        </FormSection>
      </Modal.Body>
    </ModalCard>
  );
}
