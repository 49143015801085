/* eslint-disable consistent-return */
export const findCargos = (id, cargo) =>
  cargo?.filter((data) => data?.cargo === id);

export const findCPF = (documentos) =>
  documentos?.filter((item) => item?.tipo?.nm_tipo === 'CPF');

export const findCNPJ = (documentos) =>
  documentos?.filter((item) => item?.tipo?.nm_tipo === 'CNPJ');

export const popularFormularioData = ({
  setdataForm,
  show,
  reset,
  dataForm,
  cargos,
}) => {
  if (!show.edit) {
    return null;
  }

  if (!show.edit) {
    setdataForm({});
  }

  if (
    show.data !== undefined &&
    show.data.length !== 0 &&
    dataForm.id === undefined
  ) {
    if (
      show.data.email_responsavel &&
      dataForm.email_responsavel === undefined
    ) {
      setdataForm(show.data);
      reset(show.data);
    }

    /**
     * Mapeamento para popular o formulário de edição quando um usuário já tem cadastro
     */

    if (show.data.contato_cargo) {
      setdataForm(show.data);
      reset({
        cargo_id: JSON.stringify(findCargos(show.data.cargo.cargo, cargos)[0]),
        telefone_responsavel: show.data.contato_cargo,
        email_responsavel: show.data.email,
        cargo_user_id: show.data.id,
        user_id: show.data.user.id,
        nome_responsavel: show.data.user.nome_completo,
        cpf_responsavel: findCPF(show.data.user.documentos)[0].nr_documento,
        dt_nascimento_responsavel: show.data.user.dt_nascimento,
      });
    }
  }
};
