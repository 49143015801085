/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { CadastroCorretorRequest } from '~/modules/auth/actions';
import { BackInt } from './styles';
import StepOne from './steps-cadastrar/dados-empresa';
import StepTwo from './steps-cadastrar/dados-endereco';
import StepThree from './steps-cadastrar/dados-responsavel';
import IfComponent from '~/components/if_component';

const Cadastro = ({ setCadastro, recaptchaRef }) => {
  const [showTermo, setShowTermo] = useState(false);

  const dispatch = useDispatch();
  const [contentForm, setcontentForm] = useState({});
  const [showForm, setShowForm] = useState('one');
  const { register, control, trigger, getValues, errors } = useForm({
    defaultValues: {},
  });
  const state = useSelector((st) => st);

  const onSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    const callback = () => {};
    dispatch(
      CadastroCorretorRequest({ ...data, type: 'CADASTRAR', token, callback })
    );
    // eslint-disable-next-line no-unused-expressions
    recaptchaRef.current?.reset();
  };

  const elements = {
    setShowForm,
    register,
    loading: state.auth.loading,
    control,
    trigger,
    setcontentForm,
    getValues,
    errors,
    contentForm,
    onSubmit,
    showTermo,
    setShowTermo,
  };
  return (
    <form id="login-form" className="text-center">
      <IfComponent hide={showForm !== 'one'}>
        <BackInt onClick={() => setCadastro('caminho')}>
          <i className="far fa-chevron-left" /> Voltar
        </BackInt>
        <h3 className="title">Dados Empresa</h3>
        <StepOne {...elements} />
        <p>
          Ja tem uma conta?{' '}
          <span
            role="button"
            className="button-blue"
            onClick={() => {
              setCadastro('login');
            }}
          >
            Fazer login
          </span>
        </p>
      </IfComponent>
      <IfComponent hide={showForm !== 'two'}>
        <BackInt onClick={() => setShowForm('one')}>
          <i className="far fa-chevron-left" /> dados da empresa
        </BackInt>
        <h3 className="title">Dados Endereço</h3>
        <StepTwo {...elements} />
      </IfComponent>
      <IfComponent hide={showForm !== 'three'}>
        <BackInt onClick={() => setShowForm('two')}>
          <i className="far fa-chevron-left" /> dados endereço
        </BackInt>
        <h3 className="title">Dados Responsável</h3>
        <StepThree {...elements} />
      </IfComponent>
    </form>
  );
};

export default Cadastro;
