/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Button from '~/components/button';

import { Container, Content } from './styles';

const Caminho = ({ setShowForm }) => {
  const [selected, setSelected] = useState(null);

  return (
    <Container>
      <Content>
        <form action="">
          <h1>Selecione uma opção para realizar seu cadastro</h1>
          <label htmlFor="cadastrar">
            <input
              type="radio"
              name="tipo"
              id="cadastrar"
              onChange={() => setSelected('cadastro')}
            />
            Cadastrar minha corretora (PJ) e me tornar uma plataforma.
          </label>
          <label htmlFor="vincular">
            <input
              type="radio"
              name="tipo"
              id="vincular"
              onChange={() => setSelected('vincular')}
            />
            Me vincular a uma platoforma (PJ) já cadastrada.
          </label>
          <Button
            type="button"
            bgColor="red"
            radius="24px"
            onClick={() => setShowForm(selected)}
          >
            Próximo passo
          </Button>
          <a href="#" onClick={() => setShowForm('login')}>
            Fazer login
          </a>
        </form>
      </Content>
    </Container>
  );
};

export default Caminho;
