import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 16px;

  .Link-check {
    margin: 5px;
  }

  img.edit {
    border: 0;
    margin-right: 8px;
  }

  button.edit {
    border: 0;
    color: #0761c1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 22px;
    height: 22px;
    background: transparent;

    &:hover {
      background: #0761c1;
      background: #0761c140;
      border-radius: 4px;
    }
  }

  button.remove {
    border: 0;
    color: #c10740;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    width: 22px;
    height: 22px;
    background: transparent;

    &:hover {
      background: #c10740;
      background: #c1074040;
      border-radius: 4px;
    }
  }
`;
