/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';

import InputRound from '~/components/input_round';
import Checkbox from '~/components/checkbox';

import CurrencyInput from '~/components/currencyInput';
import { ContentCardProduto, LabelError, ContentItem } from './styles';

export default function CardProdutos({
  item,
  key,
  index,
  register,
  error,
  message,
}) {
  const [valorProduto, setValorProduto] = useState(0);
  const [checked, setchecked] = useState(false);
  const _error_checked = error?.[index]?.checked || undefined;
  const _error_valor = error?.[index]?.valor || undefined;

  return (
    <ContentItem>
      <ContentCardProduto key={key}>
        <Checkbox for_id={item.identificador} label={item.produto}>
          <input
            value={checked}
            onChange={(event, data) => {
              setchecked(!checked);
            }}
            name={`produtos[${index}].checked`}
            ref={register()}
            id={item.identificador}
            type="checkbox"
          />
          <input
            type="hidden"
            ref={register()}
            name={`produtos[${index}].produto`}
            value={item.identificador}
          />
        </Checkbox>
        <InputRound label={`Valor mês/${item.tipo}:`}>
          <input
            type="text"
            style={{ width: '20%' }}
            readOnly
            value={item.value?.applyBRLNumber?.()}
          />
          <input
            type="hidden"
            ref={register({ required: true })}
            name={`produtos[${index}].valor`}
            value={item.value}
          />
        </InputRound>
      </ContentCardProduto>
      {_error_checked && (
        <LabelError>Você deve selecionar um produto</LabelError>
      )}
      {_error_valor && <LabelError>Você deve informar um valor</LabelError>}
    </ContentItem>
  );
}
