/* eslint-disable no-unused-expressions */
import { call, all, put, takeLatest } from 'redux-saga/effects';
import {
  CAD_USUARIO_REQUEST,
  UPDATE_USUARIO_REQUEST,
  CAD_USUARIO_CARGO_REQUEST,
  UPDATE_USUARIO_DADOS_REQUEST,
  UPDATE_USUARIO_FOTO_REQUEST,
  UPDATE_USUARIO_SENHA_REQUEST,
} from '../../config/constantes';
import api from '../../config/api';
import { AlterarSenhaUpdateToken } from '../auth/actions';

import { RequestFailed, RequestSuccess } from '~/config/alert';
import { OpenCloseModal } from '../utils/actions';
import {
  CadUsuarioFailed,
  CadUsuarioSucess,
  UpdateUsuarioSucess,
  UpdateUsuarioFailed,
  CadUsuarioCargoSucess,
  CadUsuarioCargoFailed,
  UpdateUsuarioDadosSucess,
  UpdateUsuarioDadosFailed,
  UpdateUsuarioFotoSucess,
  UpdateUsuarioFotoFailed,
  // UpdateUsuarioSenhaRequest,
  UpdateUsuarioSenhaFailed,
  UpdateUsuarioSenhaSuccess,
} from './actions';

/**
 * FUNÇÃO DE CADASTRAR DO USUÁRIO
 * @param payload
 */
export function* CadastrarUsuarioRequest(payload) {
  const { atualiarUsuarios } = payload.payload;
  try {
    const response = yield call(
      api.post,
      '/empresarial/usuario/store',
      payload.payload
    );
    yield put(RequestSuccess(response));
    yield put(CadUsuarioSucess(response));
    atualiarUsuarios();
  } catch (e) {
    yield put(RequestFailed(e));
    yield put(CadUsuarioFailed());
  }
}

/**
 * FUNÇÃO DE O ADMINISTRADOR ATUALIZAR DO USUÁRIO
 * @param payload
 */
export function* UpdateUsuarioRequest(payload) {
  const { atualiarUsuario } = payload.payload;
  try {
    const response = yield call(api.put, '/empresarial/usuario/update', null, {
      params: payload.payload,
    });
    yield put(RequestSuccess(response));
    yield put(UpdateUsuarioSucess(response));
    atualiarUsuario();
  } catch (e) {
    yield put(RequestFailed(e));
    yield put(UpdateUsuarioFailed());
  }
}

/**
 * FUNÇÃO DE CADASTRAR/ATUALIZAR CARGO DO USUÁRIO
 * @param payload
 */
export function* CadastrarUsuarioCargoRequest(payload) {
  const { atualiarCargos } = payload.payload;
  try {
    const response = yield call(
      api.post,
      '/empresarial/usuario/cargo/store',
      payload.payload
    );
    yield put(RequestSuccess(response));
    yield put(CadUsuarioCargoSucess(response));
    atualiarCargos();
  } catch (e) {
    yield put(RequestFailed(e));
    yield put(CadUsuarioCargoFailed());
  }
}

/**
 * FUNÇÃO DE O USUÁRIO ATUALIZAR SEUS DADOS
 * @param payload
 */
export function* UpdateUsuarioDadosRequest(payload) {
  try {
    const response = yield call(
      api.put,
      '/empresarial/usuario/dados/update',
      null,
      { params: payload.payload }
    );
    yield put(RequestSuccess(response));
    yield put(UpdateUsuarioDadosSucess(response));
    payload?.payload?.atualizar();
  } catch (e) {
    yield put(RequestFailed(e));
    yield put(UpdateUsuarioDadosFailed());
  }
}

/**
 * FUNÇÃO DE CADASTRAR/ATUALIZAR FOTO DO USUÁRIO
 * @param payload
 */
export function* UpdateUsuarioFotoRequest(payload) {
  try {
    const response = yield call(
      api.post,
      '/empresarial/usuario/foto/store',
      payload.payload
    );
    yield put(RequestSuccess(response));
    yield put(UpdateUsuarioFotoSucess(response));
  } catch (e) {
    yield put(RequestFailed(e));
    yield put(UpdateUsuarioFotoFailed());
  }
}

export function* AtualizarSenhaLogada(payload) {
  try {
    // console.log(payload);
    const response = yield call(api.put, '/empresarial/alterar-senha', null, {
      params: payload.payload,
    });
    yield put(UpdateUsuarioSenhaSuccess(response));
    yield put(AlterarSenhaUpdateToken(response));

    yield put(OpenCloseModal(false));
    yield put(RequestSuccess(response));
  } catch (error) {
    yield put(UpdateUsuarioSenhaFailed(error));
    yield put(RequestFailed(error));
  }
}

export default all([
  takeLatest(CAD_USUARIO_REQUEST, CadastrarUsuarioRequest),
  takeLatest(UPDATE_USUARIO_REQUEST, UpdateUsuarioRequest),
  takeLatest(CAD_USUARIO_CARGO_REQUEST, CadastrarUsuarioCargoRequest),
  takeLatest(UPDATE_USUARIO_DADOS_REQUEST, UpdateUsuarioDadosRequest),
  takeLatest(UPDATE_USUARIO_FOTO_REQUEST, UpdateUsuarioFotoRequest),
  takeLatest(UPDATE_USUARIO_SENHA_REQUEST, AtualizarSenhaLogada),
]);
