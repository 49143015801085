import React from 'react';
import '~/config/prototypes';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { getVisibleAlert } from '../config/alert';
import API from '../config/api';
import PrivateRouter from './private';
import Public from './public';

const ValidateFunction = () => {
  const { auth: user, util } = useSelector((state) => state);
  const visibleAlert = getVisibleAlert(util);

  if (user?.logged) {
    API.defaults.headers.common.Authorization = user.access_token;

    return (
      <>
        <PrivateRouter />
        {visibleAlert && (
          <SweetAlert {...visibleAlert}>{visibleAlert.content}</SweetAlert>
        )}
      </>
    );
  }

  return (
    <>
      <Public />
      {visibleAlert && (
        <SweetAlert {...visibleAlert}>{visibleAlert.content}</SweetAlert>
      )}
    </>
  );
};

const Main = () => (
  <BrowserRouter>
    <ValidateFunction />
  </BrowserRouter>
);
export default Main;
