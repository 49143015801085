import { PURGE } from 'redux-persist';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  AUTH_PROVIDER_CHANGE,
  ATUALIZAR_EMPRESA_ATIVA,
  ATUALIZAR_CONTRATO_ATIVO,
  SOLICITAR_SENHA_REQUEST,
  SOLICITAR_SENHA_SUCCESS,
  SOLICITAR_SENHA_FAILED,
  CADASTRO_CORRETOR_REQUEST,
  CADASTRO_CORRETOR_SUCCESS,
  CADASTRO_CORRETOR_FAILED,
} from '~/config/constantes';

const INITIAL_STATE = {
  access_token: null,
  logged: false,
  loggedAt: null,
  loading: false,
  user_id: null,
  empresa_ativa: null,
  contrato_ativo: null,
};

function Auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE;

    case ATUALIZAR_EMPRESA_ATIVA: {
      return {
        ...state,
        empresa_ativa: action.payload,
        contrato_ativo: null,
      };
    }
    case ATUALIZAR_CONTRATO_ATIVO: {
      return {
        ...state,
        contrato_ativo: action.payload,
      };
    }

    case AUTH_PROVIDER_CHANGE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case LOGIN_REQUEST: {
      return {
        ...state,
        logged: false,
        loading: true,
      };
    }
    case LOGIN_SUCCESS: {
      const { access_token, user_id } = action.payload.data;
      return {
        ...state,
        loading: false,
        logged: true,
        user_id,
        loggedAt: new Date().toJSON(),
        access_token: `Bearer ${access_token}`,
      };
    }
    case LOGIN_FAILED: {
      return {
        ...state,
        loading: false,
        logged: false,
        access_token: null,
      };
    }
    case SOLICITAR_SENHA_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SOLICITAR_SENHA_SUCCESS: {
      return {
        ...state,
        send_recuperar: true,
        loading: false,
      };
    }
    case SOLICITAR_SENHA_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case CADASTRO_CORRETOR_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case CADASTRO_CORRETOR_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case CADASTRO_CORRETOR_SUCCESS: {
      const { access_token, user_id } = action.payload.data;
      return {
        ...state,
        loading: false,
        logged: true,
        user_id,
        loggedAt: new Date().toJSON(),
        access_token: `Bearer ${access_token}`,
      };
    }

    default:
      return state;
  }
}

export default Auth;
