import { PURGE } from 'redux-persist';
import { ADD_ALERT, OPEN_CLOSE_MODAL, REMOVE_ALERT } from '~/config/constantes';

export const INITIAL_STATE_UTIL = {
  open: false,
  load_cartao: false,
  loader_header: false,
  alerts: [],
  alert_message: {
    alert: false,
    message: '',
  },
};

function UtilReducer(state = INITIAL_STATE_UTIL, action) {
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE_UTIL;

    case 'ALERT_MESSAGE_ACTION':
      return {
        ...state,
        alert_message: { ...action.payload },
      };

    /**
     * Incuir items no carrinho
     */
    case OPEN_CLOSE_MODAL:
      return {
        ...state,
        ...action.payload,
      };

    case 'LOADER_HEADER':
      return {
        ...state,
        loader_header: action.payload,
      };

    case ADD_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, action.alert],
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts].filter((a) => a.id !== action.id),
      };

    default:
      return state;
  }
}

export default UtilReducer;
