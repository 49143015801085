import React from 'react';
import { Controller } from 'react-hook-form';
import InputRound from '~/components/input_round';
import { checkIsCPF, checkIsCelular } from '~/config/util';
import FormSection from '../../form_section';
import CustomInputMask from '../../mask_input';
import RenderDependentes from '../dependentes';
import { ContentDependentes, ContentBeneficiarios } from '../styles';

export default function RenderBeneficiarios({
  fields,
  register,
  watchAllFields,
  errors,
  append,
  remove,
  control,
}) {
  return (
    <FormSection nome="">
      {fields.map((mapearBeneficiarios, index) => (
        <ContentBeneficiarios>
          <FormSection
            key={mapearBeneficiarios.id}
            nome={`Beneficiário #${index + 1}`}
            arrayBtnOn
            arrayBtnArray={[
              {
                className: 'form-controll',
                btnAction: () => {
                  append({});
                },
                show: true,
                buttonName: 'Adicionar beneficiário',
              },
              {
                className: 'form-controll',
                btnAction: () => {
                  remove(index);
                },
                show: fields?.length >= 2 && true,
                buttonName: 'Remover beneficiário',
              },
            ]}
          >
            <div className="double-input">
              <InputRound
                label="Nome Completo"
                error={errors?.beneficiarios?.[index]?.nome_completo || false}
                message={
                  errors?.beneficiarios?.[index]?.nome_completo?.message ||
                  'Você deve informar o nome completo.'
                }
              >
                <input
                  type="text"
                  name={`beneficiarios[${index}].nome_completo`}
                  ref={register({ required: true })}
                />
              </InputRound>
              <div className="espaco" />
              <InputRound
                label="Data de nascimento"
                error={errors?.beneficiarios?.[index]?.dt_nascimento || false}
                message={
                  errors?.beneficiarios?.[index]?.dt_nascimento?.message ||
                  'Você deve informar a data de nascimento.'
                }
              >
                <input
                  type="date"
                  name={`beneficiarios[${index}].dt_nascimento`}
                  ref={register({ required: true })}
                />
              </InputRound>
              <div className="espaco" />
              <InputRound
                label="CPF"
                error={errors?.beneficiarios?.[index]?.documento || false}
                message={
                  errors?.beneficiarios?.[index]?.documento?.message ||
                  'Você deve informar o CPF.'
                }
              >
                <Controller
                  as={<CustomInputMask />}
                  onChange={([e]) => ({ value: e })}
                  rules={{
                    validate: {
                      inputTelRequired2: checkIsCPF,
                      inputTelRequired: (dataCheck) => {
                        if (dataCheck === undefined) {
                          return 'O número de celular é obrigatório.';
                        }

                        const beneficiarios = watchAllFields?.beneficiarios?.filter(
                          (checkItem) =>
                            checkItem?.documento?.includes(dataCheck)
                        );
                        const dependentes = watchAllFields?.beneficiarios?.filter?.(
                          (checkItem) => {
                            const dependentescheck = checkItem?.dependentes?.filter?.(
                              (checkdepe) =>
                                checkdepe?.documento?.includes?.(dataCheck)
                            ).length;
                            if (dependentescheck > 0) {
                              return checkItem;
                            }
                            return false;
                          }
                        );

                        const total =
                          beneficiarios?.length + dependentes?.length;

                        return total > 1
                          ? `Identificamos que este está vinculado para mais de um usuário neste formulário.`
                          : undefined;
                      },
                    },
                    required: true,
                  }}
                  name={`beneficiarios[${index}].documento`}
                  mask="999.999.999-99"
                  maskChar="_"
                  control={control}
                />
              </InputRound>
            </div>
            <div className="double-input">
              <InputRound
                label="E-mail"
                error={errors?.beneficiarios?.[index]?.email || false}
                message={
                  errors?.beneficiarios?.[index]?.email?.message ||
                  'Você deve informar o e-mail.'
                }
              >
                <input
                  type="email"
                  name={`beneficiarios[${index}].email`}
                  ref={register({ required: true })}
                />
              </InputRound>
              <div className="espaco" />
              <InputRound
                label="Celular"
                error={errors?.beneficiarios?.[index]?.celular || false}
                message={
                  errors?.beneficiarios?.[index]?.celular?.message ||
                  'Você deve informar o celular.'
                }
              >
                <Controller
                  as={<CustomInputMask />}
                  onChange={([e]) => ({ value: e })}
                  rules={{
                    validate: {
                      inputTelRequired: checkIsCelular,
                    },
                    required: true,
                  }}
                  name={`beneficiarios[${index}].celular`}
                  mask="(99) 9 9999-9999"
                  maskChar="_"
                  control={control}
                />
              </InputRound>
              <div className="espaco" />
              <InputRound
                label="Estado"
                error={errors?.beneficiarios?.[index]?.estado || false}
                message={
                  errors?.beneficiarios?.[index]?.estado?.message ||
                  'Você deve informar o estado.'
                }
              >
                <input
                  type="text"
                  name={`beneficiarios[${index}].estado`}
                  ref={register({ required: true })}
                />
              </InputRound>
            </div>
            <ContentDependentes>
              <RenderDependentes
                watchAllFields={watchAllFields}
                control={control}
                register={register}
                index={index}
                errors={errors}
              />
            </ContentDependentes>
          </FormSection>
        </ContentBeneficiarios>
      ))}
    </FormSection>
  );
}
