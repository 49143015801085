/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  Content,
  SectionFormPaciente,
  ButtonsGroup,
  ButtonControl,
  ContentVenda,
  ProgressBar,
} from './styles';
import { RequestAlertConfirm, RequestSuccess } from '~/config/alert';
import InputRound from '~/components/input_round';
import ButtonRound from '~/components/button_round';
import CustomInputMask from '~/components/mask_input';
import Checkbox from '~/components/checkbox';
import { VENDAS_EMPRESARIAL } from '~/config/gql';

import useAuth from '~/config/auth_provider/contexto';
import {
  checkIsCelular,
  checkIsTelefone,
  checkIsCPF,
  checkIsCNPJ,
} from '~/config/util';
import Header from '~/views/layouts/header';
import FormSection from '../form_section';
import CardProdutos from './produtos';
import RenderBeneficiarios from './beneficiarios';
import EnderecoNovaVenda from './endereco';
import TimeQueue from '~/config/TimeQueue';
import { DownloadLayoutExcel } from '~/config/utils';

const FormVendas = ({ titleForm, subTitle, type, produtosOption }) => {
  const authData = useAuth();
  const { dispatch, auth, equipe_vendas } = authData;
  const cargo_corretor_id = equipe_vendas?.equipes?.[0]?.id || undefined;

  const [checkBeneficiarios, setCheckBeneficiarios] = useState(false);
  const [dependentes, setDependentes] = useState([]);
  const [processamento, setProcessamento] = useState(0);
  const [processado, setprocessado] = useState(0);
  const [processando, setProcessando] = useState(false);
  const [keydefault, setkeydefault] = useState(
    uuidv4({
      node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
      clockseq: 0x1234,
      msecs: new Date().getTime(),
      nsecs: 5678,
    })
  );
  const { register, handleSubmit, control, errors, watch, reset } = useForm({
    defaultValues: {
      beneficiarios: [],
    },
  });
  const watchAllFields = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'beneficiarios',
  });

  const [vendaEmpresarial, { loading }] = useMutation(VENDAS_EMPRESARIAL, {
    context: {
      headers: {
        Authorization: auth,
      },
    },
  });

  useEffect(() => {
    if (processando && processamento === processado) {
      reset();
      setProcessando(false);
      setprocessado(0);
      setProcessamento(0);
      setkeydefault(
        uuidv4({
          node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
          clockseq: 0x1234,
          msecs: new Date().getTime(),
          nsecs: 5678,
        })
      );
      dispatch(RequestSuccess('Cadastro realizado com sucesso!'));
    }
  }, [processamento, processado, processando]);

  const onSubmit = (data) => {
    const haveAProductChecked = data?.produtos?.filter(
      (item) => item?.checked == 'true'
    );
    if (haveAProductChecked.length == 0) {
      dispatch(
        RequestAlertConfirm('Você deve selecionar um produto.', '', false)
      );
    } else {
      const callVenda = (
        processamentoData,
        callback = () => {},
        position = 0
      ) => {
        vendaEmpresarial({
          variables: {
            data: processamentoData,
          },
        })
          .then(() => {
            callback?.();
            setprocessado(position);
          })
          .catch((e) => {
            console.log(e);
          });
      };

      if (data?.beneficiarios?.length > 0) {
        setProcessamento(data.beneficiarios.length);
        setProcessando(true);
        data.beneficiarios.map((beneficiario, position) => {
          TimeQueue.add(() => {
            callVenda(
              {
                empresa: {
                  keydefault,
                  tipo_produto: type,
                  ...data.responsavel,
                  ...data.empresa,
                  cargo_corretor_id,
                },
                endereco: {
                  ...data.endereco,
                  endereco: data.endereco.logradouro,
                },
                produtos: data.produtos.filter(
                  (itemProduto) => itemProduto.checked.length == 4
                ),
                beneficiario,
              },
              () => {},
              position + 1
            );
          }, 2000);
        });
        TimeQueue.start();
      } else {
        const callbackItem = () => {
          dispatch(RequestSuccess('Cadastro realizado com sucesso!'));
          reset();
          setkeydefault(
            uuidv4({
              node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
              clockseq: 0x1234,
              msecs: new Date().getTime(),
              nsecs: 5678,
            })
          );
        };
        callVenda(
          {
            empresa: {
              keydefault,
              tipo_produto: type,
              ...data.responsavel,
              ...data.empresa,
              cargo_corretor_id,
            },
            endereco: {
              ...data.endereco,
              endereco: data.endereco.logradouro,
            },
            produtos: data.produtos.filter(
              (itemProduto) => itemProduto.checked.length == 4
            ),
          },
          callbackItem
        );
      }
    }
  };

  useEffect(() => {
    if (!checkBeneficiarios) {
      fields.map((itemCheck, key) => {
        remove(key);
      });
    } else {
      append({});
    }
  }, [checkBeneficiarios]);

  return (
    <Content>
      <Header title={titleForm} texto={subTitle} />

      <SectionFormPaciente>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* DADOS PRODUTOS */}
          <ContentVenda>
            <FormSection
              nome="Produtos"
              alert={
                errors.produtos?.filter?.((itemCheck) => itemCheck?.dr_online)
                  ?.length > 0 && 'Você deve selecionar um produto.'
              }
            >
              <div className="double-input">
                {produtosOption.map((itemMap, keyParams) => (
                  <CardProdutos
                    register={register}
                    key={itemMap.identificador}
                    index={keyParams}
                    item={itemMap}
                    error={errors.produtos}
                  />
                ))}
              </div>
            </FormSection>
          </ContentVenda>

          {/* DADOS EMPRESA  */}
          <ContentVenda>
            <FormSection nome="Dados da empresa">
              <div className="double-input">
                <InputRound
                  label="CNPJ"
                  error={errors?.empresa?.cnpj || false}
                  message={
                    errors?.empresa?.cnpj?.message ||
                    'Você deve informar o CNPJ.'
                  }
                >
                  <Controller
                    as={<CustomInputMask />}
                    onChange={([e]) => ({ value: e })}
                    rules={{
                      validate: {
                        inputTelRequired: checkIsCNPJ,
                      },
                      required: true,
                    }}
                    name="empresa.cnpj"
                    mask="99.999.999/9999-99"
                    maskChar="_"
                    control={control}
                  />
                </InputRound>
                <div className="espaco" />
                <InputRound
                  label="Razão Social"
                  error={errors?.empresa?.razao_social || false}
                  message={
                    errors?.empresa?.razao_social?.message ||
                    'Você deve informar a razão social.'
                  }
                >
                  <input
                    type="text"
                    name="empresa.razao_social"
                    ref={register({ required: true, maxLength: 100 })}
                  />
                </InputRound>
                <div className="espaco" />
                <InputRound
                  label="Nome Fantasia"
                  error={errors?.empresa?.nm_fantasia || false}
                  message={
                    errors?.empresa?.nm_fantasia?.message ||
                    'Você deve informar o nome fantasia.'
                  }
                >
                  <input
                    type="text"
                    name="empresa.nm_fantasia"
                    ref={register({ required: true, maxLength: 100 })}
                  />
                </InputRound>
              </div>
              <div className="double-input">
                <InputRound
                  label="E-mail"
                  error={errors?.empresa?.email_empresa || false}
                  message={
                    errors?.empresa?.email_empresa?.message ||
                    'Você deve informar o e-mail.'
                  }
                >
                  <input
                    type="text"
                    name="empresa.email_empresa"
                    ref={register({ required: true })}
                  />
                </InputRound>
                <div className="espaco" />
                <InputRound
                  label="Telefone"
                  error={errors?.empresa?.telefone_empresa || false}
                  message={
                    errors?.empresa?.telefone_empresa?.message ||
                    'Você deve informar o telefone.'
                  }
                >
                  <Controller
                    as={<CustomInputMask />}
                    onChange={([e]) => ({ value: e })}
                    rules={{
                      validate: {
                        inputTelRequired: checkIsTelefone,
                      },
                      required: true,
                    }}
                    name="empresa.telefone_empresa"
                    mask="(99) 9999-9999"
                    maskChar="_"
                    control={control}
                  />
                </InputRound>
              </div>
            </FormSection>
          </ContentVenda>
          {/* DADOS DE ENDERECO */}
          <ContentVenda>
            <EnderecoNovaVenda
              errors={errors}
              register={register}
              control={control}
              watchAllFields={watchAllFields}
            />
          </ContentVenda>
          {/* DADOS RESPONSAVEL LEGAL */}
          <ContentVenda>
            <FormSection nome="Dados responsável legal">
              <div className="double-input">
                <InputRound
                  label="Nome Completo"
                  error={errors?.responsavel?.nome_re || false}
                  message={
                    errors?.responsavel?.nome_re?.message ||
                    'Você deve informar o nome completo.'
                  }
                >
                  <input
                    type="text"
                    name="responsavel.nome_re"
                    ref={register({ required: true })}
                  />
                </InputRound>
                <div className="espaco" />
                <InputRound
                  label="CPF"
                  error={errors?.responsavel?.documento_rep || false}
                  message={
                    errors?.responsavel?.documento_rep?.message ||
                    'Você deve informar um CPF válido.'
                  }
                >
                  <Controller
                    as={<CustomInputMask />}
                    onChange={([e]) => ({ value: e })}
                    rules={{
                      validate: {
                        inputTelRequired: checkIsCPF,
                      },
                      required: true,
                    }}
                    name="responsavel.documento_rep"
                    mask="999.999.999-99"
                    maskChar="_"
                    control={control}
                  />
                </InputRound>
                <div className="espaco" />
                <InputRound
                  label="Data Nascimento"
                  error={errors?.responsavel?.dt_nascimento_rep || false}
                  message={
                    errors?.responsavel?.dt_nascimento_rep?.message ||
                    'Você deve informar a data de nascimento.'
                  }
                >
                  <input
                    type="date"
                    name="responsavel.dt_nascimento_rep"
                    ref={register({ required: true })}
                  />
                </InputRound>
              </div>

              <div className="double-input">
                <InputRound
                  label="E-mail"
                  error={errors?.responsavel?.email_rep || false}
                  message={
                    errors?.responsavel?.email_rep?.message ||
                    'Você deve informar o e-mail.'
                  }
                >
                  <input
                    type="email"
                    name="responsavel.email_rep"
                    ref={register({ required: true })}
                  />
                </InputRound>

                <div className="espaco" />
                <InputRound
                  label="Celular"
                  error={errors?.responsavel?.celular_rep || false}
                  message={
                    errors?.responsavel?.celular_rep?.message ||
                    'Você deve informar o celular.'
                  }
                >
                  <Controller
                    as={<CustomInputMask />}
                    onChange={([e]) => ({ value: e })}
                    rules={{
                      validate: {
                        inputTelRequired: checkIsCelular,
                      },
                      required: true,
                    }}
                    name="responsavel.celular_rep"
                    mask="(99) 9 9999-9999"
                    maskChar="_"
                    control={control}
                  />
                </InputRound>
              </div>
            </FormSection>
          </ContentVenda>

          {/* DADOS BENEFICIARIOS */}
          <FormSection nome="Beneficiários">
            <div className="double-input">
              <Checkbox for_id="manualmente" label="Preencher manualmente">
                <input
                  name="suspenso"
                  onChange={() => {
                    setCheckBeneficiarios('manualmente');
                  }}
                  value={checkBeneficiarios}
                  defaultChecked={checkBeneficiarios}
                  id="manualmente"
                  type="radio"
                />
              </Checkbox>
              <div className="espaco" />
              <Checkbox
                for_id="layout"
                label="Preencher layout excel e enviar para comercial@doutorhoje.com.br"
              >
                <input
                  name="suspenso"
                  onChange={() => {
                    if (fields.length > 0) {
                      const check = (call) => {
                        if (call) {
                          setCheckBeneficiarios(undefined);
                        }
                      };
                      dispatch(
                        RequestAlertConfirm(
                          'Você perderá todos os dados preenchidos manualmente, deseja continuar ?',
                          '',
                          true,
                          check
                        )
                      );
                    } else {
                      setCheckBeneficiarios(undefined);
                    }
                  }}
                  value={!checkBeneficiarios}
                  defaultChecked={checkBeneficiarios}
                  ref={register({ required: false })}
                  id="layout"
                  type="radio"
                />
              </Checkbox>
            </div>

            {checkBeneficiarios && (
              <RenderBeneficiarios
                fields={fields}
                watchAllFields={watchAllFields}
                register={register}
                setDependentes={setDependentes}
                errors={errors}
                append={append}
                remove={remove}
                control={control}
                dependentes={dependentes}
              />
            )}

            {checkBeneficiarios === undefined && (
              <button
                type="button"
                className="form-control"
                onClick={() => {
                  DownloadLayoutExcel({
                    title: 'layout_drhoje',
                    object: [
                      {
                        NomeCompleto: 'João teste inácio alves de quiroz',
                        DataDeNascimento: '07/07/1996',
                        CNPJ_Empresa: '51.728.834/0001-59',
                        Genero: 'M',
                        CPFUsuario: '238.131.920-40',
                        TipoVinculo: 'T',
                        CPFTitular: '',
                        NumCelular: '(61) 98596-5969',
                        NumTelefoneFixo: '(61) 8596-5969',
                        Email: 'teste@teste.com.br',
                      },
                      {
                        NomeCompleto: 'Vitor inácio alves de quiroz',
                        DataDeNascimento: '07/07/2011',
                        CNPJ_Empresa: '51.728.834/0001-59',
                        Genero: 'M',
                        CPFUsuario: '533.543.360-48',
                        TipoVinculo: 'D',
                        CPFTitular: '238.131.920-40',
                        NumCelular: '(61) 98596-5969',
                        NumTelefoneFixo: '(61) 8596-5969',
                        Email: 'teste@teste.com.br',
                      },
                      {
                        NomeCompleto: 'Luísa Hellena alves de quiroz',
                        DataDeNascimento: '07/07/2011',
                        CNPJ_Empresa: '51.728.834/0001-59',
                        Genero: 'F',
                        CPFUsuario: '489.267.610-10',
                        TipoVinculo: 'D',
                        CPFTitular: '238.131.920-40',
                        NumCelular: '(61) 98596-5969',
                        NumTelefoneFixo: '(61) 8596-5969',
                        Email: 'teste@teste.com.br',
                      },
                    ],
                  });
                }}
              >
                Baixar arquivo layout
              </button>
            )}

            <ContentVenda>
              <strong>Importante</strong>
              <p>
                Este cadastro representa a intenção da empresa em contratar uma
                das assinaturas da DRHOJE e não garante a implantação e o
                benefício.
              </p>
              <p>
                Em até 24hs úteis a Dr Hoje fará contato com o corretor para
                seguir com o processo de contratação e implantação.
              </p>
            </ContentVenda>
          </FormSection>
          {processando && (
            <div className="progress">
              <ProgressBar
                className="progress-bar"
                role="progressbar"
                aria-valuenow={parseInt((processado / processamento) * 10)}
                aria-valuemin="0"
                aria-valuemax="100"
                tamanho={parseInt((processado / processamento) * 10)}
              >
                {parseInt((processado / processamento) * 10)}%
              </ProgressBar>
            </div>
          )}

          <ButtonsGroup>
            <ButtonControl>
              <ButtonRound
                type="submit"
                disabled={processando || loading}
                className="gradient"
              >
                Realizar cadastro
              </ButtonRound>
            </ButtonControl>
          </ButtonsGroup>
        </form>
      </SectionFormPaciente>
    </Content>
  );
};

export default FormVendas;
