import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem;

  h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  a {
    margin: 0 auto;
  }

  form {
    margin: 1rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 30px;
    label {
      display: flex;
      align-items: center;
      border: 1px solid #c5c5c5;
      border-radius: 10px;
      padding: 1rem;
      cursor: pointer;

      input {
        margin-right: 16px;
      }
    }

    @media (max-width: 768px) {
      margin: 1rem 0;
    }
  }
`;
