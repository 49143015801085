import React from 'react';

const ServicoAgendamentoContextoDefault = {
  servico: {
    data: new Date().toJSON(),
    horario: {
      index: null,
      id: null,
      horario: null,
    },
    paciente: '',
  },
};
export const ServicoAgendamentoContext = React.createContext(
  ServicoAgendamentoContextoDefault
);
export const ServicoAgendamentoProvider = ServicoAgendamentoContext.Provider;

const ItemAgendamentoSelecionadoDefault = {
  preco: 0,
  plano: null,
  subcontrato: null,
  item: {},
};
export const ItemAgendamentoContext = React.createContext(
  ItemAgendamentoSelecionadoDefault
);
export const ItemAgendamentoProvider = ItemAgendamentoContext.Provider;

const DetalhePlano = {};
export const ItemDetalhePlano = React.createContext(DetalhePlano);
export const ItemDetalhePlanoPlano = ItemDetalhePlano.Provider;

export const EmpresaAtivaSelecContext = React.createContext({});
export const EmpresaAtivaSelecProvider = EmpresaAtivaSelecContext.Provider;

export const BlocoBuscaFiltroContext = React.createContext({});
export const BlocoBuscaFiltroProvider = BlocoBuscaFiltroContext.Provider;

export const PassosCompraContext = React.createContext({});
export const PassosCompraProvider = PassosCompraContext.Provider;

export const SaldoEmpresarialContext = React.createContext({});
export const SaldoEmpresarialProvider = SaldoEmpresarialContext.Provider;

export const InputSaldoContext = React.createContext({
  setEvent: null,
  register: null,
  trigger: null,
  control: null,
  novoSaldo: null,
  setnovoSaldo: null,
});
export const InputSaldoProvider = InputSaldoContext.Provider;
