import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import {
  borderInput,
  placeholder,
  fieldValue,
  labelColor,
  error,
  disabledInput,
} from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex: ${(props) => props.flex};
  .msg-obrigatorio {
    font-size: 12px;
    font-weight: 300;
    color: #d7d7d7;
    margin: 0 0 0 12px;
  }
`;
export const ClearButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  background: transparent !important;
  border-color: transparent !important;
  color: #6b6b6b !important;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FullInput = styled.div`
  display: flex;
  justify-content: flex-end;
  height: fit-content;
  width: 100%;
  padding: 0px 13px;
  align-items: center;
  border: 1px solid ${borderInput};
  border-radius: 22px;
  background-color: white;
  transition: all 0.5s;

  input[type='text'] {
    border: none;
    flex: 1;
    font-size: 14px;
  }

  .form-control {
    background-color: transparent;
    border: none;
    display: block;
    font-size: 14px;
    color: ${fieldValue};
    transition: all 0.5s;
    padding: 6px 0 6px 9px;
    &::placeholder {
      font-size: 16px;
      font-weight: 300;
      color: ${placeholder};
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
    &:disabled {
      background-color: transparent;
      color: ${placeholder};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-color: transparent;
      transition: background-color 5000s ease-in-out 0s;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
    &::placeholder {
      font-size: 14px;
    }
  }

  &.error {
    border: 1px solid ${error};
  }
  &.disabled {
    background-color: ${disabledInput};
  }

  .icon-container {
    position: absolute;
  }

  @keyframes around {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader::after,
  .loader::before {
    content: '';
    background: white;
    position: absolute;
    display: ${(props) => (!props.loading ? 'none' : 'inline-block')};
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #008bff #008bff transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  .loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
`;
export const Label = styled.span`
  font-size: 14px;
  color: ${labelColor};
  white-space: nowrap;
  transition: all 0.5s;
  &.error {
    color: ${error};
  }
`;
export const ErrorMessage = styled.span`
  font-size: 14px;
  font-weight: 300;
  color: ${error};
  margin: 0 0 0 12px;
  transition: all 0.5s;
`;
