import styled from 'styled-components';

export const Header = styled.div`
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 25%);
  position: fixed;
  width: 100%;
  display: none;
  z-index: 3;
  padding: 12px 0;

  /**

   */

  .btn-primary {
    background: #fff !important;
    color: #000 !important;
    width: 100%;
    border: none;
    padding: 0 !important;
  }

  .dropdown-selecionar {
    border: none !important;
  }

  .show > .btn-primary.dropdown-toggle:focus {
    background: #fff;
    color: #000;
    width: 100%;
  }

  .menu-empresa {
    width: 100%;
  }

  /* drop down boostrap */
  .dropdown-menu {
    border: none;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.23);
    border-radius: 0 0 12px 12px;
    margin-top: 16px;
  }

  #dropdown-basic::after {
    display: none !important;
  }

  .dropdown-user {
    border-radius: 50px;
    padding: 0;
    background: transparent;
    border: none;

    figure {
      width: 38px;
      margin: 0;
      img {
        display: block;
        max-width: 100%;
      }
    }
  }

  .dropdown-content-user {
    margin: 0 8px 0 0;
    position: sticky !important;

    @media (max-width: 350px) {
      margin: 0 8px 0 0;
    }
  }

  .dropdown-item:nth-child(2n) {
    border-top: 1px solid #d4d4d4;
  }
  .dropdown-item:nth-child(3n) {
    border-top: 1px solid #d4d4d4;
  }

  .dropdown-select-empresa {
    width: 100%;
  }
  /* fim dropdown */

  div.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div.icon-menu-hamburguer {
    padding: 8px 0;
    margin: 0 0 0 8px;
    background: #eff1f3;
    border-radius: 2px;

    @media (max-width: 350px) {
      margin: 0 0 0 8px;
    }
  }

  @media (max-width: 700px) {
    display: block;
  }

  a {
    color: #000;
  }

  /* header */

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
    height: 100vh !important;
  }

  li a {
    display: block;
    padding: 20px 20px;
    text-align: center;
    width: 100%;
    text-decoration: none;
    color: #000;
    border-bottom: 1px solid #e7e7e7;
  }

  li a:hover,
  .menu-btn:hover {
    background-color: #1858af;
    color: #fff;
  }

  .logo {
    display: block;
    float: left;
    font-size: 2em;
    padding: 10px 20px;
    text-decoration: none;
  }

  /* menu */

  .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;

    .dropdown-menu.show {
      left: 50% !important;
      transform: translateX(-50%) !important;
    }
  }

  /* menu icon */

  .menu-icon {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    position: relative;
    user-select: none;
    margin: 0;
  }

  .menu-icon .navicon {
    background: #0439d6;
    display: block;
    height: 4px;
    position: relative;
    border-radius: 2px;
    transition: background 0.2s ease-out;
    width: 24px;
  }

  .menu-icon .navicon:before,
  .menu-icon .navicon:after {
    background: #0439d6;
    border-radius: 2px;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }

  .menu-icon .navicon:before {
    top: 7px;
  }

  .menu-icon .navicon:after {
    top: -7px;
  }

  /* menu btn */

  .menu-btn {
    display: none;
  }

  .menu {
    max-height: ${(props) => (props.showMenu ? '100vh' : '0')};
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

  /* 48em = 768px */

  @media (min-width: 48em) {
    li {
      float: left;
    }
    li a {
      padding: 20px 30px;
    }
    .menu {
      clear: none;
      float: right;
      max-height: none;
    }
    .menu-icon {
      display: none;
    }
  }
`;

export const MenuUser = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;

  figure {
    margin: 0;
    width: 52px;

    img {
      display: block;
      max-width: 100%;
    }
  }

  @media (max-width: 350px) {
    padding: 8px 0;

    figure {
      margin: 0;
      width: 38px;
    }
  }
`;

export const InfoUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 28px;

  strong {
    font-size: 18px;
    color: #0439d6;
  }

  @media (max-width: 350px) {
    strong {
      font-size: 14px;
    }

    span {
      font-size: 12px;
    }
  }
`;

export const LinkDadosConta = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 8px;
    color: #3a3a3d;
  }

  a {
    color: #3a3a3d;
    font-size: 14px;
  }

  @media (max-width: 350px) {
    a {
      font-size: 12px;
    }
  }
`;

export const Logout = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 600;
  a {
    color: #0439d6;
  }
`;

export const SelectEmpresaDropdown = styled.div``;

export const ListEmpresas = styled.div`
  div {
    display: flex;
    padding: 12px 0;
    border-bottom: 1px solid #d5d5d5;
    margin: 0 16px;

    label {
      display: flex;
      margin: 0;
      align-items: center;
      width: 100%;

      input {
        margin-right: 8px;
      }
    }
  }
`;

export const ButtonControl = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 6px;
`;
