/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  UpdateUsuarioDadosRequest,
  UpdateUsuarioFotoRequest,
  UpdateUsuarioSenhaRequest,
} from '~/modules/usuarios/actions';
import { GET_USUARIO } from '~/config/gql';
import InputMasked from '~/components/InputMask';

import Header from '../layouts/header';
import GlobalModal from '~/components/global_modal';
import useAuth from '~/config/auth_provider/contexto';
import usePng from '~/assets/img/user.png';
import InputRound from '~/components/input_round';
import Button from '~/components/button';

import {
  Content,
  ContentDadosLine,
  ContentCardDados,
  CardDados,
  CardHeaderdados,
  GroupList,
  ContentCardImagem,
  FotoUser,
  FooterForm,
} from './styles';

const Configuracoes = () => {
  const dispatch = useDispatch();
  const { dataEmpresaSelecionada, dataCargoRepre } = useAuth();
  const { auth_object: auth, empresa_ativa } = useAuth();
  const [showModalCadastro, setShowModalCadastro] = useState(false);
  const [showModalSenha, setShowModalSenha] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [cardFile, setCardFile] = useState(null);
  const {
    register: registerSenha,
    handleSubmit: handleSubmitSenha,
    watch,
    errors: errorsSenha,
  } = useForm({ defaultValues: {} });

  const onSubmitSenha = (data) => {
    setShowModalSenha(false);
    dispatch(UpdateUsuarioSenhaRequest(data));
  };

  const { data: usuario, refetch } = useQuery(GET_USUARIO, {
    variables: {
      empresa_id: empresa_ativa?.id,
      user_id: dataCargoRepre?.[0]?.user?.id,
    },
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    skip: dataCargoRepre?.[0]?.user?.id === undefined,
  });

  const recebeArquivo = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    if (file === undefined) {
      return;
    }
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setCardFile({
        base64: reader.result,
        size: file.size / 1048576,
        user_id: dataCargoRepre?.[0]?.user?.id,
      });
    };
  };

  const enviarImagem = () => {
    if (cardFile !== null) {
      dispatch(UpdateUsuarioFotoRequest(cardFile));
    }
  };

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    setShowModalCadastro(false);
    const atualizar = () => {
      refetch();
    };
    dispatch(UpdateUsuarioDadosRequest({ ...data, atualizar }));
  };

  return (
    <Content>
      <Header
        title="Configurações"
        texto="Estas são as informações pessoais usadas para acessar e gerenciar sua conta. Você também pode adicionar um número de telefone celular e um email para aumentar a segurança, recuperar sua conta e receber notificações."
        ocultarCard
      />

      <ContentDadosLine>
        <ContentCardDados>
          <CardDados>
            <CardHeaderdados>
              <span>Dados do Usuário</span>
              <a href="#senha" onClick={() => setShowModalCadastro(true)}>
                Editar cadastro
              </a>
            </CardHeaderdados>
            <hr />
            <GroupList>
              <ul>
                <li>Nome </li>
                <li>CPF</li>
                <li>E-mail</li>
                <li>Telefone</li>
              </ul>
              <ul>
                <li>{usuario?.usuario?.user?.nome_completo}</li>
                <li>{usuario?.usuario?.user?.documentos?.[0]?.nr_documento}</li>
                <li>{usuario?.usuario?.email}</li>
                <li>{usuario?.usuario?.contato_cargo}</li>
              </ul>
            </GroupList>

            <GlobalModal
              handleShow={showModalCadastro}
              setHandleShow={setShowModalCadastro}
              titleModal="Dados pessoais"
              dialogClassName="modal-50w"
            >
              <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
                <h1>Editar usuário</h1>

                <InputRound label="Nome">
                  <input
                    ref={register({ required: true })}
                    type="text"
                    placeholder="Nome completo"
                    id="nome_completo"
                    name="nome_completo"
                    defaultValue={usuario?.usuario?.user?.nome_completo}
                    invalid={!!errors?.nome_completo}
                  />
                </InputRound>
                {errors?.nome_completo && <small>* Nome obrigatório!</small>}

                <InputRound label="E-mail de contato">
                  <input
                    ref={register({ required: true })}
                    type="email"
                    name="email"
                    placeholder="exemplo@gmail.com"
                    invalid={!!errors?.email}
                    defaultValue={usuario?.usuario?.email}
                    required
                  />
                </InputRound>
                {errors?.email && <small>* E-mail obrigatório!</small>}

                <InputRound label="Celular">
                  <InputMasked
                    mask="celular"
                    ref={register({ required: false })}
                    onChange={(e) => setTelefone(e.target.value)}
                    placeHolder={usuario?.usuario?.contato_cargo}
                    value={telefone}
                    type="text"
                    name="contato_cargo"
                    invalid={!!errors?.contato_cargo}
                  />
                </InputRound>
                {errors?.contato_cargo && (
                  <small>* Telefone obrigatório!</small>
                )}

                <input
                  type="hidden"
                  name="contato_cargo"
                  value={
                    telefone.length === 0
                      ? usuario?.usuario?.contato_cargo
                      : telefone
                  }
                  ref={register({ required: true })}
                />

                <input
                  type="hidden"
                  name="empresa_id"
                  value={dataEmpresaSelecionada?.id}
                  ref={register({ required: false })}
                />

                <input
                  type="hidden"
                  name="user_id"
                  value={usuario?.usuario?.user?.id}
                  ref={register({ required: false })}
                />

                <FooterForm>
                  <Button bgColor="darkBlue" width="150px">
                    Salvar
                  </Button>
                </FooterForm>
              </form>
            </GlobalModal>
          </CardDados>
          <CardDados>
            <CardHeaderdados>
              <span>Senha de acesso</span>
              <a href="#senha" onClick={() => setShowModalSenha(true)}>
                Alterar senha
              </a>
            </CardHeaderdados>
            <hr />
            <GroupList>
              <ul>
                <li>
                  <strong>
                    <i className="far fa-lock-alt" /> Senha atual
                  </strong>
                </li>
              </ul>
              <ul>
                <li> ••••</li>
              </ul>
            </GroupList>

            <GlobalModal
              handleShow={showModalSenha}
              setHandleShow={setShowModalSenha}
              titleModal="Dados pessoais"
              dialogClassName="modal-50w"
            >
              <form id="login-form" onSubmit={handleSubmitSenha(onSubmitSenha)}>
                <h1>Alterar senha</h1>
                <InputRound
                  label="Senha atual"
                  required
                  validacao={errorsSenha?.password || false}
                  mensagemDeErro={
                    errorsSenha?.password &&
                    'É necessário informar a senha atual.'
                  }
                >
                  <input
                    type="password"
                    name="password"
                    ref={registerSenha({ required: true })}
                    placeholder="Digite sua senha atual"
                    required
                  />
                </InputRound>
                <InputRound
                  label="Nova senha"
                  required
                  validacao={errorsSenha?.new_password || false}
                  mensagemDeErro={
                    errorsSenha?.new_password &&
                    'É necessário informar a nova senha.'
                  }
                >
                  <input
                    type="password"
                    name="new_password"
                    ref={registerSenha({ required: true })}
                    placeholder="Digite sua nova senha"
                    required
                  />
                </InputRound>
                <InputRound
                  label="Confirme a senha"
                  required
                  validacao={errorsSenha?.confirmPassword || false}
                  mensagemDeErro={
                    errorsSenha?.confirmPassword &&
                    'A senha informada não confere, tente novamente.'
                  }
                >
                  <input
                    type="password"
                    name="confirmPassword"
                    ref={registerSenha({
                      required: true,
                      validate: (value) => value === watch('new_password'),
                    })}
                    placeholder="Confirme a aqui"
                    required
                  />
                </InputRound>

                <FooterForm>
                  <Button
                    bgColor="darkBlue"
                    width="150px"
                    radius="24px"
                    disabled={false}
                  >
                    Salvar
                  </Button>
                </FooterForm>
              </form>
            </GlobalModal>
          </CardDados>
        </ContentCardDados>
        <ContentCardImagem>
          <CardDados>
            <CardHeaderdados>
              <span>Foto do perfil</span>
              <p />
            </CardHeaderdados>
            <hr />
            <FotoUser>
              <figure>
                <img
                  src={
                    cardFile?.base64.length === undefined
                      ? usePng
                      : cardFile?.base64
                  }
                  alt="foto do usuario"
                />
              </figure>
              <input
                type="file"
                onChange={recebeArquivo}
                accept="application/jpg, image/png, image/gif"
              />
              <a href="#alterarfoto" onClick={enviarImagem}>
                Salvar a imagem
              </a>
            </FotoUser>
          </CardDados>
        </ContentCardImagem>
      </ContentDadosLine>
    </Content>
  );
};
export default Configuracoes;
