import styled from 'styled-components';
import Select from 'react-select/async';

export const Loading = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 20px;
`;

export const TelaLogin = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 500px;

  h3 {
    text-align: center;
  }
  .logo {
    display: flex;
    height: auto;
    width: 248px;
    margin: 48px auto 16px;
  }
  .card {
    margin: 8px;
    border-radius: 10px;
    padding: 40px 30px;

    .form-control {
      height: 40px;
      width: 250px;
    }
    .btn {
      margin: 16px auto;
    }
  }
  .recuperar-senha {
    text-align: center;
  }
`;

export const ContentForm = styled.div`
  height: 100%;
  .button-blue {
    color: #007bff;
  }
  form {
    margin: 0 62px;
    height: 100%;

    h3 {
      margin-top: 60px;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }

    p {
      text-align: left;
      margin: 16px 0 34px 0;
      font-size: 15px;
    }

    button {
      margin: 24px 0 32px 0;
      width: 100%;
    }
  }

  .lastInput {
    margin-bottom: 54px;
  }

  html #recaptcha_area,
  html #recaptcha_table {
    width: 300px !important;
  }

  @media (max-width: 1440px) {
    form {
      margin: 0 52px;

      h3 {
        margin-top: 30px;
      }

      p {
      }
    }

    .lastInput {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 600px) {
    form {
      margin: 0 24px;

      h3 {
        margin-top: 30px;
      }
    }
  }

  @media (max-width: 500px) {
    form {
      margin: 0 24px;
    }
  }
`;

export const InputControl = styled.div`
  margin: 16px 0;
`;

export const GridContentForm = styled.div`
  display: grid;
  grid-template-areas: 'asideLeft asideRight';
  grid-template-columns: 40% 56%;
  grid-gap: 4%;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;

    div {
      width: 100%;
    }
  }
`;

export const AsideLeft = styled.aside`
  grid-area: asideLeft;
`;

export const AsideRight = styled.aside`
  grid-area: asideRight;
`;

export const Box = styled(Select)`
  display: flex;
  min-width: 235px;
  width: 100%;
  .css-yk16xz-control,
  .css-1pahdxg-control {
    display: flex;
    flex: 1;
  }
  .css-1wa3eu0-placeholder {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .add-info {
    color: #d7d7d7;
  }
`;

export const TituloGrupo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
  font-size: 14px;

  .qtd-resultados {
    font-weight: 300;
    color: #0000005e;
    margin-left: 30px;
  }
`;

export const BackInt = styled.span`
  display: flex;
  align-items: center;
  margin-top: 20px;
  i {
    margin-right: 10px;
  }
  cursor: pointer;
`;

export const TermoInput = styled.label`
  input {
    margin-top: 4px;
    margin-right: 8px;
  }

  display: flex;
  p {
    text-align: left;
  }

  @media (max-width: 1440px) {
    p {
      text-align: left;
    }
  }
`;

export const LoginInfo = styled.div`
  p {
    margin: 0 !important;
    font-size: 14px !important;

    strong {
      font-size: 14px !important;
    }
  }
`;
