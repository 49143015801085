import React from 'react';
import { Modal } from 'react-bootstrap';

import './styles.css';

const GlobalModal = ({
  children,
  handleShow,
  setHandleShow,
  size,
  dialogClassName,
}) => {
  return (
    <Modal
      show={handleShow}
      onHide={setHandleShow}
      keyboard={false}
      centered
      size={size}
      dialogClassName={dialogClassName}
    >
      <Modal.Header closeButton />
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default GlobalModal;
