/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Controller } from 'react-hook-form';
import ButtonRound from '~/components/button_round';
import CustomInputMask from '~/components/mask_input';
import InputForm from '~/components/input_form';
import Termos from '~/components/termos';
import * as S from '../styles';
import { checkIsCelular } from '~/config/util';
import { checkIsCPF } from '~/config/utils';

export default function StepThree({
  register,
  control,
  trigger,
  getValues,
  errors,
  onSubmit,
  contentForm,
  loading,
  showTermo,
  setShowTermo,
}) {
  return (
    <>
      <S.InputControl>
        <InputForm
          labelName="Nome Completo"
          required
          validacao={errors?.nome_completo}
          mensagemDeErro="Informe seu nome completo"
        >
          <input
            type="text"
            maxLength={100}
            name="nome_completo"
            defaultValue={contentForm?.nome_completo || ''}
            className="form-control"
            placeholder="Nome Completo"
            ref={register({ required: true })}
          />
        </InputForm>
      </S.InputControl>
      <InputForm
        labelName="CPF"
        required
        validacao={errors?.documento}
        mensagemDeErro="Informe seu CPF"
      >
        <Controller
          as={<CustomInputMask />}
          onChange={([e]) => ({ value: e })}
          rules={{
            validate: {
              inputCPFRequired: checkIsCPF,
            },
          }}
          name="documento"
          mask="999.999.999-99"
          defaultValue={contentForm?.documento || ''}
          maskChar="_"
          placeholder="___.___.___-__"
          className="form-control"
          control={control}
        />
      </InputForm>
      <InputForm
        labelName="Data de nascimento"
        required
        validacao={errors?.dt_nascimento}
        mensagemDeErro="Informe sua data de nascimento"
      >
        <input
          type="date"
          name="dt_nascimento"
          className="form-control"
          placeholder="Informe a Data de Nascimento"
          defaultValue={contentForm?.dt_nascimento || ''}
          ref={register({ required: true })}
        />
      </InputForm>
      <InputForm labelName="Sexo" required>
        <select
          className="form-control"
          name="sexo"
          defaultValue={contentForm?.sexo || ''}
          ref={register({ required: true })}
        >
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
        </select>
      </InputForm>
      <InputForm
        labelName="Email"
        required
        validacao={errors?.email}
        mensagemDeErro="Informe seu email"
      >
        <input
          type="email"
          name="email"
          defaultValue={contentForm?.email || ''}
          className="form-control"
          maxLength={100}
          placeholder="exemplo@gmail.com"
          ref={register({ required: true })}
        />
      </InputForm>
      <InputForm
        labelName="Celular"
        required
        validacao={errors?.celular}
        mensagemDeErro="Informe seu celular"
      >
        <Controller
          as={<CustomInputMask />}
          onChange={([e]) => ({ value: e })}
          rules={{
            validate: {
              inputCelularRequired: checkIsCelular,
            },
          }}
          name="celular"
          mask="(99) 9 9999-9999"
          defaultValue={contentForm?.celular || ''}
          maskChar="_"
          placeholder="(00) 9 0000-0000"
          className="form-control"
          control={control}
        />
      </InputForm>
      <InputForm
        labelName="Senha"
        required
        validacao={errors?.password}
        mensagemDeErro="Informe sua senha"
      >
        <input
          type="password"
          name="password"
          className="form-control"
          maxLength={10}
          ref={register({ required: true })}
        />
      </InputForm>

      <S.TermoInput htmlFor="termo" className="termo">
        <input
          id="termo"
          type="checkbox"
          name="termo"
          ref={register({
            required: true,
          })}
        />{' '}
        <p style={{ textAlign: 'left !important', margin: '0' }}>
          Declaro que li e concordo com as {'  '}
          <a href="#" onClick={() => setShowTermo(true)}>
            Condições Comerciais
          </a>{' '}
          pactuadas com a Dr Hoje.
        </p>
        <Termos show={showTermo} onHide={() => setShowTermo(false)} />
      </S.TermoInput>

      <ButtonRound
        type="button"
        disabled={loading}
        onClick={async () => {
          const goOn = await trigger();
          const values = await getValues();
          if (goOn) {
            onSubmit({ ...contentForm, ...values });
          }
        }}
      >
        finalizar
      </ButtonRound>

      <p>
        Protegido por reCAPTCHA e sujeito à{' '}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          without
          rel="noopener noreferrer"
        >
          Política de privacidade
        </a>{' '}
        e aos{' '}
        <a href="#" onClick={() => setShowTermo(true)}>
          Termos de serviço
        </a>{' '}
        da DrHoje.
        <Termos show={showTermo} onHide={() => setShowTermo(false)} />
      </p>
    </>
  );
}
