import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  border: 1px solid
    ${(props) =>
      (props.error && 'red') || (props.warning && 'orange') || '#cdced3'};
  border-radius: 20px;
  width: 100%;
  background: #fff;
  padding: 0 12px;
  font-size: 14px;

  label {
    margin: 0;
    color: #6b6b6b;
    font-size: 14px;
    margin-right: 8px;

    @media (max-width: 320px) {
      width: 50%;
    }
  }

  input,
  select {
    border: none;
    background: transparent;
    flex: 1;
    height: 36px;

    @media (max-width: 320px) {
      width: 100%;
    }
  }

  input[type='date'] {
    border: none;
    background: transparent;
    flex: 1;

    @media (max-width: 320px) {
      width: 100%;
    }
  }
  input[type='date'] {
    border: none;
    background: transparent;
    flex: 1;

    @media (max-width: 320px) {
      width: 100%;
    }
  }
  select {
    border: none;
    flex: 1;
    background: transparent;
    width: 100%;
  }
`;

export const LabelError = styled.label`
  color: ${(props) => (props.warning && 'orange') || 'red'};
`;
