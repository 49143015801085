/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import IfComponent from '~/components/if_component';
import Captcha from '~/components/recaptcha';
import iconCell from '~/assets/img/prestador/download.svg';
import CustomInputMask from '~/components/mask_input';
import InputForm from '~/components/input_form';
import AuthTermos from '~/components/auth_termos';
import AuthContainer from '~/components/auth_container';
import Button from '~/components/button';
import { ContentSoliciarSenha, ContentCodigo, InputControl } from './styles';
import useAuth from '~/config/auth_provider/contexto';
import { validaCPF } from '~/config/utils';
import { SolicitarSenhaRequest } from '~/modules/auth/actions';

const RecuperarSenha = () => {
  const recaptchaRef = React.useRef();
  const addToast = useToasts();
  const { auth } = useSelector((item) => item);
  const dataUserAuth = useAuth();
  const { login, dispatch } = dataUserAuth;

  const [send, setSend] = useState(false);
  const [cod, setCod] = useState(null);
  const [documento, setDocumento] = useState(null);

  const onSubmit = async () => {
    const token = await recaptchaRef.current.executeAsync();

    if (!cod) {
      addToast(
        'Você deve informar o código que foi enviado para os canais de comunicação cadastrado.',
        { appearance: 'error', autoDismiss: true }
      );
      return false;
    }

    login({ cpf: documento, password: cod, token });
    // eslint-disable-next-line no-unused-expressions
    recaptchaRef.current?.reset();
    return token;
  };

  const solicitarSenha = async () => {
    const valid = validaCPF(documento);
    const token = await recaptchaRef.current.executeAsync();

    if (!valid) {
      addToast(
        'Parece que o CPF informado não é válido, verifique os números e tente novamente.',
        { appearance: 'error', autoDismiss: true }
      );
      return false;
    }

    const callback = () => {
      setSend(true);
    };
    dispatch(SolicitarSenhaRequest({ cpf: documento, token, callback }));
    // eslint-disable-next-line no-unused-expressions
    recaptchaRef.current?.reset();
    return valid;
  };

  const onChange = () => {};

  return (
    <AuthContainer>
      <IfComponent hide={send}>
        <ContentSoliciarSenha>
          <form>
            <strong>Esqueci minha senha</strong>
            <p>Informe o seu CPF no campo abaixo</p>

            <p>
              Enviaremos uma mensagem de texto para o e-mail e celular que
              estiver vinculado a este CPF com sua nova senha.
              <br />
            </p>
            <InputControl>
              <InputForm labelName="CPF" required>
                <CustomInputMask
                  name="documento"
                  mask="999.999.999-99"
                  maskChar="_"
                  onChange={(e) => {
                    setDocumento(e.target.value);
                  }}
                  placeholder="999.999.999-99"
                  className="form-control"
                />
              </InputForm>
            </InputControl>

            <Captcha
              referencia={recaptchaRef}
              size="invisible"
              callback={onChange}
            />

            <Button
              type="button"
              onClick={() => solicitarSenha()}
              disabled={auth.loading}
              name="login-submit"
              id="login-submit"
              bgColor="red"
              radius="24px"
            >
              SOLICITAR
            </Button>
          </form>
          <AuthTermos />
        </ContentSoliciarSenha>
      </IfComponent>
      <IfComponent hide={!send}>
        <ContentCodigo>
          <form action="">
            <figure>
              <img src={iconCell} alt="icone celular" />
            </figure>
            <h4>
              Enviamos um código para o e-mail e celular cadastrados na
              plataforma.
            </h4>
            <p>Para realizar login, digite o código recebido abaixo.</p>

            <InputControl>
              <InputForm labelName="Código" required>
                <input
                  type="text"
                  placeholder="Código"
                  className="form-control"
                  onChange={(e) => {
                    setCod(e.target.value);
                  }}
                />
              </InputForm>
            </InputControl>

            <Captcha
              referencia={recaptchaRef}
              size="invisible"
              callback={onChange}
            />

            <Button
              type="button"
              onClick={() => onSubmit()}
              disabled={auth.loading}
              name="login-submit"
              id="login-submit"
              bgColor="red"
              radius="24px"
            >
              LOGIN
            </Button>

            <AuthTermos />
          </form>
        </ContentCodigo>
      </IfComponent>
    </AuthContainer>
  );
};

export default RecuperarSenha;
