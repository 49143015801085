import React from 'react';
import FormVendas from '~/components/form-vendas';
import { PME } from '../precificacao';

export default function ViewPme() {
  return (
    <FormVendas
      titleForm="Cadastro PME"
      subTitle="2 a 99 vidas"
      type="pme"
      produtosOption={PME()}
    />
  );
}
