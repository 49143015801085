import React from 'react';
import moment from 'moment';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { useQuery } from '@apollo/client';
import { formatarCPF } from '../../config/validate';
import { findCPF } from '../../modules/empresa/functions';
import { OBTER_BENEFICIARIOS } from '~/config/gql';
import useAuth from '~/config/auth_provider/contexto';

const customStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  headRow: {
    style: {
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

export default function Expanded({ data }) {
  const niveis = data?.pedido?.itempedidos?.[0]?.assinatura?.versoes?.[0];

  const { auth, empresa_ativa, loadingRep } = useAuth();

  console.log('data', data);

  const { data: beneficiarios, loading: loadingBeneficiarios } = useQuery(
    OBTER_BENEFICIARIOS,
    {
      context: {
        headers: {
          Authorization: auth,
        },
      },
      variables: {
        versao_assinatura_id: niveis.id,
      },
      fetchPolicy: 'no-cache',
      skip: !auth || !empresa_ativa.id || loadingRep,
    }
  );

  return (
    <DataTable
      noHeader
      dense
      pagination
      progressPending={loadingBeneficiarios}
      customStyles={customStyles}
      paginationComponentOptions={{
        noRowsPerPage: true,
        selectAllRowsItem: false,
      }}
      paginationPerPage={10}
      columns={[
        {
          name: 'Relação ID',
          selector: 'id',
          sortable: true,
        },
        {
          name: 'Beneficiário',
          selector: 'user.nome_completo',
          sortable: true,
        },
        {
          name: 'CPF Beneficiário',
          cell: (row) =>
            formatarCPF(
              findCPF(row?.user?.documentos || [])?.[0]?.nr_documento || ''
            ),

          sortable: true,
        },
        {
          name: 'Dt Nascimento',
          selector: 'user.dt_nascimento',
          cell: (row) => moment(row.user.dt_nascimento).format('DD/MM/YYYY'),
          sortable: true,
        },
        {
          name: 'Email',
          selector: 'user.email',
          sortable: true,
        },
        {
          name: 'Celular',
          selector: 'user.celular',
          sortable: true,
        },
        {
          name: 'Data Inclusão',
          selector: 'created_at',
          cell: (row) => moment(row.created_at).format('DD/MM/YYYY HH:mm:ss'),
          sortable: true,
        },
      ]}
      data={beneficiarios?.beneficiarios_assinatura || []}
    />
  );
}
