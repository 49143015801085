/**
 * Esse componente é responsável por realizar a exibição ou não do subcomponente passados via props
 */
export default function IfComponent({ hide, children }) {
  if (hide) {
    return '';
  }

  return [children];
}
