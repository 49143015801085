import styled from 'styled-components';

export const ContentDadosLine = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin-bottom: 48px;

  .modal-content {
    padding: 0 !important;
  }
`;

export const ContentCardDados = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 94px;
  padding: 42px 33px 33px 42px;
  box-shadow: 0px 1px 4px #00000029;
  background: #fff;

  span {
    margin: 0;
    line-height: 0;
    font-size: 16px;
    color: #000000de;
  }

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`;

export const CardHeaderdados = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: #707070;
  }

  a {
    margin: 0;
  }
`;

export const GroupList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;

  ul {
    list-style: none;

    li {
      width: 150px;
      padding: 2px 0;
    }
  }

  ul:last-child {
    list-style: none;

    li {
      width: 100%;
      padding: 2px 0;
    }
  }
`;

export const CardDados = styled.div``;

export const Form = styled.form`
  padding: 0 16px;

  h1 {
    font-size: 32px;
    margin-bottom: 16px;
  }

  ul {
    margin: 16px 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;

    li {
      border-bottom: 1px solid #e2e2e2;
      span {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        color: #ee0731;
        cursor: pointer;
      }
    }
  }
`;

export const FooterForm = styled.form`
  display: flex;
  justify-content: center;

  margin-top: 16px;
`;

export const GroupListPerfis = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }

  span {
    background: #0439d6;
    padding: 12px 12px;
    color: #fff;
    border-radius: 24px;

    i {
      margin-right: 8px;
    }
  }
`;
