/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import guiaPNG from '../../assets/img/material-apoio/guia.png';
import apresentacaoPNG from '../../assets/img/material-apoio/apresentacao.png';
import telemedicinaPNG from '../../assets/img/material-apoio/telemedicina.png';
import redecredenciadaPNG from '../../assets/img/material-apoio/rede-credenciada.png';

import { Container, Content, Card } from './styles';

function MaterialApoio() {
  return (
    <Container>
      <Content>
        <Card>
          <figure>
            <img src={guiaPNG} alt="Guia" />
          </figure>

          <p>Guia Comercial - Fevereiro e Março de 2022</p>

          <a
            href="https://gateway.drhoje.com/assets/pdf/guia-corretores.pdf"
            target="_blank"
          >
            <button type="button">Acessar o guia</button>
          </a>
        </Card>

        <Card>
          <figure>
            <img src={apresentacaoPNG} alt="apresentacao" />
          </figure>

          <p>Material de Apoio e Treinamento</p>
          <a
            href="https://gateway.drhoje.com/assets/pdf/apresenta%C3%A7%C3%A3o-corretores.pdf"
            target="_blank"
          >
            <button type="button">Acessar o arquivo</button>
          </a>
        </Card>

        <Card>
          <figure>
            <img src={telemedicinaPNG} alt="apresentacao" />
          </figure>

          <p>Telemedicina para Empresas (flyer para o cliente)</p>
          <a
            href="https://gateway.drhoje.com/assets/pdf/telemedicina-empresarial_corretores.pdf"
            target="_blank"
          >
            <button type="button">Acessar o arquivo</button>
          </a>
        </Card>

        <Card>
          <figure>
            <img src={redecredenciadaPNG} alt="apresentacao" />
          </figure>

          <p>Rede credenciada - DrHoje</p>
          <a
            href="https://gateway.drhoje.com/assets/pdf/Rede%20Credenciada.pdf"
            target="_blank"
          >
            <button type="button">Acessar o arquivo</button>
          </a>
        </Card>
      </Content>
    </Container>
  );
}

export default MaterialApoio;
