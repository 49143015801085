import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import InputRound from '../../../components/input_round';
import { EmpresaAtivaSelecContext } from '~/config/contexts';
import CardDadosEmpresa from './card-dados-empresa';

import {
  UpdateContatosEmpresa,
  UpdateEnderecoEmpresa,
  UpdateNomeEmpresa,
  UpdateDocumentosEmpresa,
} from '../actions';

import { Container, ContentFormulario, SubContentInlineEnd } from './styles';
import { formatarCNPJ, formatarCPF } from '~/config/utils';
// import { Card } from '~/components/card';

import Button from '~/components/button';
import CustomInputMask from '~/components/mask_input';

import useAuth from '~/config/auth_provider/contexto';
import useCEP from '~/config/useCEP';
import IfComponent from '~/components/if_component';

const DadosEmpresa = ({ hide = false }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {},
  });

  const { auth, me } = useAuth();
  const data = useContext(EmpresaAtivaSelecContext);
  const { RefetchEmpresa, LoadingEmpresa } = data;

  const [cep, setCep] = useState('');
  const [showFormEmpresa, setShowFormEmpresa] = useState(true);
  const [showFormDocumentos, setShowFormDocumentos] = useState(true);
  const [showFormContato, setShowFormContato] = useState(true);
  const [showFormEndereco, setShowFormEndereco] = useState(true);

  const { updateContatos } = UpdateContatosEmpresa();
  const { updateEndereco } = UpdateEnderecoEmpresa();
  const { updateNomeEmpresa } = UpdateNomeEmpresa();
  const { updateDocumentosEmpresa } = UpdateDocumentosEmpresa();
  const { data: dataCEP } = useCEP(cep);

  const buscarCEP = (e) => setCep(e.target.value.replace(/[^\d]+/g, ''));

  return (
    <Container>
      <div className="cardsLeft">
        {/* DADOS DA EMPRESA */}
        <CardDadosEmpresa
          title="Empresa"
          setShowForm={setShowFormEmpresa}
          showForm={showFormEmpresa}
          onSubmit={handleSubmit((dataItem) => {
            updateNomeEmpresa({
              variables: { id: data?.id, ...dataItem },
              context: {
                headers: {
                  Authorization: auth,
                },
              },
            }).then(() => {
              RefetchEmpresa();
              setShowFormEmpresa(true);
            });
          })}
        >
          {showFormEmpresa && (
            <table>
              <tbody>
                <tr>
                  <td>Nome Fantasia</td>
                  <td>{data?.nm_fantasia}</td>
                </tr>
                <tr>
                  <td>Razão Social</td>
                  <td>{data?.razao_social}</td>
                </tr>
              </tbody>
            </table>
          )}

          {!showFormEmpresa && (
            <ContentFormulario>
              <InputRound label="Nome Fantasia">
                <input
                  defaultValue={data?.nm_fantasia}
                  ref={register({ required: true })}
                  required
                  name="nm_fantasia"
                  type="text"
                />
              </InputRound>
              <InputRound label="Razão Social">
                <input
                  defaultValue={data?.razao_social}
                  ref={register({ required: true })}
                  required
                  name="razao_social"
                  type="text"
                />
              </InputRound>
              <SubContentInlineEnd>
                <Button
                  bgColor="red"
                  radius="24px"
                  disabled={LoadingEmpresa}
                  type="submit"
                >
                  Salvar
                </Button>
              </SubContentInlineEnd>
            </ContentFormulario>
          )}
        </CardDadosEmpresa>
        {/* DOCUMENTOS DA EMPRESA */}
        <CardDadosEmpresa
          title="Documentos"
          setShowForm={setShowFormDocumentos}
          showForm={showFormDocumentos}
          onSubmit={handleSubmit((dataItem) => {
            updateDocumentosEmpresa({
              variables: { id: data?.id, ...dataItem },
              context: {
                headers: {
                  Authorization: auth,
                },
              },
            }).then(() => {
              RefetchEmpresa();
              setShowFormDocumentos(true);
            });
          })}
        >
          {showFormDocumentos && (
            <table>
              <tbody>
                <tr>
                  <td>CNPJ</td>
                  <td>{formatarCNPJ(data?.documento?.nr_documento)}</td>
                </tr>
                <tr>
                  <td span="4">Inscrição Estadual</td>
                  <td>{data?.inscricao_estadual}</td>
                </tr>
                <tr>
                  <td>Inscrição INSS</td>
                  <td>{data?.inscricao_inss}</td>
                </tr>
              </tbody>
            </table>
          )}
          {!showFormDocumentos && (
            <ContentFormulario>
              <InputRound label="Inscrição Estadual">
                <input
                  defaultValue={data?.inscricao_estadual}
                  ref={register()}
                  name="inscricao_estadual"
                  type="text"
                />
              </InputRound>
              <InputRound label="Inscrição INSS">
                <input
                  defaultValue={data?.inscricao_inss}
                  ref={register()}
                  name="inscricao_inss"
                  type="text"
                />
              </InputRound>
              <SubContentInlineEnd>
                <Button
                  bgColor="red"
                  radius="24px"
                  disabled={LoadingEmpresa}
                  type="submit"
                >
                  Salvar
                </Button>
              </SubContentInlineEnd>
            </ContentFormulario>
          )}
        </CardDadosEmpresa>

        <IfComponent hide={hide}>
          <CardDadosEmpresa title="Dados do representante legal">
            <table>
              <tbody>
                <tr>
                  <td>
                    <b>Nome</b>
                  </td>
                  <td />
                  <td>{me?.nome_completo}</td>
                </tr>
                <tr>
                  <td>
                    <b> CPF</b>
                  </td>
                  <td />
                  <td>{formatarCPF(me?.documentos[0]?.nr_documento)}</td>
                </tr>
                <tr>
                  <td span="4">
                    <b>Data de nascimento</b>
                  </td>
                  <td />

                  <td>{moment(me?.dt_nascimento).format('DD/MM/YYYY')}</td>
                </tr>

                <tr>
                  <td>
                    <b>E-mail</b>
                  </td>
                  <td />

                  <td>{me?.email}</td>
                </tr>

                <tr>
                  <td>
                    <b>Sexo</b>
                  </td>
                  <td />
                  <td>{me?.sexo === 'M' ? 'Masculino' : 'Feminino'}</td>
                </tr>

                <tr>
                  <td>
                    <b>Celular</b>
                  </td>
                  <td />
                  <td>{me?.celular}</td>
                </tr>
              </tbody>
            </table>
          </CardDadosEmpresa>
        </IfComponent>

        {/* ENDERECO EMPRESA  */}
        <CardDadosEmpresa
          title="Endereço"
          setShowForm={setShowFormEndereco}
          showForm={showFormEndereco}
          onSubmit={handleSubmit((dataItem) => {
            updateEndereco({
              variables: { id: data?.endereco?.id, ...dataItem },
              context: {
                headers: {
                  Authorization: auth,
                },
              },
            }).then(() => {
              RefetchEmpresa();
              setShowFormEndereco(true);
            });
          })}
        >
          {showFormEndereco && (
            <table>
              <tbody>
                <tr>
                  <td>CEP</td>
                  <td>{data?.endereco?.cep}</td>
                </tr>
                <tr>
                  <td>Lougradouro</td>
                  <td>{data?.endereco?.logradouro}</td>
                </tr>
                <tr>
                  <td>Endereço</td>
                  <td>{data?.endereco?.endereco}</td>
                </tr>
                <tr>
                  <td>Número</td>
                  <td>{data?.endereco?.numero}</td>
                </tr>
                <tr>
                  <td>Bairro</td>
                  <td>{data?.endereco?.bairro}</td>
                </tr>
                <tr>
                  <td>Cidade</td>
                  <td>{data?.endereco?.cidade?.nm_cidade}</td>
                </tr>
                <tr>
                  <td>Complemento</td>
                  <td>{data?.endereco?.complemento}</td>
                </tr>
              </tbody>
            </table>
          )}
          {!showFormEndereco && (
            <ContentFormulario>
              <InputRound label="CEP">
                <CustomInputMask
                  name="cep"
                  mask="99999-999"
                  register={register({ required: true })}
                  maskChar="_"
                  defaultValue={data?.endereco?.cep}
                  onChange={buscarCEP}
                  placeholder="ex. 99999-999"
                />
              </InputRound>
              <InputRound label="Endereço">
                <input
                  type="text"
                  placeholder="Endereço"
                  required
                  defaultValue={data?.endereco?.endereco}
                  name="endereco"
                  ref={register({ required: true })}
                />
              </InputRound>

              <InputRound label="Lougradouro">
                <input
                  type="text"
                  name="logradouro"
                  readOnly
                  value={
                    dataCEP?.buscarcep?.te_logradouro ||
                    data?.endereco?.logradouro
                  }
                  ref={register({ required: false })}
                  placeholder="Logradouro"
                />
              </InputRound>
              <InputRound label="Número">
                <input
                  type="text"
                  placeholder=""
                  defaultValue={data?.endereco?.numero}
                  required
                  name="numero"
                  ref={register({ required: true })}
                />
              </InputRound>
              <InputRound label="Bairro">
                <input
                  type="text"
                  placeholder="-"
                  name="bairro"
                  readOnly
                  defaultValue={
                    dataCEP?.buscarcep?.te_bairro || data?.endereco?.logradouro
                  }
                  ref={register({ required: true })}
                />
              </InputRound>
              <InputRound label="Cidade">
                <input
                  type="text"
                  placeholder="-"
                  name="cidade"
                  readOnly
                  value={
                    dataCEP?.buscarcep?.cidade?.nm_cidade ||
                    data?.endereco?.cidade?.nm_cidade
                  }
                  ref={register({ required: true })}
                />
                <input
                  type="hidden"
                  placeholder="-"
                  name="cidade_id"
                  readOnly
                  value={
                    dataCEP?.buscarcep?.cidade?.id || data?.endereco?.cidade?.id
                  }
                  ref={register({ required: true })}
                />
              </InputRound>
              <InputRound label="Estado">
                <input
                  type="text"
                  placeholder="Ponto de referência/Apartamento/Bloco"
                  name="estado"
                  readOnly
                  value={
                    dataCEP?.buscarcep?.cidade?.sg_cidade ||
                    data?.endereco?.cidade?.sg_cidade
                  }
                  ref={register({ required: false })}
                />
              </InputRound>
              <InputRound label="Complemento">
                <input
                  type="text"
                  placeholder="Ponto de referência/Apartamento/Bloco"
                  name="complemento"
                  ref={register({ required: false })}
                />
              </InputRound>
              <SubContentInlineEnd>
                <Button
                  bgColor="red"
                  radius="24px"
                  disabled={LoadingEmpresa}
                  type="submit"
                >
                  Salvar
                </Button>
              </SubContentInlineEnd>
            </ContentFormulario>
          )}
        </CardDadosEmpresa>
      </div>

      <div className="cardsRight">
        {/* CONTATOS EMPRESA */}
        <CardDadosEmpresa
          title="Contato"
          showAtualizar={!hide}
          setShowForm={setShowFormContato}
          showForm={showFormContato}
          onSubmit={handleSubmit((dataItem) => {
            updateContatos({
              variables: { id: data?.id, ...dataItem },
              context: {
                headers: {
                  Authorization: auth,
                },
              },
            }).then(() => {
              RefetchEmpresa();
              setShowFormContato(true);
            });
          })}
        >
          {showFormContato && (
            <table>
              <tbody>
                <tr>
                  <td>Telefone</td>
                  <td>{data?.contato_administrativo}</td>
                </tr>
                <tr>
                  <td>E-mail</td>
                  <td>{data?.email_empresa}</td>
                </tr>
              </tbody>
            </table>
          )}
          {!showFormContato && (
            <ContentFormulario>
              <InputRound label="Telefone">
                <CustomInputMask
                  name="contato_administrativo"
                  mask="(99) 9999-9999"
                  register={register({ required: true })}
                  maskChar="_"
                  defaultValue={data?.contato_administrativo}
                  placeholder="ex. (99) 9999-9999"
                />
              </InputRound>
              <InputRound label="E-mail">
                <input
                  defaultValue={data?.email_empresa}
                  ref={register({ required: true })}
                  required
                  name="email_empresa"
                  type="text"
                />
              </InputRound>
              <SubContentInlineEnd>
                <Button
                  bgColor="red"
                  radius="24px"
                  disabled={LoadingEmpresa}
                  type="submit"
                >
                  Salvar
                </Button>
              </SubContentInlineEnd>
            </ContentFormulario>
          )}
        </CardDadosEmpresa>
        <IfComponent hide={hide}>
          <ContentFormulario>
            {/* <Card>
              <strong>Comissão - Produtos Pessoa Física</strong>
              <p>
                <b> Produto:</b> TOTAL/TODOS/MAIS
              </p>
              <p>100% da primeira parcela</p>
              <p>5% vitalício</p>
            </Card>

            <Card>
              <strong>Recebimento de comissão</strong>
              <table>
                <tr>
                  <th>Vendas</th>
                  <th>Emissão de NF</th>
                  <th>Recebimento</th>
                </tr>

                <tr>
                  <td>1 a 16</td>
                  <td>17 a 20</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>17 a 32</td>
                  <td>1 a 05</td>
                  <td>10</td>
                </tr>
              </table>
            </Card> */}
          </ContentFormulario>
        </IfComponent>
      </div>
    </Container>
  );
};

export default DadosEmpresa;
