/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Captcha from '~/components/recaptcha';
import CustomInputMask from '~/components/mask_input';
import InputForm from '../../components/input_form';
import useAuth from '~/config/auth_provider/contexto';
import * as S from './styles';

import AuthContainer from '~/components/auth_container';
import ButtonRound from '~/components/button_round';
import Cadastro from './cadastro';
import IfComponent from '~/components/if_component';
import Caminho from './caminho';
import VincularCorretora from './vincularCorretora';

const Login = (props) => {
  const recaptchaRef = React.useRef();
  const [showForm, setShowForm] = useState('caminho');
  const { register, handleSubmit, clearErrors, errors } = useForm({
    defaultValues: {},
  });
  const useraht = useAuth();
  const { login } = useraht;
  const state = useSelector((st) => st);

  useEffect(() => {
    if (props.location.pathname === '/login') {
      setShowForm('login');
    }
  }, [props.location.pathname]);

  return (
    <AuthContainer>
      <S.ContentForm>
        <IfComponent hide={showForm !== 'login'}>
          <form
            id="login-form"
            onSubmit={handleSubmit(async (data) => {
              const token = await recaptchaRef.current.executeAsync();

              login({ ...data, token });
              // eslint-disable-next-line no-unused-expressions
              recaptchaRef.current?.reset();
              return true;
            })}
            className="text-center"
          >
            <h3 className="title">Login</h3>

            <p>
              Não tem uma conta?{' '}
              <span
                role="button"
                className="button-blue"
                onClick={() => {
                  setShowForm('caminho');
                }}
              >
                Fazer cadastro
              </span>
            </p>

            <S.LoginInfo>
              <p>
                <strong>Plataforma</strong> (PJ) Loga com o CPF do representante
                legal
              </p>
              <p>
                <strong>Corretor</strong> (CPF) Loga com o CPF do corretor{' '}
              </p>
            </S.LoginInfo>

            <S.InputControl>
              <InputForm
                {...{
                  labelName: 'CPF',
                }}
                required
                validacao={errors?.cpf}
                mensagemDeErro="Informe um CPF válido"
              >
                <CustomInputMask
                  name="cpf"
                  mask="999.999.999-99"
                  register={register({ required: true })}
                  maskChar="_"
                  required
                  placeholder="xxx.xxx.xxx-xx"
                  className="form-control"
                />
              </InputForm>
            </S.InputControl>
            <S.InputControl className="lastInput">
              <InputForm
                {...{
                  labelName: 'Senha',
                }}
                required
                validacao={errors?.password}
                mensagemDeErro="Informe a senha"
              >
                <input
                  type="password"
                  placeholder="Informe a senha"
                  onChange={() => clearErrors('password')}
                  name="password"
                  ref={register({ required: true })}
                  className="form-control"
                />
              </InputForm>
            </S.InputControl>
            <ButtonRound
              disabled={state.auth.loading}
              type="submit"
              name="login-submit"
              id="login-submit"
              value="Log In"
            >
              Login
            </ButtonRound>
            <Link to="/recuperar-senha" className="recuperar-senha">
              Esqueci minha senha
            </Link>

            <p>
              Protegido por reCAPTCHA e sujeito à{' '}
              <a href="https://policies.google.com/privacy">
                Política de privacidade
              </a>{' '}
              e aos{' '}
              <a href="https://policies.google.com/terms">Termos de serviço </a>
              do Google.
            </p>
          </form>
        </IfComponent>

        <IfComponent hide={showForm !== 'cadastro'}>
          <Cadastro setCadastro={setShowForm} recaptchaRef={recaptchaRef} />
        </IfComponent>
        <IfComponent hide={showForm !== 'vincular'}>
          <VincularCorretora
            setCadastro={setShowForm}
            recaptchaRef={recaptchaRef}
          />
        </IfComponent>

        <IfComponent hide={showForm !== 'caminho'}>
          <Caminho setShowForm={setShowForm} />
        </IfComponent>

        <div className="captcha-control">
          <Captcha referencia={recaptchaRef} size="invisible" />
        </div>
      </S.ContentForm>
    </AuthContainer>
  );
};

export default Login;
