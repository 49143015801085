import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  margin: 20px;
  form {
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    margin-top: 30px;
    padding: 30px 46px;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (max-width: 1440px) {
      padding: 18px;
    }

    div.input-control {
      max-width: 340px;
      width: 100%;

      @media (max-width: 800px) {
        max-width: initial;
      }
    }

    div.group-input-row {
      display: flex;

      @media (max-width: 1500px) {
        flex-wrap: wrap;
      }
    }

    div.group-input {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
    }

    div.double-input {
      display: flex;
      gap: 8px;

      @media (max-width: 800px) {
        flex-wrap: wrap;
        margin-bottom: 0;
      }
    }

    @media (max-width: 1500px) {
      div.double-input {
        margin-bottom: 8px;
      }
    }

    @media (max-width: 800px) {
      div.margin {
        margin-bottom: 8px;
      }
    }

    div.espaco {
      width: 30px;
    }
  }

  @media (max-width: 800px) {
    padding: 0 16px;
    margin: 0;
  }
`;

export const ButtonAdicionarColaborador = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  max-width: 279px;
  width: 100%;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 4px;

  display: flex;
  align-items: center;

  margin-bottom: 32px;
  color: #0439d6;
  cursor: pointer;

  span {
    padding: 18px 20px;
    border-right: 1px solid #e9edf1;
  }

  p {
    padding: 18px;
    color: #0439d6;
  }
`;

export const SectionFormPaciente = styled.div`
  margin-bottom: 24px;
  margin-top: 44px;

  h5 {
    font-size: 16px;
    font-weight: bold;
  }

  strong {
    i {
      margin-right: 8px;
    }
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const ButtonControl = styled.div`
  margin: 10px;

  .gradient {
    background-size: 300% 100%;

    background-image: linear-gradient(
      to right,
      #7e0754,
      #ed0732,
      #7e0754,
      #ed0732
    );

    border-radius: 50px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-position: 100% -100%;

      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
  }
`;
