import React from 'react';
import Button from '~/components/button';
import logo from '~/assets/img/logo.svg';

import { AtualizarEmpresaAtiva } from '~/modules/auth/actions';
import useAuth from '~/config/auth_provider/contexto';
import InputRound from '~/components/input_round';
import { formatarCNPJ } from '~/config/utils';

import {
  Container,
  CardLine,
  CardPrestador,
  InfoPagina,
  InfoEmpresa,
  SelecionarEmpresa,
} from './styles';
import IfComponent from '~/components/if_component';

export default function Header({ title, texto }) {
  const { dataRep, dispatch, auth_object, empresa_ativa, logout } = useAuth();

  return (
    <Container>
      <CardLine>
        <IfComponent hide={!empresa_ativa?.id}>
          <CardPrestador>
            <div className="img-control">
              <img
                src={empresa_ativa?.logo ? empresa_ativa?.logo : logo}
                alt="logo da empresa"
              />
            </div>
            <InfoEmpresa>
              <SelecionarEmpresa>
                <InputRound label="Empresa">
                  <select
                    name=""
                    id="nome"
                    onChange={(elemento) =>
                      dispatch(
                        AtualizarEmpresaAtiva(JSON.parse(elemento.target.value))
                      )
                    }
                  >
                    {dataRep?.map((item) => (
                      <option
                        value={JSON.stringify(item?.empresa)}
                        selected={
                          item?.empresa?.id === auth_object.empresa_ativa?.id
                        }
                      >
                        {item?.empresa?.nm_fantasia}
                      </option>
                    ))}
                  </select>
                </InputRound>
              </SelecionarEmpresa>
              <span>
                <strong> CNPJ: </strong>{' '}
                {formatarCNPJ(empresa_ativa?.documento?.nr_documento)}
              </span>
            </InfoEmpresa>
            {/* )} */}
          </CardPrestador>
        </IfComponent>
        <div className="button-control">
          <Button
            type="button"
            bgColor="gray"
            radius="24px"
            largura="100%"
            onClick={() => logout()}
          >
            Sair
          </Button>
        </div>
      </CardLine>

      <InfoPagina>
        <h1>{title}</h1>
        <p>{texto}</p>
      </InfoPagina>
    </Container>
  );
}
