/* eslint-disable no-restricted-syntax */

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportCSV = ({ csvData: dataItem, fileName: name }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const colNum = XLSX.utils.decode_col('O'); // decode_col converts Excel col name to an integer for col #
    const fmt = '#,##0.00'; // or any Excel number format
    const range = XLSX.utils.decode_range(ws['!ref']);
    // eslint-disable-next-line no-plusplus
    for (let i = range.s.r + 1; i <= range.e.r; ++i) {
      const ref = XLSX.utils.encode_cell({ r: i, c: colNum });
      // eslint-disable-next-line no-continue
      if (!ws[ref]) continue;
      /* `.t == "n"` for number cells */
      // eslint-disable-next-line no-continue
      if (ws[ref].t !== 'n') continue;
      /* assign the `.z` number format */
      ws[ref].z = fmt;
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  exportToCSV(dataItem, name);
};

export function csvJSON(csv) {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',');

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < lines.length; i++) {
    // eslint-disable-next-line no-continue
    if (!lines[i]) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const obj = {};
    const currentline = lines[i].split('",');

    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j].replace('"', '');
    }
    result.push(obj);
  }
  return result;
}

export function parseXmlToJson(xml) {
  const json = {};
  for (const res of xml.matchAll(
    /(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm
  )) {
    const key = res[1] || res[3];
    const value = res[2] && parseXmlToJson(res[2]);
    json[key] = (value && Object.keys(value).length ? value : res[2]) || null;
  }
  return json;
}
