/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-unused-expressions */

import React from 'react';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import {
  Content,
  SectionFormPaciente,
  ButtonsGroup,
  ButtonControl,
} from './styles';

import InputRound from '~/components/input_round';
import ButtonRound from '~/components/button_round';
import CustomInputMask from '~/components/mask_input';
import Header from '../../../layouts/header';

import IfComponent from '~/components/if_component';

import { checkIsCPF, checkIsCelular } from '~/config/util';
import { CADASTRAR_CORRETOR } from '~/config/gql';
import useAuth from '~/config/auth_provider/contexto';
import { RequestSuccess, RequestFailed } from '~/config/alert';

const NovoCorretor = ({
  setNovaCorretor,
  listarCorretores,
  equipe_venda_id,
}) => {
  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: {},
  });
  const { auth, dispatch, empresa_ativa } = useAuth();
  const empresa_id = empresa_ativa.id;

  const [cadastrarCorretor, { loading }] = useMutation(CADASTRAR_CORRETOR, {
    context: {
      headers: {
        Authorization: auth,
      },
    },
    skip: !empresa_id || !auth || !equipe_venda_id,
  });

  const onSubmit = (data) => {
    cadastrarCorretor({
      variables: {
        data_user: { ...data },
        empresa_id,
        equipe_id: equipe_venda_id,
      },
    })
      .then(() => {
        setNovaCorretor?.(false);
        listarCorretores?.();
        dispatch(RequestSuccess('Corretor cadastrado com sucesso!'));
      })
      .catch((e) => {
        const message =
          e.graphQLErrors?.[0]?.extensions?.reason ||
          'Não conseguimos realizar o cadastro do corretor, tente novamente!';
        dispatch(RequestFailed(message));
      });
  };

  return (
    <Content>
      <Header
        title="Cadastrar Corretor"
        texto="Cadastre corretor na empresa previamente selecionada. Você também pode acompanhar o extrato de procedimentos utilizados pelos ."
      />

      <SectionFormPaciente>
        <strong
          role="button"
          onClick={() => {
            setNovaCorretor?.(false);
            listarCorretores?.();
          }}
        >
          <i className="fas fa-arrow-left" />
          Dados do corretor
        </strong>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="double-input">
            <InputRound
              label="CPF"
              required
              error={errors?.documento || false}
              message={
                errors?.documento?.message ||
                'Você deve informar um CPF válido.'
              }
            >
              <Controller
                as={<CustomInputMask />}
                onChange={([e]) => ({ value: e })}
                rules={{
                  validate: {
                    inputTelRequired: checkIsCPF,
                  },
                  required: true,
                }}
                name="documento"
                mask="999.999.999-99"
                maskChar="_"
                placeholder="ex. 999.999.999-99"
                control={control}
              />
            </InputRound>
          </div>
          <div className="group-input-row">
            <div className="group-input">
              <div className="flex-1">
                <InputRound
                  label="Nome Completo"
                  error={errors?.nome_completo || false}
                  message={
                    errors?.nome_completo?.message ||
                    'Você deve informar o nome completo.'
                  }
                >
                  <input
                    type="text"
                    name="nome_completo"
                    maxLength="100"
                    ref={register({ required: true, mix: 2, max: 100 })}
                  />
                </InputRound>
              </div>
              <div className="double-input">
                <InputRound
                  label="Data de Nasc"
                  error={errors?.dt_nascimento || false}
                  message={
                    errors?.dt_nascimento?.message ||
                    'Você deve informar a data de nascimento.'
                  }
                >
                  <input
                    type="date"
                    name="dt_nascimento"
                    ref={register({ required: true })}
                  />
                </InputRound>
                <div className="espaco" />
                <InputRound label="Gênero">
                  <select name="sexo" ref={register({ required: true })}>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                  </select>
                </InputRound>
              </div>
            </div>
            <div className="espaco" />

            <div className="group-input">
              <div className="double-input">
                <InputRound
                  label="E-mail"
                  required
                  error={errors?.email || false}
                  message={
                    errors?.email?.message ||
                    'Você deve informar um e-mail válido.'
                  }
                >
                  <input
                    type="email"
                    name="email"
                    ref={register({ required: true })}
                  />
                </InputRound>
              </div>
              <div className="double-input">
                <InputRound
                  label="Celular"
                  error={errors?.celular || false}
                  message={
                    errors?.celular?.message ||
                    'Você deve informar um celular válido.'
                  }
                >
                  <Controller
                    as={<CustomInputMask />}
                    onChange={([e]) => ({ value: e })}
                    rules={{
                      validate: {
                        inputTelRequired: checkIsCelular,
                      },
                      required: true,
                    }}
                    name="celular"
                    mask="(99) 9 9999-9999"
                    maskChar="_"
                    placeholder="ex. (99) 9 9999-9999"
                    control={control}
                  />
                </InputRound>
              </div>
            </div>
          </div>
          <ButtonsGroup>
            <div>
              <ButtonControl>
                <IfComponent hide={loading}>
                  <ButtonRound
                    bgColor="gray"
                    onClick={() => {
                      setNovaCorretor?.(false);
                      listarCorretores?.();
                    }}
                  >
                    Cancelar
                  </ButtonRound>
                </IfComponent>
              </ButtonControl>
            </div>
            <ButtonControl>
              <ButtonRound
                type="submit"
                disabled={loading}
                className="gradient"
              >
                Salvar
              </ButtonRound>
            </ButtonControl>
          </ButtonsGroup>
        </form>
      </SectionFormPaciente>
    </Content>
  );
};

export default NovoCorretor;
