import React from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Assinatura } from './styles';
import ButtonsAcao from '../../components/buttons_acao_datatable';
import { siteURL } from '~/config/api';

export const columns = ({
  addToast,
  userid,
  corretor_id,
  nome_completo,
  nm_empresa,
  ds_equipe,
}) => [
  {
    name: 'Nome',
    cell: () => nome_completo,
  },
  {
    name: 'Corretora',
    cell: () => nm_empresa,
  },
  {
    name: 'Equipe',
    cell: () => ds_equipe,
  },
  {
    name: 'Empresa',
    cell: (row) => row.versao_contrato?.contrato?.empresa?.nm_fantasia,
  },
  {
    name: 'Assinatura',

    cell: (row) => <Assinatura>{row?.plano?.plano}</Assinatura>,
  },
  {
    name: 'Realizar vendas',
    selector: 'acoes',
    wrap: true,
    cell: (row) => (
      <ButtonsAcao>
        <Link
          className="Link-check"
          to={`${siteURL}/${row.link_adesao}&check_detalhe=${Buffer.from(
            userid
          ).toString('base64')}&check_corretora=${Buffer.from(
            corretor_id
          ).toString('base64')}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${siteURL}/${row.link_adesao}&check_detalhe=${Buffer.from(
              userid
            ).toString('base64')}&check_corretora=${Buffer.from(
              corretor_id
            ).toString('base64')}`}
          >
            <i className="link fas fa-external-link" /> Realizar venda
          </a>
        </Link>
      </ButtonsAcao>
    ),
  },
  {
    name: 'Links',
    selector: 'acoes',
    wrap: true,
    cell: (row) => (
      <ButtonsAcao>
        <CopyToClipboard
          className="Link-check"
          text={`${siteURL}/${row.link_adesao}&check_detalhe=${Buffer.from(
            userid
          ).toString('base64')}&check_corretora=${Buffer.from(
            corretor_id
          ).toString('base64')}`}
          onCopy={() => {
            addToast('Link copiado com sucesso!', {
              appearance: 'success',
              autoDismiss: true,
            });
          }}
        >
          <span role="button" id="copy">
            <i className="link fas fa-copy" /> Copiar link
          </span>
        </CopyToClipboard>
      </ButtonsAcao>
    ),
  },
];

export const customStyles = {
  headRow: {
    style: {
      border: 'none',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',

      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
};

export const paginationOptions = {
  rowsPerPageText: 'Itens por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: false,
  selectAllRowsItemText: 'Todos',
};
