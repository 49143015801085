import { createContext, useContext } from 'react';

export const AuthContext = createContext({
  isAuthenticated: false,
  auth: null,
  auth_object: null,
  login: null,
  loading: false,
  logout: null,
  dataCargoRepre: null,
  dataRep: [],
  refetchRep: null,
  loadingRep: false,
  saldo: {
    debito: 0,
    credito: 0,
    disponivel: 0,
  },
  LoadingSaldo: false,
  RefechSaldo: () => {},
});
export default function useAuth() {
  return useContext(AuthContext);
}
