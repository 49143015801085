/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation } from '@apollo/client';

import {
  ATUALIZAR_CONTATO_EMPRESA,
  ATUALIZAR_ENDERECO_EMPRESA,
  ATUALIZAR_LOGO_EMPRESA,
  ATUALIZAR_NOME_EMPRESA,
  ATUALIZAR_DOC_EMPRESA,
  ATUALIZAR_DADOS_REPRESENTANTE,
} from '~/config/gql';

export const UpdateContatosEmpresa = () => {
  const [updateContatos] = useMutation(ATUALIZAR_CONTATO_EMPRESA);

  return { updateContatos };
};

export const UpdateEnderecoEmpresa = () => {
  const [updateEndereco] = useMutation(ATUALIZAR_ENDERECO_EMPRESA);

  return { updateEndereco };
};

export const UpdateLogoEmpresa = () => {
  const [updateLogoEmpresa] = useMutation(ATUALIZAR_LOGO_EMPRESA);

  return { updateLogoEmpresa };
};

export const UpdateNomeEmpresa = () => {
  const [updateNomeEmpresa] = useMutation(ATUALIZAR_NOME_EMPRESA);

  return { updateNomeEmpresa };
};

export const UpdateDocumentosEmpresa = () => {
  const [updateDocumentosEmpresa] = useMutation(ATUALIZAR_DOC_EMPRESA);

  return { updateDocumentosEmpresa };
};
export const UpdateDadosRepresentante = () => {
  const [updateDadosRepresentante] = useMutation(ATUALIZAR_DADOS_REPRESENTANTE);

  return { updateDadosRepresentante };
};
