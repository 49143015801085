import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Side } from './styles';
import useAuth from '~/config/auth_provider/contexto';

export default function Sidebar() {
  const [showMenu, setShowMenu] = useState(false);
  const [dropDown, setShowDropDown] = useState(false);
  const { routes } = useAuth();
  function handleShowMenu() {
    setShowMenu(!showMenu);
  }

  const RenderRoutes = ({ routes_default }) =>
    routes_default?.map((item) => (
      <li>
        <NavLink activeClassName="is-active" to={item.route}>
          <i className={item.icon} />
          <span>{item.name}</span>
        </NavLink>
      </li>
    ));

  return (
    <Container>
      <Side showMenu={showMenu}>
        <ul>
          <RenderRoutes routes_default={routes.routes} />
          <li className="menu">
            <input
              type="checkbox"
              name="menu"
              id="teste1"
              onChange={() => setShowDropDown(!dropDown)}
            />
            <li>
              <label htmlFor="teste1" className="item">
                <i className="fa fa-link" />
                <span>Vendas Novas</span>
              </label>
              {dropDown && (
                <>
                  <NavLink
                    to="/links-venda"
                    activeClassName="is-active"
                    className="sub-item"
                  >
                    <span>Individual</span>
                  </NavLink>
                  {/* <NavLink
                    to="/venda-pme"
                    activeClassName="is-active"
                    className="sub-item"
                  >
                    <span>PME 2 a 99 vidas</span>
                  </NavLink>
                  <NavLink
                    to="/venda-empresarial"
                    activeClassName="is-active"
                    className="sub-item"
                  >
                    <span>Empresarial a partir de 100 vidas</span>
                  </NavLink> */}
                  <a
                    href="https://drhoje.planium.io/web/login/entrar?target=venda"
                    className="sub-item"
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                    rel="noopener"
                  >
                    <span>PME a partir de 2 vidas</span>
                  </a>
                </>
              )}
            </li>
          </li>
        </ul>
        <input type="radio" name="menu" id="sub-item" />
        <label className="linkRecolherSidebar" onClick={handleShowMenu}>
          <i className="fal fa-chevron-circle-left" />
          <i title="Mostrar menu" className="fal fa-chevron-circle-right" />
          <span className="recolher">recolher menu</span>
        </label>
      </Side>
    </Container>
  );
}
