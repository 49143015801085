import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { textColor } from '~/styles/colors';

export const Content = styled.div`
  margin: 0 62px;
  width: 100%;

  @media (max-width: 800px) {
    margin: 0;
    padding: 0 12px;
  }
`;

export const BoxTabs = styled.div`
  position: relative;
  display: flex;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const BoxInputs = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 8px 0;
  span {
    color: ${textColor};
  }

  button {
    background-size: 300% 100%;

    background-image: linear-gradient(
      to right,
      #7e0754,
      #ed0732,
      #7e0754,
      #ed0732
    );

    border-radius: 50px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-position: 100% -100%;

      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
  }
`;

export const ColaboradoresTabela = styled.div`
  div.rdt_TableHeader {
    display: none;
  }

  .popover-body {
    box-shadow: 0px 3px 6px #00000029;
    padding: 18px;
  }

  .popover {
    border: 0;
  }

  .funcionalidade-colaboradores {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;

    .import-group {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;

      @media (max-width: 1200px) {
        flex-direction: column;
        margin: 24px 0;
        align-items: flex-start;
      }
    }

    @media (max-width: 1200px) {
      margin: 24px 0;
      flex-direction: column;
    }
  }

  .gaMRwo {
    margin-right: auto;
  }
`;

export const DicaTooltip = styled.div`
  margin-right: 32px;
  color: #0439d6;
  font-weight: 600;

  i {
    margin-right: 8px;
    font-size: 16px;
  }

  @media (max-width: 1200px) {
    margin: 24px 0;
  }
`;

export const InfoTooltip = styled.div`
  display: flex;
  flex-direction: column;

  button {
    background: #0439d6;
    color: #fff;
    padding: 4px 0;
    margin-top: 8px;
    border-radius: 25px;
    width: 100%;
    text-align: center;
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  .acao {
    display: flex;

    ul {
      margin-left: 4px;
    }
  }
  strong {
    color: #575757;
  }

  p {
    strong {
      color: #575757;
    }
    color: #989898;
    margin-bottom: 8px;
  }
`;

export const CardImportExcel = styled.div`
  display: flex;
  align-items: center;
  color: #0439d6;

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  padding: 18px;
  border-radius: 4px;
  max-width: 279px;
  width: 100%;
  cursor: pointer;

  figure {
    margin-right: 12px;
    margin-bottom: 0;
    width: 33px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 50%;
    padding: 6px;
    img {
      display: block;
      max-width: 100%;
    }
  }
`;

export const ButtonAdicionarColaborador = styled(Link)`
  background: #ffffff 0% 0% no-repeat padding-box;
  max-width: 279px;
  width: 100%;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 4px;
  align-items: baseline;
  display: flex;
  align-items: center;

  color: #0439d6;
  cursor: pointer;

  span {
    padding: 18px 20px;
    border-right: 1px solid #e9edf1;
  }

  p {
    padding: 18px;
    color: #0439d6;
    margin: 0;
  }

  @media (max-width: 700px) {
    padding: 5px;
  }
`;

export const CustomStyleDataTable = styled.div`
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 8px;
  padding: 16px;
  background: #fff;
  margin-bottom: 150px;

  .control-input {
    margin-bottom: 32px;
  }
`;

export const Assinatura = styled.span`
  padding: 8px 0;
  margin: 5px;
  width: 138px;
  background: #e9edf1;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000a8;
  border-radius: 5px;
`;

export const ButtonsAcao = styled.div`
  display: flex;

  button.edit {
    border: 0;
    margin-right: 8px;
  }
  a {
    margin: 5px;
  }
  button {
    margin: 5px;
  }

  .remove {
    border: none;
    color: red;
  }
`;

export const ExportButton = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    align-items: center;
    border: none;
    background-color: white;

    border-radius: 22px;
    transition: ease-in 0.2s;

    &:hover {
      background-color: #ececec;
    }
  }
`;

export const CardStatus = styled.div`
  margin: 5px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000a8;
  border-radius: 5px;
  color: ${(props) => props.red && 'white'};
  background: ${(props) => props.red && 'red'};

  color: ${(props) => props.green && 'white'};
  background: ${(props) => props.green && '#1e7e34'};
`;
