import Axios from 'axios';

export const urls = {
  test: process.env.REACT_APP_URL_API_TEST,
  development: process.env.REACT_APP_URL_API_HOMO,
  production: process.env.REACT_APP_URL_API_PROD,
};

const urlSites = {
  test: process.env.REACT_APP_URL_SITE_TEST,
  development: process.env.REACT_APP_URL_SITE_HOMO,
  production: process.env.REACT_APP_URL_SITE_PROD,
};

export const siteURL = urlSites[process.env.NODE_ENV];

const api = Axios.create({
  baseURL: urls[process.env.NODE_ENV],
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default api;
