import { createGlobalStyle } from 'styled-components';
import { darken } from 'polished';

import {
  textColor,
  defaultButton,
  link,
  btnEncerrarContrato,
  red,
} from './colors';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;

    font-size: 16px;

    @media(max-width: 1440px){
      font-size: 14px;

    }



      &:focus {
        outline:0 !important;
        box-shadow: none !important;
      }
  }
  html, body, #root{
    width: 100%;
    box-sizing: border-box;
  }



  h1, h2, h3, h4, p{
    margin: 0px;
    color: ${textColor};
  }



  ul, li{
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

  a, .link{
    color: ${link};
    text-decoration: none;

    &:hover{
      color: ${darken(0.1, link)};
      text-decoration: none;
    }
  }

  img{
    display: block;
    max-width: 100%;
  }

  button, input{
    display: block;
    font-size: 1rem;
    font-family: var(--type-first);
    color: #333;
  }


  /* columns */
  .col{
    display: flex;
    flex-direction: column;
  }
  .mesma-linha {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  /* buttons */
  .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: fit-content;
    background-color: ${defaultButton};
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.2px;
    color: white;
    transition: all .5s ease-in;

    i{
      margin-right:8px;
    }

    &:hover, &:active{
        color: white;
        background-color: ${darken(0.05, defaultButton)};
    }
  }
  .btn-red{
    background-color: ${btnEncerrarContrato};
    flex-wrap: nowrap;
    white-space: nowrap;

    &:hover, &:active{
      color: white;
      background-color: ${darken(0.2, btnEncerrarContrato)};
    }
  }
  .btn-rounded{
    justify-content: center;
    border-radius: 22px;
  }
  .btn-outline{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 12px 40px;
    width: fit-content;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.6px;
    color: ${defaultButton};
    transition: all .5s ease-in;
    border: 1px solid ${defaultButton};
    border-radius:4px;
  }


  .btn-outline-file-input {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width:120px;
    padding: 12px 40px;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.6px;
    color: ${defaultButton};
    cursor: pointer;
    transition: all .5s ease-in;
    border: 1px solid ${defaultButton};
    border-radius:4px;
  }
  .btn-outline-file-input::-webkit-file-upload-button {
  visibility: hidden;
  }
  .remove-before {
    padding: 0!important;
  }
  .btn-outline-file-input.remove-before::before {
    content: '';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border-radius: 3px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .btn-outline-file-input::before {
    content: 'CSV';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border-radius: 3px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

  /* btn para exportar tabelas */
  .btn-exportacao {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: white;
    padding: 12px;
    border-radius: 22px;
    transition: ease-in 0.2s;

    &:hover {
      background-color: ${darken(0.05, '#f9f9f9')};
    }
  }
  /* link */
  .link{
    color: ${link};
    border: none;
    background: transparent;

    &.red{
      color: ${red};
    }
  }
  /* modal */
  .modal-content{
    padding: 30px;
    border: none;
    border-radius: 30px;

    .link{
      margin-left: auto;
    }

    @media(max-width: 720px){
      padding: 0;
    }
  }

  /* =================TABLE STYLES=================*/
  .rdt_TableHeader{
    flex: none;
    .hhrSAx{
      font-size: 18px;
      font-weight: bold;
    }
  }
  .eJYrX{ /*campo da tabela */
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .rdt_Table {
    /* header */
    .rdt_TableHeadRow{
      .rdt_TableCol{
        font-size: 13px;
        font-weight: 700;
        color: ${textColor}
      }
    }
    /* dados da tabela */
    .rdt_TableRow {
      .rdt_TableCell{
        font-size: 13px;
        font-weight: normal;
        color: ${textColor};

        a {
          color: ${link};

          &:hover {
            color: ${darken(0.1, link)};
          }
        }
      }
    }
  }
  .rdt_Pagination{
    display: flex;
    flex: none;
    margin-top: auto;
  }
  .table-title {
    font-size: 16px;
    font-weight: bold;
  }

  /* padrões de largura */
  .w-40{
   width: 40%;
  }
  .w-50{
   width: 50%;
  }
  .w-75{
   width: 75%;
  }
  .w-100{
   width: 100%;
  }
  .w-275{
    width: 275px;
  }

  /* container */
  .whiteContainer{
    display: flex;
    width: 100%;
    max-width: 100%;
    flex: 1;
    background-color: white;
    border-radius: 10px;
    padding: 24px;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1280px){
    .whiteContainer{
      padding: 24px 16px;
    }
  }


  .loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .19));

  background: -webkit-radial-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255,.19));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(25,25,25, 255) 1.5em 0 0 0, rgba(25,25,25,255) 1.1em 1.1em 0 0, rgba(25,25,25,255) 0 1.5em 0 0, rgba(25,25,25,255) -1.1em 1.1em 0 0, rgba(25,25,25,255) -1.5em 0 0 0, rgba(25,25,25,255) -1.1em -1.1em 0 0, rgba(25,25,25,255) 0 -1.5em 0 0, rgba(25,25,25,255) 1.1em -1.1em 0 0;
box-shadow: rgba(25,25,25,255) 1.5em 0 0 0, rgba(25,25,25,255) 1.1em 1.1em 0 0, rgba(25,25,25,255) 0 1.5em 0 0, rgba(25,25,25,255) -1.1em 1.1em 0 0, rgba(25,25,25,255) -1.5em 0 0 0, rgba(25,25,25,255) -1.1em -1.1em 0 0, rgba(25,25,25,255) 0 -1.5em 0 0, rgba(25,25,25,255) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
`;
