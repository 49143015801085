import React from 'react';
import { Controller } from 'react-hook-form';
import ButtonRound from '~/components/button_round';
import CustomInputMask from '~/components/mask_input';
import InputForm from '~/components/input_form';
import * as S from '../styles';
import { checkIsCNPJ } from '~/config/utils';
import { checkIsCelular } from '~/config/util';

export default function StepOne({
  register,
  control,
  trigger,
  setShowForm,
  getValues,
  setcontentForm,
  errors,
  contentForm,
}) {
  return (
    <>
      <S.InputControl>
        <InputForm
          labelName="CNPJ"
          required
          validacao={errors?.cnpj}
          mensagemDeErro="Informe um CNPJ válido"
        >
          <Controller
            as={<CustomInputMask />}
            onChange={([e]) => ({ value: e })}
            rules={{
              validate: {
                inputCNPJRequired: checkIsCNPJ,
              },
            }}
            defaultValue={contentForm?.cnpj || ''}
            name="cnpj"
            mask="99.999.999/9999-99"
            maskChar="_"
            placeholder="__.___.___/____-__"
            className="form-control"
            control={control}
          />
        </InputForm>
      </S.InputControl>
      <S.InputControl>
        <InputForm
          labelName="Nome Fantasia"
          required
          validacao={errors?.nm_fantasia}
          mensagemDeErro="Informe o nome fantasia"
        >
          <input
            ref={register({ required: true })}
            name="nm_fantasia"
            defaultValue={contentForm?.nm_fantasia || ''}
            className="form-control"
            type="text"
          />
        </InputForm>
      </S.InputControl>
      <S.InputControl>
        <InputForm
          labelName="Razão Social"
          required
          validacao={errors?.razao_social}
          mensagemDeErro="Informe a razão social"
        >
          <input
            ref={register({ required: true })}
            name="razao_social"
            defaultValue={contentForm?.razao_social || ''}
            type="text"
            className="form-control"
          />
        </InputForm>
      </S.InputControl>

      <S.InputControl>
        <InputForm
          labelName="Celular"
          required
          validacao={errors?.contato_administrativo}
          mensagemDeErro="Informe o celular"
        >
          <Controller
            as={<CustomInputMask />}
            onChange={([e]) => ({ value: e })}
            rules={{
              validate: {
                inputCelularRequired: checkIsCelular,
              },
            }}
            name="contato_administrativo"
            defaultValue={contentForm?.contato_administrativo || ''}
            mask="(99) 9 9999-9999"
            maskChar="_"
            placeholder="(00) 9 0000-0000"
            className="form-control"
            control={control}
          />
        </InputForm>
      </S.InputControl>

      <S.InputControl>
        <InputForm
          labelName="Email"
          required
          validacao={errors?.email_empresa}
          mensagemDeErro="Informe o e-mail"
        >
          <input
            ref={register({ required: true })}
            name="email_empresa"
            defaultValue={contentForm?.email_empresa || ''}
            type="email"
            className="form-control"
          />
        </InputForm>
      </S.InputControl>

      <ButtonRound
        type="button"
        name="login-submit"
        id="login-submit"
        value="Log In"
        onClick={async () => {
          const goOn = await trigger();
          const values = await getValues();
          if (goOn) {
            setcontentForm((old) => ({ ...old, ...values }));
            setShowForm('two');
          }
        }}
      >
        avançar
      </ButtonRound>
    </>
  );
}
