/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */

import React, { useRef, useEffect, useState } from 'react';
import qs from 'query-string';
import { useLazyQuery } from '@apollo/client';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import {
  ColaboradoresTabela,
  ContentGeral,
  BoxTabs,
  CustomStyleDataTable,
} from './styles';
import Header from '../layouts/header';
import Button from '~/components/button';
import { ACOMPANHAR_VENDAS } from '~/config/gql';
import useAuth from '~/config/auth_provider/contexto';
import { RequestAlertConfirm } from '~/config/alert';
import ButtonsAcaoDatatable from '~/components/buttons_acao_datatable';
import ModalHistoricoAssinatura from '~/components/modal_historico_assinatura';
import { getColumns } from './contantes';
import InputFilter from '~/components/input_filter';
import { checkDate } from '~/config/util';
import Expanded from './expanded';
import { enviarEmailRequest } from '../../modules/empresa/actions';

export default function Corretores() {
  const page = useRef(1);
  const history = useHistory();
  const { addToast } = useToasts();
  const [dateFilters, setdateFilters] = useState({
    data_inicio: '',
    filtro: '',
    data_fim: '',
  });
  const { auth, dispatch: dispatchAuth, empresa_ativa, loadingRep } = useAuth();
  const dispatch = useDispatch();
  const [filterDtFim, setFilterDtFim] = useState('');
  const [filterDtInicio, setFilterDtInicio] = useState('');
  const [filterText, setFilterText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalInfos, setModalInfos] = useState(null);

  const [getVendas, { data, loading }] = useLazyQuery(ACOMPANHAR_VENDAS, {
    context: {
      headers: {
        Authorization: auth,
      },
    },
    variables: {
      empresa_id: empresa_ativa.id,
    },
    fetchPolicy: 'no-cache',
    skip: !auth || !empresa_ativa.id || loadingRep,
  });

  useEffect(() => {
    getVendas({
      variables: {
        first: 15,
        page: page.current,
        empresa_id: empresa_ativa.id,
        ...dateFilters,
      },
    });
  }, [empresa_ativa]);

  const onChagePage = (pg) => {
    page.current = pg;
    getVendas({
      variables: {
        first: 15,
        page: page.current,
        empresa_id: empresa_ativa.id,
        ...dateFilters,
      },
    });
  };

  const EnviarEmailHandle = (row) => {
    const check = (call) => {
      if (call) {
        dispatch(enviarEmailRequest({ id: row?.pedido?.id }));
      }
    };
    dispatchAuth(
      RequestAlertConfirm(
        `Deseja realmente enviar o email e o sms da fatura para este cliente?`,
        '',
        true,
        check
      )
    );
  };

  const ActionList = (row) => {
    return (
      <ButtonsAcaoDatatable>
        {row?.metodo?.id === '2' && (
          <button
            onClick={() => {
              setModalInfos(row);
              setOpenModal(true);
            }}
            type="button"
            className="edit"
          >
            <i className="ver fas fa-ballot-check" />
          </button>
        )}
        {row?.atualizacao_pagamento[0]?.status === 'pending' &&
          row?.metodo?.id === '2' && (
            <button
              onClick={() => {
                EnviarEmailHandle(row);
              }}
              type="button"
              className="edit"
            >
              <i className="ver fas fa-envelope" />
            </button>
          )}
      </ButtonsAcaoDatatable>
    );
  };

  useEffect(() => {
    if (history.location.search?.GetQueryParams?.()) {
      let item = history.location.search?.GetQueryParams?.() || {
        data_inicio: '',
        data_fim: '',
        filtro: '',
      };
      if (!checkDate(item.data_inicio) || !checkDate(item.data_fim)) {
        item = {
          data_inicio: '',
          data_fim: '',
          filtro: '',
        };
      }
      setdateFilters({ ...item });
      setFilterDtInicio(item.data_inicio || '');
      setFilterDtFim(item.data_fim || '');
      setFilterText(item.filtro || '');
    }
  }, []);

  useEffect(() => {
    page.current = 1;
    getVendas({
      variables: {
        first: 15,
        page: page.current,
        empresa_id: empresa_ativa.id,
        ...dateFilters,
      },
    });
  }, [dateFilters]);

  const aplicarFiltro = () => {
    const check = checkDate(filterDtInicio) && checkDate(filterDtFim);

    if (!check && (filterDtInicio !== '' || filterDtFim !== '')) {
      addToast('Dr Informa, o intervalo de data não é válido.', {
        appearance: 'error',
        autoDismiss: true,
      });
      return false;
    }

    if (filterDtInicio !== '' && filterDtFim !== '') {
      history.push({
        pathname: '/acompanhamento-vendas',
        search: qs.stringify({
          data_inicio: filterDtInicio,
          filtro: filterText,
          data_fim: filterDtFim,
        }),
      });
    }

    if (filterText !== '' && filterDtInicio === '' && filterDtFim === '') {
      history.push({
        pathname: '/acompanhamento-vendas',
        search: qs.stringify({
          filtro: filterText,
        }),
      });
    }

    if (filterText === '' && filterDtInicio === '' && filterDtFim === '') {
      history.push(`/acompanhamento-vendas`);
    }

    setdateFilters({
      search: filterText,
      data_inicio: filterDtInicio,
      data_fim: filterDtFim,
    });
  };

  return (
    <ContentGeral>
      <Header title="Acompanhar Vendas" texto="" />

      <ColaboradoresTabela>
        <CustomStyleDataTable>
          <div className="control-input">
            <BoxTabs>
              <InputFilter
                flex={1}
                labelName="Data Início "
                type="date"
                className="input-control"
                // clear
                callback={(datas) => {
                  setFilterDtInicio(datas);
                }}
              />
              <InputFilter
                labelName="Data Fim "
                type="date"
                // clear
                callback={(datas) => {
                  setFilterDtFim(datas);
                }}
              />
              <InputFilter
                labelName="Pesquisar por: "
                placeholder=" Nome, CPF"
                id="buscar-usuarios"
                type="text"
                // clear
                callback={(datas) => {
                  setFilterText(datas);
                }}
              />
              <Button
                // disabled={LoadingAdesao || LoadingTotalAdesao}
                type="button"
                radius="22px"
                onClick={() => {
                  aplicarFiltro();
                }}
              >
                filtrar
              </Button>
            </BoxTabs>
          </div>

          <DataTable
            noHeader
            pagination
            noDataComponent="Nenhuma venda encontrada, tente realizar uma venda para ser listada aqui."
            columns={getColumns(ActionList)}
            paginationTotalRows={
              data?.getAcompVendas?.paginatorInfo?.total || 0
            }
            data={data?.getAcompVendas?.data || []}
            dense
            paginationServer
            expandableRows
            expandableRowsComponent={<Expanded />}
            progressPending={loading}
            onChangePage={onChagePage}
            paginationPerPage={15}
          />
        </CustomStyleDataTable>
      </ColaboradoresTabela>
      <ModalHistoricoAssinatura
        show={openModal}
        handleClose={() => {
          setOpenModal(false);
          setModalInfos(null);
        }}
        elemento={modalInfos}
      />
    </ContentGeral>
  );
}
