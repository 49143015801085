import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 54px 0 16px 0;
  width: 100%;

  .button-control {
    width: 180px;
    align-self: center;
  }

  @media (max-width: 1440px) {
  }
`;

export const CardLine = styled.div`
  display: flex;
  gap: 100px;
  flex-wrap: wrap;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const CardPrestador = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0 38px;
  border-radius: 12px;
  border: 1px solid #d9d9d9;

  flex: 1;
  padding: 24px 42px;

  span {
    margin-right: 12px;
    font-size: 14px;
  }

  div.img-control {
    width: 155px;
    height: 69px;
    display: flex;
    align-items: center;
    margin-right: 16px;

    img {
      display: block;
      max-width: 100%;
    }
  }
`;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoPagina = styled.div`
  margin-top: 32px;

  h1 {
    color: #c10740;
    font-size: 32px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
    margin: 0 16px 16px 0;
  }
`;

/** saaaaai */

export const InfoEmpresa = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr;

  span {
    strong {
      font-weight: 600;
      margin-left: 18px;
    }
  }

  input {
    width: 100%;
  }
`;

export const InfoPage = styled.div`
  flex: 1;

  h1 {
    font-size: 32px;
    color: #0439d6;
    font-weight: bold;

    margin-bottom: 8px;
  }
`;

export const SelecionarEmpresa = styled.div`
  display: flex;
  align-items: center;

  strong {
    white-space: nowrap;
    margin-right: 20px;
  }
`;
