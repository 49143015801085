const { cpf, cnpj } = require('cpf-cnpj-validator');

export const validaCPF = (document) => cpf.isValid(document);

export const validaCNPJ = (document) => cnpj.isValid(document);

export const formatarCNPJ = (document) => cnpj.format(document);

export const formatarCPF = (document) => cpf.format(document);

export const TipoCheck = (procedimento) => {
  switch (procedimento) {
    case '10101012':
      return false;

    case '01012019':
      return false;

    case '01022019':
      return false;

    default:
      return true;
  }
};
