import React from 'react';
import { Container, Content, SideLeft, SideRight } from './styles';
import bg from '../../assets/img/backgrounds/homem-sentado.png';
import useAuth from '~/config/auth_provider/contexto';
import IfComponent from '~/components/if_component';

const BemVindo = () => {
  const { empresa_ativa } = useAuth();
  return (
    <Container>
      <Content>
        <IfComponent hide={!empresa_ativa?.id}>
          <Corretoras />
        </IfComponent>
        <IfComponent hide={empresa_ativa?.id}>
          <Consultores />
        </IfComponent>
        <SideRight>
          <figure>
            <img src={bg} alt="Background animaçao" />
          </figure>
        </SideRight>
      </Content>
    </Container>
  );
};

const Corretoras = () => (
  <SideLeft>
    <h1>
      Seja <br />
      <b> bem-vindo(a) à Dr.Hoje!</b>
    </h1>

    <h2>
      As nossas soluções de saúde são modernas, inovadoras e cabem no bolso do
      brasileiro.
      <b> Agora você faz parte dessa história!</b>
    </h2>
    <p>
      <span>
        <i className="far fa-building" />
      </span>
      Adicionar vendedores, podendo organizar por equipes.
    </p>

    <p>
      <span>
        <i className="far fa-file-download" />
      </span>
      Obter o seu link de vendas por produto e de toda equipe de forma
      individualizada e personalizada
    </p>

    <p>
      <span>
        <i className="far fa-file-upload" />
      </span>
      Acompanhar as vendas realizas por você e por cada membro da equipe
    </p>

    <p>
      <span>
        <i className="far fa-file-chart-line" />
      </span>
      Emitir relatórios para conferências{' '}
    </p>
  </SideLeft>
);
const Consultores = () => (
  <SideLeft>
    <h1>
      Corretor, seja <br />
      <b> bem-vindo(a) à Dr.Hoje!</b>
    </h1>

    <h2>
      As nossas soluções de saúde são modernas, inovadoras e cabem no bolso do
      brasileiro.
      <b> Agora você faz parte dessa história!</b>
    </h2>
    <p>
      <span>
        <i className="far fa-file-download" />
      </span>
      Obter o seu link de vendas por produto de forma individualizada e
      personalizada
    </p>

    <p>
      <span>
        <i className="far fa-file-upload" />
      </span>
      Acompanhar as vendas realizas por você
    </p>

    <p>
      <span>
        <i className="far fa-file-chart-line" />
      </span>
      Emitir relatórios para conferências{' '}
    </p>
  </SideLeft>
);
export default BemVindo;
