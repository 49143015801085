import styled from 'styled-components';
import { sidebarSelectedText, dark } from '~/styles/colors';

export const Container = styled.div`
  margin: 24px 32px 82px 32px;

  @media (max-width: 700px) {
    padding: 0;
    margin: 24px 16px 32px 16px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-bottom: 0.1rem solid #dedede;
    margin-bottom: 12px;
    padding-bottom: 4px;

    width: 100%;

    h3 {
      font-size: 16px;
      font-weight: bold;
    }

    span {
      font-size: 14px;
      color: #ee0731;
      cursor: pointer;
      margin-bottom: 0;
    }

    span.atualizar {
      margin-left: 25px;
      color: ${sidebarSelectedText};
    }

    span.atualizar {
      color: ${sidebarSelectedText};
    }
  }

  div.contentForm {
    display: flex;
    align-items: baseline;

    @media (max-width: 320px) {
      ul:nth-child(2n - 1) {
        margin-right: 8px;
      }
    }

    ul {
      margin-right: 32px;
      color: ${dark};

      @media (max-width: 320px) {
        margin: 0;
      }

      li {
        font-size: 16px;
        margin-top: 8px;
        word-wrap: normal;
      }
    }

    ul:nth-child(2n - 1) {
      color: #00000093;
    }

    table {
      width: 100%;
      tr {
        width: 100%;
        line-height: 2;

        @media (max-width: 700px) {
          display: flex;
          flex-direction: column;

          td:first-child {
            font-weight: 600;
            margin-top: 8px;
            color: #5d5d5d;
          }
        }

        td {
          width: 50%;

          @media (max-width: 700px) {
            width: 100% !important;
            line-height: 1.4;
          }
        }
      }
    }
  }
`;
