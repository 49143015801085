import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import useAuth from './auth_provider/contexto';
import { BUSCAR_CEP } from './gql';
import { urls } from '~/config/api_apollo';

export default function useCEP(zip_code, client = urls[process.env.NODE_ENV]) {
  const [cep, setCep] = useState('');
  const [call, setcall] = useState(BUSCAR_CEP);
  const { auth } = useAuth();

  useEffect(() => {
    if (zip_code.replace(/[^\d]+/g, '').length === 8) {
      setCep(zip_code.replace(/[^\d]+/g, ''));
    }
  }, [zip_code]);

  const { loading, data } = useQuery(call, {
    variables: {
      nr_cep: cep,
    },
    context: {
      headers: {
        Authorization: auth,
      },
      uri: client,
    },
    fetchPolicy: 'no-cache',
    skip: cep === '',
  });

  return {
    loading,
    data,
    setcall,
  };
}
