/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */

import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import GenExcel from '~/config/gen_excel';
import { BUSCAR_TODOS_CORRETORES } from '~/config/gql';
import csvPng from '~/assets/img/csv-file.png';
import { ExportButton } from '../corretores/styles';
import { formatarCPF, findCPF } from '~/config/utils';

export default function ExportarCorretoresExcel({
  auth,
  colaboradorFilters,
  first = 30,
  hide = false,
}) {
  const dateNow = new Date();

  const [downloading, setDownloading] = useState(false);
  const [porcentagem, setPorcentagem] = useState('aguarde...');
  const [excelItem, setexcelItem] = useState([]);
  const page = useRef(1);
  const [getItenExcel, { data: dataExcel }] = useLazyQuery(
    BUSCAR_TODOS_CORRETORES,
    {
      context: {
        headers: {
          Authorization: `${auth}`,
        },
      },
      fetchPolicy: 'no-cache',
      skip: !auth,
    }
  );

  const mapearItemDownloaded = (elementos) => {
    const elementosExcel = [];
    // eslint-disable-next-line no-unused-expressions
    elementos?.map((linha) => {
      elementosExcel.push({
        equipe_id: linha?.equipe_vendas?.id,
        consultor_id: linha?.id,
        consultor: linha?.user?.nome_completo,
        email_consultor: linha?.email,
        celular_consultor: linha?.contato_cargo,
        documento_consultor: formatarCPF(
          findCPF(linha?.user?.documentos || [])?.[0]?.nr_documento || ''
        ),
        equipe: linha?.equipe_vendas?.ds_equipe,
        gestor: linha?.equipe_vendas?.nm_gestor,
        documento_gestor: linha?.equipe_vendas?.documento,
        celular_gestor: linha?.equipe_vendas?.celular,
        email_gestor: linha?.equipe_vendas?.email,
      });
      return linha;
    });
    return elementosExcel;
  };

  useEffect(() => {
    if (dataExcel?.getTodosCorretores?.paginatorInfo) {
      const { paginatorInfo, data: contnt } = dataExcel?.getTodosCorretores;
      const { hasMorePages, total } = paginatorInfo;

      GenExcel({
        hasMorePages,
        total,
        excelItem,
        objectExport: {
          csvData: excelItem,
          fileName: `relatorio_consultores_equipe ${dateNow}`,
        },
        setDownloading,
        setPorcentagem,
        pageExcel: page,
        setexcelItem,
        itemMapeadoExcel: mapearItemDownloaded(contnt),
        getItenExcel,
        variables: {
          first,
          ...colaboradorFilters,
        },
        skip: !auth,
        authorization: auth,
        seeMessage: false,
      });
    }
  }, [dataExcel]);

  if (hide) {
    return '';
  }

  return (
    <ExportButton>
      {downloading && (
        <button type="button" className="btn-exportacao ml-auto">
          Baixando dados... {porcentagem}
        </button>
      )}

      {!downloading && (
        <button
          className="btn"
          type="button"
          onClick={() => {
            setDownloading(true);
            getItenExcel({
              variables: {
                ...colaboradorFilters,
                first,
                page: page.current,
              },
              skip: !auth,
            });
          }}
        >
          <img src={csvPng} alt="" />
        </button>
      )}
    </ExportButton>
  );
}
