import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import useAuth from '~/config/auth_provider/contexto';
import Button from '~/components/button';

import { formatarCNPJ } from '~/config/utils';
import { AtualizarEmpresaAtiva } from '~/modules/auth/actions';

import {
  Header,
  MenuUser,
  InfoUser,
  LinkDadosConta,
  Logout,
  SelectEmpresaDropdown,
  ListEmpresas,
  ButtonControl,
} from './styles';

const Navbar = () => {
  const {
    dataRep,
    dispatch,
    auth_object,
    empresa_ativa,
    logout,
    dataCargoRepre,
    routes,
  } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const [empresaSelecionada, setEmpresaSelecionada] = useState('');

  const selecionarEmpresaAtiva = (empresa) => {
    dispatch(AtualizarEmpresaAtiva(JSON.parse(empresa)));
  };

  const RenderRoutes = ({ routes_default }) =>
    routes_default?.map((item) => (
      <li onClick={() => setShowMenu(!showMenu)}>
        <NavLink activeClassName="is-active" to={item.route}>
          <span>{item.name}</span>
        </NavLink>
      </li>
    ));

  return (
    <Header className="header" showMenu={showMenu}>
      <div className="header-content">
        <div className="icon-menu-hamburguer">
          <input
            className="menu-btn"
            type="checkbox"
            id="menu-btn"
            checked={showMenu}
          />
          <label
            className="menu-icon"
            htmlFor="menu-btn"
            onClick={() => setShowMenu(!showMenu)}
          >
            <span className="navicon" />
          </label>
        </div>
        <Dropdown className="dropdown-select-empresa">
          <Dropdown.Toggle id="dropdown-basic" className="select-dropdown">
            <SelectEmpresaDropdown>
              CNPJ: {formatarCNPJ(empresa_ativa?.documento?.nr_documento)}{' '}
              <i className="fas fa-caret-down" />
            </SelectEmpresaDropdown>
          </Dropdown.Toggle>
          <Dropdown.Menu className="menu-empresa">
            <form action="">
              <ListEmpresas>
                {dataRep?.map((item) => (
                  <div>
                    <label htmlFor="sinpol">
                      <input
                        type="radio"
                        id="sinpol"
                        name="empresa"
                        onChange={() =>
                          setEmpresaSelecionada(JSON.stringify(item?.empresa))
                        }
                        defaultChecked={
                          item?.empresa?.id === auth_object.empresa_ativa?.id
                        }
                      />
                      {item?.empresa?.nm_fantasia}
                    </label>
                  </div>
                ))}
              </ListEmpresas>

              <Dropdown.Item href="#/action-3" className="dropdown-selecionar">
                <ButtonControl>
                  <Button
                    width="100%"
                    bgColor="darkBlue"
                    radius="24px"
                    onClick={() => selecionarEmpresaAtiva(empresaSelecionada)}
                  >
                    Selecionar
                  </Button>
                </ButtonControl>
              </Dropdown.Item>
            </form>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="dropdown-content-user">
          <Dropdown.Toggle id="dropdown-basic" className="dropdown-user">
            <figure>
              <img src={dataCargoRepre?.[0]?.user?.foto} alt="" />
            </figure>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">
              <MenuUser>
                <figure>
                  <img src={dataCargoRepre?.[0]?.user?.foto} alt="" />
                </figure>
                <InfoUser>
                  <strong>{dataCargoRepre?.[0]?.user?.nome_completo}</strong>
                  <span>{dataCargoRepre?.[0]?.cargo?.cargo}</span>
                </InfoUser>
              </MenuUser>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-2">
              <LinkDadosConta>
                <Link to="/configuracoes">
                  {' '}
                  <i className="far fa-cogs" /> Dados da conta
                </Link>
              </LinkDadosConta>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-3">
              <Logout onClick={() => logout()}>
                <Link to="/#">Sair</Link>
              </Logout>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <ul className="menu">
        <li>
          <RenderRoutes routes_default={routes.routes} />

          <NavDropdown
            title="Vendas Novas"
            id="nav-dropdown"
            className="drop-down-wrapper-menu"
          >
            <NavLink
              to="/links-venda"
              activeClassName="is-active"
              className="sub-item"
              onClick={() => setShowMenu(false)}
            >
              Individual
            </NavLink>
            {/* <NavLink
                to="/venda-pme"
                activeClassName="is-active"
                className="sub-item"
                onClick={() => setShowMenu(false)}
              >
                PME 2 a 99 vidas{' '}
              </NavLink>
              <NavLink
                to="/venda-empresarial"
                activeClassName="is-active"
                className="sub-item"
                onClick={() => setShowMenu(false)}
              >
                Empresarial a partir de 100 vidas
              </NavLink> */}
            <a
              href="https://drhoje.planium.io/web/login/entrar?target=venda"
              className="sub-item"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              rel="noopener"
            >
              <span>PME a partir de 2 vidas</span>
            </a>
          </NavDropdown>
        </li>
        <li onClick={() => setShowMenu(!showMenu)}>
          <NavLink activeClassName="is-active" to="/#">
            <span>Sair</span>
          </NavLink>
        </li>
      </ul>
    </Header>
  );
};

export default Navbar;
