import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  AUTH_PROVIDER_CHANGE,
  ATUALIZAR_EMPRESA_ATIVA,
  ATUALIZAR_CONTRATO_ATIVO,
  SOLICITAR_SENHA_REQUEST,
  SOLICITAR_SENHA_SUCCESS,
  SOLICITAR_SENHA_FAILED,
  ALTERAR_SENHA_SUCCESS,
  CADASTRO_CORRETOR_REQUEST,
  CADASTRO_CORRETOR_SUCCESS,
  CADASTRO_CORRETOR_FAILED,
} from '~/config/constantes';

/**
 * LOGIN
 */
export const ProviderAuthChange = (payload) => ({
  type: AUTH_PROVIDER_CHANGE,
  payload,
});
export const AtualizarEmpresaAtiva = (payload) => ({
  type: ATUALIZAR_EMPRESA_ATIVA,
  payload,
});

export const AtualizarContratoAtivo = (payload) => ({
  type: ATUALIZAR_CONTRATO_ATIVO,
  payload,
});

export const LoginRequest = (payload) => ({
  type: LOGIN_REQUEST,
  payload,
});

export const LoginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});
export const LoginFailed = (payload) => ({
  type: LOGIN_FAILED,
  payload,
});

export const SolicitarSenhaRequest = (payload) => ({
  type: SOLICITAR_SENHA_REQUEST,
  payload,
});

export const SolicitarSenhaSuccess = (payload) => ({
  type: SOLICITAR_SENHA_SUCCESS,
  payload,
});
export const SolicitarSenhaFailed = (payload) => ({
  type: SOLICITAR_SENHA_FAILED,
  payload,
});

export const AlterarSenhaUpdateToken = (payload) => ({
  type: ALTERAR_SENHA_SUCCESS,
  payload,
});

export const CadastroCorretorRequest = (payload) => ({
  type: CADASTRO_CORRETOR_REQUEST,
  payload,
});

export const CadastroCorretorSuccess = (payload) => ({
  type: CADASTRO_CORRETOR_SUCCESS,
  payload,
});
export const CadastroCorretorFailed = (payload) => ({
  type: CADASTRO_CORRETOR_FAILED,
  payload,
});

export const InvalidateToken = () => {};
