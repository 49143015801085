import styled from 'styled-components';
import { darkBlue, sidebarSelectedText, textColor } from '../../styles/colors';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const HeaderEmpresa = styled.div`
  margin-bottom: 56px;
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    width: 100%;
  }

  @media (max-width: 680px) {
    margin-top: 90px;
  }

  div {
    flex-wrap: wrap;
  }

  h1 {
    color: ${darkBlue};
    font-weight: bold;
    margin-bottom: 16px;
  }
  button {
    background-color: #e5e5e5;
    color: #707070;
    width: 138px;
    margin-left: 32px;
  }
`;

export const ContentGeral = styled.div`
  width: 100%;
  margin-left: 62px;
  margin-right: 48px;
  width: 100%;

  @media (max-width: 680px) {
    margin-left: 8px;
    margin-right: 8px;
  }

  main {
    width: 100%;
    display: flex;

    aside {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 1440px) {
      flex-direction: row;
      align-items: flex-start;

      aside {
        flex-direction: column-reverse;
        margin-bottom: 16px;
      }
    }

    @media (max-width: 1074px) {
      flex-direction: column;
    }

    @media (max-width: 700px) {
      aside {
        width: 100%;
      }
    }
  }
`;

export const CardEmpresa = styled.label`
  display: flex;

  width: 100%;
  max-width: 344px;
  min-width: 344px;
  height: 221px;

  box-shadow: 1px 1px 4px #00000055;
  border-radius: 8px;

  @media (max-width: 700px) {
    min-width: auto;
  }

  div.sideInput {
    border-right: 1px solid #0000001f;
    padding: 14px;
  }

  div.sideLogoEmpresa {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    padding: 37px 26px;

    div.img {
      max-width: 212px;
      width: 100%;
      margin-bottom: 16px;
    }

    span {
      font-size: 14px;
      text-align: center;
    }

    span.cnpj {
      font-size: 12px;
      color: #707070;
      text-align: center;
    }
  }
`;

export const ContainerEmpresa = styled.div`
  box-shadow: 0px 1px 4px #00000029;
  background: #fff;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 16px;

  @media (max-width: 1074px) {
    margin-left: 0;
  }
`;

export const HeaderDados = styled.div`
  border-bottom: 1px solid #0000001f;
  padding: 30px 0 16px 3%;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  input {
    display: none;
  }

  input[type='radio']:checked + label {
    border: 1px solid ${sidebarSelectedText};
    i {
      color: ${sidebarSelectedText};
    }
  }

  label {
    margin-right: 24px;
    padding: 10px 18px;
    background: transparent;
    border-radius: 24px;

    i {
      margin-right: 8px;
    }
  }

  div.group-input-row {
    display: flex;
    width: 100%;
    margin-right: 40px;

    @media (max-width: 1500px) {
      flex-wrap: wrap;
    }
  }

  div.group-input {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  div.double-input {
    display: flex;
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
  }

  div.espaco {
    width: 30px;
  }

  div.group-input-hash-integracao {
    display: flow-root;
  }

  .group-item-hash-integracao-label {
    display: table-cell !important;
  }

  .group-item-hash-integracao-input {
    width: 100%;
    display: table-cell !important;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: inherit;

    button {
      margin-bottom: 8px;
    }
  }
`;

export const ColaboradoresTabela = styled.div`
  div.rdt_TableHeader {
    display: none;
  }

  .popover-body {
    box-shadow: 0px 3px 6px #00000029;
    padding: 18px;
  }

  .popover {
    border: 0;
  }

  .funcionalidade-colaboradores {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;

    .import-group {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;

      @media (max-width: 1200px) {
        flex-direction: column;
        margin: 24px 0;
        align-items: flex-start;
      }
    }

    @media (max-width: 1200px) {
      margin: 24px 0;
      flex-direction: column;
    }
  }

  .gaMRwo {
    margin-right: auto;
  }
`;

export const CustomStyleDataTable = styled.div`
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 8px;
  padding: 16px;
  background: #fff;
  margin-bottom: 150px;

  .control-input {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
  }

  button.button-nova-equipe {
    background-size: 300% 100%;

    background-image: linear-gradient(
      to right,
      #7e0754,
      #ed0732,
      #7e0754,
      #ed0732
    );

    border-radius: 50px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-position: 100% -100%;

      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
  }
`;

export const BoxTabs = styled.div`
  position: relative;
  display: flex;

  border-top-left-radius: 10px;
  width: 100%;

  border-top-right-radius: 10px;
`;

export const BoxInputs = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  gap: 32px;
  padding: 8px 0;

  span {
    color: ${textColor};
  }
`;
