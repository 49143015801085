import {
  CAD_USUARIO_REQUEST,
  CAD_USUARIO_SUCCESS,
  CAD_USUARIO_FAILED,
  UPDATE_USUARIO_REQUEST,
  UPDATE_USUARIO_SUCCESS,
  UPDATE_USUARIO_FAILED,
  CAD_USUARIO_CARGO_REQUEST,
  CAD_USUARIO_CARGO_SUCCESS,
  CAD_USUARIO_CARGO_FAILED,
  UPDATE_USUARIO_DADOS_REQUEST,
  UPDATE_USUARIO_DADOS_SUCCESS,
  UPDATE_USUARIO_DADOS_FAILED,
  UPDATE_USUARIO_FOTO_REQUEST,
  UPDATE_USUARIO_FOTO_SUCCESS,
  UPDATE_USUARIO_FOTO_FAILED,
  UPDATE_USUARIO_SENHA_REQUEST,
  UPDATE_USUARIO_SENHA_SUCCESS,
  UPDATE_USUARIO_SENHA_FAILED,
} from '~/config/constantes';

export const CadUsuarioRequest = (payload) => ({
  type: CAD_USUARIO_REQUEST,
  payload,
});
export const CadUsuarioSucess = (payload) => ({
  type: CAD_USUARIO_SUCCESS,
  payload,
});
export const CadUsuarioFailed = (payload) => ({
  type: CAD_USUARIO_FAILED,
  payload,
});

export const UpdateUsuarioRequest = (payload) => ({
  type: UPDATE_USUARIO_REQUEST,
  payload,
});
export const UpdateUsuarioSucess = (payload) => ({
  type: UPDATE_USUARIO_SUCCESS,
  payload,
});
export const UpdateUsuarioFailed = (payload) => ({
  type: UPDATE_USUARIO_FAILED,
  payload,
});

export const CadUsuarioCargoRequest = (payload) => ({
  type: CAD_USUARIO_CARGO_REQUEST,
  payload,
});
export const CadUsuarioCargoSucess = (payload) => ({
  type: CAD_USUARIO_CARGO_SUCCESS,
  payload,
});
export const CadUsuarioCargoFailed = (payload) => ({
  type: CAD_USUARIO_CARGO_FAILED,
  payload,
});

export const UpdateUsuarioDadosRequest = (payload) => ({
  type: UPDATE_USUARIO_DADOS_REQUEST,
  payload,
});
export const UpdateUsuarioDadosSucess = (payload) => ({
  type: UPDATE_USUARIO_DADOS_SUCCESS,
  payload,
});
export const UpdateUsuarioDadosFailed = (payload) => ({
  type: UPDATE_USUARIO_DADOS_FAILED,
  payload,
});

export const UpdateUsuarioFotoRequest = (payload) => ({
  type: UPDATE_USUARIO_FOTO_REQUEST,
  payload,
});
export const UpdateUsuarioFotoSucess = (payload) => ({
  type: UPDATE_USUARIO_FOTO_SUCCESS,
  payload,
});
export const UpdateUsuarioFotoFailed = (payload) => ({
  type: UPDATE_USUARIO_FOTO_FAILED,
  payload,
});

export const UpdateUsuarioSenhaRequest = (payload) => ({
  type: UPDATE_USUARIO_SENHA_REQUEST,
  payload,
});
export const UpdateUsuarioSenhaSuccess = (payload) => ({
  type: UPDATE_USUARIO_SENHA_SUCCESS,
  payload,
});
export const UpdateUsuarioSenhaFailed = (payload) => ({
  type: UPDATE_USUARIO_SENHA_FAILED,
  payload,
});
