/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import InputRound from '../../../../components/input_round';
import CardDadosEmpresa from './card-dados-empresa';
import { CADASTRAR_CORRETORA } from '~/config/gql';
import { Container, ContentFormulario, SubContentInlineEnd } from './styles';
import Button from '~/components/button';
import CustomInputMask from '~/components/mask_input';
import useAuth from '~/config/auth_provider/contexto';
import useCEP from '~/config/useCEP';
import { RequestSuccess } from '~/config/alert';
import { checkIsCNPJ } from '~/config/utils';

const DadosEmpresa = ({ setNovaEmpresa }) => {
  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: {},
  });
  const [cep, setCep] = useState('');
  const { data: dataCEP } = useCEP(cep);

  const buscarCEP = (e) => setCep(e.target.value.replace(/[^\d]+/g, ''));

  const { auth, refetchRep, dispatch } = useAuth();
  const [cadastrarEmpresa, { loading }] = useMutation(CADASTRAR_CORRETORA, {
    context: {
      headers: {
        Authorization: auth,
      },
    },
  });

  const onSubmit = (payload) => {
    cadastrarEmpresa({
      variables: { data: { ...payload } },
    })
      .then(() => {
        setNovaEmpresa?.(false);
        refetchRep?.();
        dispatch(RequestSuccess('Corretora cadastrada com sucesso!'));
      })
      .catch((e) => {
        console.log(e);
      });
  };
  console.log(errors);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <div className="cardsLeft">
          {/* DOCUMENTOS DA EMPRESA */}
          <CardDadosEmpresa title="Documentos">
            <ContentFormulario>
              <InputRound label="CNPJ">
                <Controller
                  as={<CustomInputMask />}
                  onChange={([e]) => ({ value: e })}
                  rules={{
                    validate: {
                      inputCNPJRequired: checkIsCNPJ,
                    },
                  }}
                  name="cnpj"
                  mask="99.999.999/9999-99"
                  maskChar="_"
                  placeholder="__.___.___/____-__"
                  className="form-control"
                  control={control}
                />
              </InputRound>
              <InputRound label="Inscrição Estadual">
                <input ref={register()} name="inscricao_estadual" type="text" />
              </InputRound>
              <InputRound label="Inscrição INSS">
                <input ref={register()} name="inscricao_inss" type="text" />
              </InputRound>
            </ContentFormulario>
          </CardDadosEmpresa>
          {/* DADOS DA EMPRESA */}
          <CardDadosEmpresa title="Empresa">
            <ContentFormulario>
              <InputRound label="Nome Fantasia">
                <input
                  ref={register({ required: true })}
                  name="nm_fantasia"
                  type="text"
                />
              </InputRound>
              <InputRound label="Razão Social">
                <input
                  ref={register({ required: true })}
                  name="razao_social"
                  type="text"
                />
              </InputRound>
            </ContentFormulario>
          </CardDadosEmpresa>
          {/* CONTATOS EMPRESA */}
          <CardDadosEmpresa title="Contato">
            <ContentFormulario>
              <InputRound label="Telefone">
                <CustomInputMask
                  name="contato_administrativo"
                  mask="(99) 99999-9999"
                  register={register({ required: true })}
                  maskChar="_"
                  placeholder="(__) _____-____"
                />
              </InputRound>
              <InputRound label="E-mail">
                <input
                  ref={register({ required: true })}
                  name="email_empresa"
                  type="text"
                />
              </InputRound>
            </ContentFormulario>
          </CardDadosEmpresa>
        </div>

        <div className="cardsRight">
          {/* ENDERECO EMPRESA  */}
          <CardDadosEmpresa title="Endereço">
            <ContentFormulario>
              <InputRound label="CEP">
                <CustomInputMask
                  name="cep"
                  mask="99999-999"
                  register={register({ required: true })}
                  maskChar="_"
                  onChange={buscarCEP}
                  placeholder="ex. 99999-999"
                />
              </InputRound>
              <InputRound label="Endereço">
                <input
                  type="text"
                  name="endereco"
                  value={dataCEP?.buscarcep?.te_logradouro}
                  ref={register({ required: false })}
                  placeholder="endereco"
                />
              </InputRound>
              <InputRound label="Número">
                <input
                  type="text"
                  placeholder="Informe o número"
                  name="numero"
                  ref={register({ required: true })}
                />
              </InputRound>
              <InputRound label="Bairro">
                <input
                  type="text"
                  placeholder="-"
                  name="bairro"
                  defaultValue={dataCEP?.buscarcep?.te_bairro}
                  ref={register({ required: true })}
                />
              </InputRound>
              <InputRound label="Cidade">
                <input
                  type="text"
                  placeholder="-"
                  name="cidade"
                  readOnly
                  value={dataCEP?.buscarcep?.cidade?.nm_cidade}
                  ref={register({ required: true })}
                />
                <input
                  type="hidden"
                  placeholder="-"
                  name="cidade_id"
                  readOnly
                  value={dataCEP?.buscarcep?.cidade?.id}
                  ref={register({ required: true })}
                />
              </InputRound>
              <InputRound label="Estado">
                <input
                  type="text"
                  placeholder="Estado"
                  name="estado"
                  readOnly
                  value={dataCEP?.buscarcep?.cidade?.sg_cidade}
                  ref={register({ required: false })}
                />
              </InputRound>
              <InputRound label="Complemento">
                <input
                  type="text"
                  placeholder="Ponto de referência/Apartamento/Bloco"
                  name="complemento"
                  ref={register({ required: false })}
                />
              </InputRound>
              <SubContentInlineEnd>
                <Button
                  disabled={loading}
                  bgColor="red"
                  radius="24px"
                  type="submit"
                >
                  cadastrar
                </Button>
              </SubContentInlineEnd>
            </ContentFormulario>
          </CardDadosEmpresa>
        </div>
      </Container>
    </form>
  );
};

export default DadosEmpresa;
