import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Controller } from 'react-hook-form';
import InputRound from '~/components/input_round';
import FormSection from '../../form_section';
import CustomInputMask from '../../mask_input';
import { checkIsCPF, checkIsCelular } from '~/config/util';

export default function RenderDependentes({
  register,
  index,
  errors,
  control,
}) {
  const [dependentes, setDependentes] = useState([]);
  const [updateDependentes, setupdateDependentes] = useState([]);
  const removeDependente = (key) => {
    dependentes.splice(key, 1);
    setupdateDependentes(dependentes);
  };

  useEffect(() => {
    if (updateDependentes.length > 0) {
      setDependentes(updateDependentes);
      setupdateDependentes([]);
    }
  }, [updateDependentes]);

  return (
    <FormSection
      nome="Dependentes"
      arrayBtnOn
      arrayBtnArray={[
        {
          className: 'form-controll',
          btnAction: () => {
            setDependentes((old) => [
              ...old,
              {
                position: index,
                key: uuidv4({
                  node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
                  clockseq: 0x1234,
                  msecs: new Date().getTime(),
                  nsecs: 5678,
                }),
              },
            ]);
          },
          show: true,
          buttonName: 'Adicionar dependente',
        },
      ]}
    >
      {dependentes.map((item, keyDepe) => (
        <FormSection
          key={item.key}
          nome={`Dependente #${keyDepe + 1}`}
          arrayBtnOn
          arrayBtnArray={[
            {
              className: 'form-controll',
              btnAction: () => removeDependente(keyDepe),
              show: true,
              buttonName: 'Remover',
            },
          ]}
        >
          <div className="double-input">
            <InputRound
              label="Nome Completo"
              error={
                errors?.beneficiarios?.[index]?.dependentes?.[keyDepe]
                  ?.nome_completo || false
              }
              message="Você deve informar o nome completo."
            >
              <input
                type="text"
                name={`beneficiarios[${index}].dependentes[${keyDepe}].nome_completo`}
                ref={register({ required: true })}
              />
            </InputRound>
            <div className="espaco" />
            <InputRound
              label="Data de nascimento"
              error={
                errors?.beneficiarios?.[index]?.dependentes?.[keyDepe]
                  ?.dt_nascimento || false
              }
              message="Você deve informar o nascimento."
            >
              <input
                type="date"
                name={`beneficiarios[${index}].dependentes[${keyDepe}].dt_nascimento`}
                ref={register({ required: true })}
              />
            </InputRound>
            <div className="espaco" />
            <InputRound
              label="CPF"
              error={
                errors?.beneficiarios?.[index]?.dependentes?.[keyDepe]
                  ?.documento || false
              }
              message="Você deve informar o CPF."
            >
              <Controller
                as={<CustomInputMask />}
                onChange={([e]) => ({ value: e })}
                rules={{
                  validate: {
                    inputTelRequired: checkIsCPF,
                  },
                  required: true,
                }}
                name={`beneficiarios[${index}].dependentes[${keyDepe}].documento`}
                mask="999.999.999-99"
                maskChar="_"
                control={control}
              />
            </InputRound>
          </div>
          <div className="double-input">
            <InputRound
              label="E-mail"
              error={
                errors?.beneficiarios?.[index]?.dependentes?.[keyDepe]?.email ||
                false
              }
              message="Você deve informar o e-mail."
            >
              <input
                type="email"
                name={`beneficiarios[${index}].dependentes[${keyDepe}].email`}
                ref={register({ required: true })}
              />
            </InputRound>
            <div className="espaco" />
            <InputRound
              label="Celular"
              error={
                errors?.beneficiarios?.[index]?.dependentes?.[keyDepe]
                  ?.celular || false
              }
              message="Você deve informar o celular."
            >
              <Controller
                as={<CustomInputMask />}
                onChange={([e]) => ({ value: e })}
                rules={{
                  validate: {
                    inputTelRequired: checkIsCelular,
                  },
                  required: true,
                }}
                name={`beneficiarios[${index}].dependentes[${keyDepe}].celular`}
                mask="(99) 9 9999-9999"
                maskChar="_"
                control={control}
              />
            </InputRound>
            <div className="espaco" />
            <InputRound
              label="Estado"
              error={
                errors?.beneficiarios?.[index]?.dependentes?.[keyDepe]
                  ?.estado || false
              }
              message="Você deve informar o estado."
            >
              <input
                type="text"
                name={`beneficiarios[${index}].dependentes[${keyDepe}].estado`}
                ref={register({ required: true, maxLength: 2, minLength: 2 })}
              />
            </InputRound>
            <div className="espaco" />
            <InputRound
              label="Grau de parentesco"
              error={
                errors?.beneficiarios?.[index]?.dependentes?.[keyDepe]
                  ?.parentesco || false
              }
              message="Você deve informar o parentesco."
            >
              <input
                type="text"
                name={`beneficiarios[${index}].dependentes[${keyDepe}].parentesco`}
                ref={register({ required: true, maxLength: 50 })}
              />
            </InputRound>
          </div>
        </FormSection>
      ))}
    </FormSection>
  );
}
