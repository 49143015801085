import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

function statusColor(status) {
  switch (status) {
    case 'Ativa':
      return '#75BC1E93';
    case 'Suspensa':
      return '#ffc10793';
    case 'Canceled':
      return '#EE073193';
    default:
      return 'black';
  }
}

export const ModalCard = styled(Modal)`
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: ${(props) => props.tamanhoCard};
      margin: 1.75rem auto;
    }
  }
`;

export const Container = styled.div``;

export const ContentModalCliente = styled.div``;

export const AssinaturasContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 430px;
  padding-right: 8px;

  &::-webkit-scrollbar-thumb {
    background: #222;
    border-left: 4px solid #5e5e5e;
    border-right: 4px solid #5e5e5e;
    border-radius: 24px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 24px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }
`;

export const Assinatura = styled.div`
  flex: 1;
`;

export const AssinaturaBody = styled.div`
  table {
    tr {
      height: 28px;
    }
  }
`;

export const DivHistory = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const DivTable = styled.div`
  flex: 13;

  tr {
    td {
      padding: 10px 0px;
      font-size: 14px;
    }
  }
`;

export const Table = styled.table`
  margin-top: 10px;
  align-items: center;
  text-align: center;
  th {
    color: white;
  }
`;

export const TableHeader = styled.tr`
  background: linear-gradient(
    90deg,
    rgba(193, 7, 65, 1) 0%,
    rgba(194, 4, 63, 1) 47%,
    rgba(224, 48, 103, 1) 100%
  );
  th {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
  }
`;

export const ButtonsGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .button-fechar {
    background: transparent;
    color: #008bff;
    border: 1px solid #008bff;
    border-radius: 24px;
    font-weight: 600;
  }
`;

export const Number = styled.div`
  height: 25px;
  width: 25px;
  margin-top: 15px;
  border-radius: 15px;
  background-color: #c10741;
  text-align: center;
  margin-right: 10px;
  span {
    color: white;
    font-weight: bold;
  }
`;

export const DivNumber = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DivCircularNumber = styled.div`
  flex: 4;
`;

export const DivLine = styled.div`
  flex: 8;
  margin-right: 10px;
`;

export const Acoes = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  i {
    color: #c10741;
    cursor: pointer;
  }
`;

export const AssinaturaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d4d4d4;
  margin: 16px 0 8px 0;
  padding: 6px 0;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
  }

  span {
    text-transform: uppercase;
    background-color: ${(props) => statusColor(props.status)};
    color: white;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 10px;
    font-weight: bold;
  }
`;
