import styled from 'styled-components';
import { divider, textColor, link, red } from '../../styles/colors';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${divider};
  margin-bottom: 16px;

  h6 {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: normal;
    color: ${textColor};
    margin-bottom: 8px;

    .info {
      color: #0000005e;
      margin-left: 8px;
    }
    .alerta {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: ${red};
      margin-left: 8px;
    }

    .link {
      font-size: 14px;
      font-weight: 300;
      cursor: pointer;
      color: ${link};
    }
    .remover {
      color: ${red};
      margin-left: 12px;
    }
    .links {
      margin-left: auto;
      display: flex;
    }
  }
`;
