import React from 'react';
// import logoImg from '@assets/img/logo.svg';
import * as S from './styles';
import bgWrapper from '~/assets/img/backgrounds/casal-comfundo.png';

export default function AuthContainer({ children }) {
  return (
    <S.Container>
      <S.ContentCentral>
        <S.Wrapper>
          <figure>
            <img src={bgWrapper} alt="Casal com fundo" />
          </figure>
        </S.Wrapper>
        <S.Content>
          <div>
            <h2>
              Seja um corretor Dr.hoje <br /> e <b>aumente a sua renda!</b>
            </h2>
            <p>
              As nossas soluções de saúde são modernas, inovadoras e cabem no
              bolso do brasileiro.
            </p>

            <p className="ballon">
              Você pode se cadastrar e se vincular a uma corretora (PJ) já
              existente ou cadastrar seu CNPJ e montar sua equipe de vendas.
            </p>
            <S.BallonCard>
              <strong>Você ainda pode:</strong>
              <p>
                Gerar seus links personalizados de venda de assinaturas e
                cadastrar seu CNPJ para se tornar uma plataforma de vendas
                Dr.Hoje!
              </p>
            </S.BallonCard>
          </div>
        </S.Content>
        <S.ContentCard>
          <S.Card>{children}</S.Card>
          {/* Link + mensagem nova para tela de login */}
          <p>Quer fechar um contrato empresarial?</p>
          <a
            className="tag_cdst_proposta"
            href="https://drhoje.planium.io/web/login/entrar?target=venda"
          >
            Clique aqui para cadastrar sua proposta!
          </a>
        </S.ContentCard>
      </S.ContentCentral>
    </S.Container>
  );
}
