import React from 'react';
import { Controller } from 'react-hook-form';
import InputRound from '~/components/input_round';
import FormSection from '../../form_section';
import useCEP from '~/config/useCEP';
import CustomInputMask from '../../mask_input';

export default function EnderecoNovaVenda({
  register,
  errors,
  control,
  watchAllFields,
}) {
  const cep = watchAllFields?.endereco?.cep?.replace?.(/[^\d]+/g, '') || '';

  const { data } = useCEP(cep);

  return (
    <FormSection nome="Endereço">
      <div className="double-input">
        <div className="input-control">
          <InputRound
            label="CEP"
            error={errors?.endereco?.cep}
            message="Informe um CEP válido"
          >
            <Controller
              as={<CustomInputMask />}
              rules={{
                required: true,
              }}
              name="endereco.cep"
              mask="99999-999"
              maskChar=""
              control={control}
            />
          </InputRound>
        </div>

        <div className="espaco" />
        <div className="input-control">
          <InputRound
            label="Estado"
            error={errors?.endereco?.estado}
            message="Informe um estado válido"
          >
            <input
              type="text"
              name="endereco.estado"
              readOnly
              defaultValue={data?.buscarcep?.cidade?.sg_cidade}
              ref={register({ required: true })}
            />
          </InputRound>
        </div>
      </div>

      <div className="double-input">
        <div className="input-control-endereco">
          <InputRound
            label="Endereço"
            error={errors?.endereco?.endereco}
            message="Informe um endereço válido"
          >
            <input
              type="text"
              name="endereco.endereco"
              ref={register({ required: true })}
            />
          </InputRound>
        </div>
        <div className="espaco" />

        <div className="input-control-cidade">
          <InputRound
            label="Cidade"
            error={errors?.endereco?.cidade}
            message="Informe uma cidade válida"
          >
            <input
              type="text"
              name="endereco.cidade"
              readOnly
              defaultValue={data?.buscarcep?.cidade?.nm_cidade}
              ref={register({ required: true })}
              placeholder="Cidade"
            />
            <input
              type="hidden"
              name="endereco.cidade_id"
              readOnly
              defaultValue={data?.buscarcep?.cidade?.id}
              ref={register({ required: true })}
            />
          </InputRound>
        </div>
      </div>
      <div className="double-input">
        <InputRound
          label="Logradouro"
          error={errors?.endereco?.logradouro}
          message="Informe uma logradouro válido"
        >
          <input
            type="text"
            name="endereco.logradouro"
            readOnly
            defaultValue={data?.buscarcep?.te_logradouro}
            ref={register({ required: false })}
            placeholder="Logradouro"
          />
        </InputRound>
      </div>
      <div className="double-input">
        <div className="input-control">
          <InputRound
            label="Número"
            error={errors?.endereco?.numero}
            message="Informe uma número válido"
          >
            <input
              type="text"
              name="endereco.numero"
              ref={register({ required: true })}
            />
          </InputRound>
        </div>

        <div className="espaco" />
        <div className="input-control">
          <InputRound
            label="Bairro"
            error={errors?.endereco?.bairro}
            message="Informe uma bairro válido"
          >
            <input
              type="text"
              name="endereco.bairro"
              readOnly
              defaultValue={data?.buscarcep?.te_bairro}
              ref={register({ required: true })}
              placeholder="Bairro"
            />
          </InputRound>
        </div>
      </div>
      <div className="double-input">
        <InputRound label="Complemento">
          <input
            type="text"
            name="endereco.complemento"
            ref={register({ required: false })}
          />
        </InputRound>
      </div>
    </FormSection>
  );
}
