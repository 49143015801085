import styled, { css } from 'styled-components';
import { sidebarSelectedText } from '../../../styles/colors';

export const Container = styled.div`
  display: flex;
  height: 100vh;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const Side = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 0 30px 30px 0;
  z-index: 1000;
  height: 95%;
  width: 220px;
  margin: 30px 0;
  box-shadow: 0px 1px 4px #00000029;
  transition: 0.5s;

  input {
    display: none;
  }

  .is-active {
    color: #c10740;
    background: #f6d2d24f;
    font-weight: 600;

    i {
      color: #c10740;
    }
  }

  ul {
    height: 100%;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    li {
      height: 45px;
      transition: all 0.3s;
      label.item,
      a {
        cursor: pointer;
        padding: 8px 0;
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;
        color: #000000a8;
        gap: 16px;
        &.sub-item {
          display: flex;
          justify-content: left;
          margin-top: 5px;
          height: 36px;
          width: 100%;
          padding: 5px 12px;
          padding-left: 20px;
          font-size: 13px;
          font-weight: 300;
        }

        i {
          margin-left: 18px;
          color: #000000a8;
          align-self: center;
          height: 20px;
          width: 18px;
        }

        &:hover {
          background: #f6d2d24f;
          color: #c10740;

          i {
            color: #c10740;
          }
        }
      }
    }
    input:checked + li {
      height: fit-content;
    }
  }

  .linkRecolherSidebar {
    display: flex;
    align-self: center;
    margin-top: auto;
    text-decoration: none;
    font-size: 16px;
    color: #c10740;
    gap: 16px;
    background-color: transparent;
    border: none;

    i {
      color: #c10740;
    }

    &:hover {
      cursor: pointer;
    }
  }
  i {
    margin-top: 3px;
    font-size: 20px;
    color: #c10740;
  }
  .fa-chevron-circle-right {
    display: none;
    margin-left: auto;
    margin-right: auto;
  }
  &.recolher-expandir {
    width: 60px;

    span,
    .fa-chevron-circle-left,
    .fa-chevron-circle-right {
      display: none;
    }
    .fa-chevron-circle-right {
      display: block;
      color: ${sidebarSelectedText};
    }
  }

  ${(props) =>
    props.showMenu &&
    css`
      width: 60px;

      ul li {
        a {
          i {
            display: block;
          }
        }
      }

      &.recolher-expandir {
        width: fit-content !important;
        span,
        .fa-chevron-circle-left,
        .fa-chevron-circle-right {
          display: block;
        }
        .fa-chevron-circle-right {
          display: none;
        }
      }
      span,
      .fa-chevron-circle-left,
      .fa-chevron-circle-right {
        display: none;
      }
      .fa-chevron-circle-right {
        display: block;
      }
    `}

  @media only screen and (max-width: 1280px) {
    width: 60px;

    &.recolher-expandir {
      width: fit-content !important;
      span,
      .fa-chevron-circle-left,
      .fa-chevron-circle-right {
        display: block;
      }
      .fa-chevron-circle-right {
        display: none;
      }
    }
    span,
    .fa-chevron-circle-left,
    .fa-chevron-circle-right {
      display: none;
    }
    .fa-chevron-circle-right {
      display: block;
    }

    ${(props) =>
      props.showMenu &&
      css`
        width: 200px;

        span,
        .fa-chevron-circle-left,
        .fa-chevron-circle-right {
          display: block;
        }
        .fa-chevron-circle-right {
          display: none;
        }
      `};
  }
`;
