/* eslint-disable jsx-a11y/aria-role */
import React, { useCallback } from 'react';
import { Container } from './styles';

const CardDadosEmpresa = ({
  title,
  children,
  setShowForm,
  showForm,
  onSubmit,
  showAtualizar,
  actionBtn = false,
  onclickBtn,
  titleBtn,
}) => {
  const handleHideForm = useCallback(() => {
    setShowForm(true);
  }, [setShowForm]);

  const handleShowForm = useCallback(() => {
    setShowForm(false);
  }, [setShowForm]);

  return (
    <Container showForm={showForm}>
      <form id={title} onSubmit={onSubmit}>
        <header>
          <h3>{title}</h3>
          {showAtualizar && !actionBtn && (
            <div>
              {!showForm && <span onClick={handleHideForm}>Cancelar</span>}
              {showForm && (
                <span className="atualizar" onClick={handleShowForm}>
                  Atualizar
                </span>
              )}
            </div>
          )}
          {actionBtn && (
            <span className="atualizar" onClick={onclickBtn}>
              {titleBtn}
            </span>
          )}
        </header>
        <div className="contentForm">{children}</div>
      </form>
    </Container>
  );
};

export default CardDadosEmpresa;
