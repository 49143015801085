import React from 'react';
import { CircleLoading } from 'react-loadingg';
import { BallPulseSync } from 'react-pure-loaders';
import { Container, Loading } from './styles';

export default function LoadingContent({
  centralizado = false,
  loading = false,
  children,
}) {
  if (loading) {
    return (
      <Container centralizado={centralizado}>
        <CircleLoading
          color="#000"
          size="large"
          style={{
            alignSelf: 'center',
          }}
        />
      </Container>
    );
  }

  return [children];
}

export function ComponentLoad({ load = true }) {
  return (
    <Loading>
      <BallPulseSync color="#000" loading={load} />
    </Loading>
  );
}
