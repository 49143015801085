import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import RecuprarSenha from '../../views/auth/recuperar-senha';
import Login from '../../views/auth/login';

export default function PublicRouter() {
  return (
    <Switch>
      <Route path="/recuperar-senha" component={RecuprarSenha} />
      <Route path="/login" component={Login} />
      <Route path="/check-login" component={Login} />
      <Redirect to="/check-login" />
    </Switch>
  );
}
