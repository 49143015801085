import React, { useState } from 'react';
import moment from 'moment';
import Button from '~/components/button';
import DadosEmpresa from './dados-empresa';
import CadastrarNovaEmpresa from './nova';
import { EmpresaAtivaSelecProvider } from '~/config/contexts';
import Header from '../layouts/header';
import {
  ContentGeral,
  ContainerEmpresa,
  HeaderDados,
  GroupDados,
} from './styles';
import IfComponent from '~/components/if_component';
// import { Card } from '~/components/card';
import LoadingContent from '~/components/loading';
import useAuth from '~/config/auth_provider/contexto';
import CardDadosEmpresa from './dados-empresa/card-dados-empresa';
import { formatarCPF } from '~/config/utils';

export default function Empresa() {
  const [novaEmpresa, setNovaEmpresa] = useState(false);
  const {
    LoadingEmpresa,
    dataEmpresaSelecionada,
    RefetchEmpresa,
    me,
    equipe_vendas,
  } = useAuth();
  const {
    admin,
    refetchEquipeCorretor,
    loadingEquipeCorretor,
    showEmpresa,
    firstElement,
  } = equipe_vendas;

  return (
    <ContentGeral>
      <LoadingContent
        centralizado
        loading={LoadingEmpresa || loadingEquipeCorretor}
      >
        {/* EXIBIR SOMENTE QUANDO FOR UM CORRETOR VINCULADO A UMA EQUIPE */}
        <IfComponent hide={!(showEmpresa && !admin)}>
          <Header
            selectEmpresa
            card
            title="Corretora"
            texto="Veja as informações da corretora na qual você está vinculado."
          />

          <main>
            <ContainerEmpresa>
              <IfComponent hide={!firstElement?.id}>
                <HeaderDados>
                  <div className="control-input">
                    <GroupDados>
                      <CardDadosEmpresa title="Seus dados">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <b>Nome</b>
                              </td>
                              <td />
                              <td>{me?.nome_completo}</td>
                            </tr>
                            <tr>
                              <td>
                                <b> CPF</b>
                              </td>
                              <td />
                              <td>
                                {formatarCPF(me?.documentos[0]?.nr_documento)}
                              </td>
                            </tr>
                            <tr>
                              <td span="4">
                                <b>Data de nascimento</b>
                              </td>
                              <td />

                              <td>
                                {moment(me?.dt_nascimento).format('DD/MM/YYYY')}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <b>E-mail</b>
                              </td>
                              <td />

                              <td>{me?.email}</td>
                            </tr>

                            <tr>
                              <td>
                                <b>Sexo</b>
                              </td>
                              <td />
                              <td>
                                {me?.sexo === 'M' ? 'Masculino' : 'Feminino'}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <b>Celular</b>
                              </td>
                              <td />
                              <td>{me?.celular}</td>
                            </tr>
                          </tbody>
                        </table>
                      </CardDadosEmpresa>
                    </GroupDados>
                  </div>
                </HeaderDados>
              </IfComponent>
              <EmpresaAtivaSelecProvider
                value={{
                  RefetchEmpresa: refetchEquipeCorretor,
                  LoadingEmpresa: loadingEquipeCorretor,
                  ...firstElement,
                }}
              >
                <DadosEmpresa hide titleRepresentante="Seus dados" />
              </EmpresaAtivaSelecProvider>
            </ContainerEmpresa>
          </main>
        </IfComponent>
        {/* EXIBIR SOMENTE QUANDO O USUARIO FOR UM ADMIN DA CORRETORA */}
        <IfComponent hide={!admin}>
          <IfComponent hide={novaEmpresa}>
            <Header
              selectEmpresa
              card
              title="Corretora"
              texto="Dados da corretora."
            />

            <main>
              <ContainerEmpresa>
                <IfComponent hide={dataEmpresaSelecionada?.id}>
                  <HeaderDados>
                    <div className="control-input">
                      <GroupDados>
                        <Button
                          load={false}
                          disabled={false}
                          type="button"
                          radius="22px"
                          onClick={() => {
                            setNovaEmpresa(true);
                          }}
                        >
                          Cadastrar corretora PJ
                        </Button>

                        <CardDadosEmpresa title="Dados do representante legal">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>Nome</b>
                                </td>
                                <td />
                                <td>{me?.nome_completo}</td>
                              </tr>
                              <tr>
                                <td>
                                  <b> CPF</b>
                                </td>
                                <td />
                                <td>
                                  {formatarCPF(me?.documentos[0]?.nr_documento)}
                                </td>
                              </tr>
                              <tr>
                                <td span="4">
                                  <b>Data de nascimento</b>
                                </td>
                                <td />

                                <td>
                                  {moment(me?.dt_nascimento).format(
                                    'DD/MM/YYYY'
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>E-mail</b>
                                </td>
                                <td />

                                <td>{me?.email}</td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Sexo</b>
                                </td>
                                <td />
                                <td>
                                  {me?.sexo === 'M' ? 'Masculino' : 'Feminino'}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <b>Celular</b>
                                </td>
                                <td />
                                <td>{me?.celular}</td>
                              </tr>
                            </tbody>
                          </table>
                        </CardDadosEmpresa>
                      </GroupDados>
                      {/* <div className="group-card">
                        <Card>
                          <strong>Comissão - Produtos Pessoa Física</strong>
                          <p>
                            <b> Produto:</b> TOTAL/TODOS/MAIS
                          </p>
                          <p>100% da primeira parcela</p>
                          <p>5% vitalício</p>
                        </Card>

                        <Card>
                          <strong>Recebimento de comissão</strong>
                          <table>
                            <tr>
                              <th>Vendas</th>
                              <th>Emissão de NF</th>
                              <th>Recebimento</th>
                            </tr>

                            <tr>
                              <td>1 a 16</td>
                              <td>17 a 20</td>
                              <td>25</td>
                            </tr>
                            <tr>
                              <td>17 a 32</td>
                              <td>1 a 05</td>
                              <td>10</td>
                            </tr>
                          </table>
                        </Card>
                      </div> */}
                    </div>
                  </HeaderDados>
                </IfComponent>
                <IfComponent hide={!dataEmpresaSelecionada?.id}>
                  <EmpresaAtivaSelecProvider
                    value={{
                      RefetchEmpresa,
                      LoadingEmpresa,
                      ...dataEmpresaSelecionada,
                    }}
                  >
                    <DadosEmpresa />
                  </EmpresaAtivaSelecProvider>
                </IfComponent>
              </ContainerEmpresa>
            </main>
          </IfComponent>
          <IfComponent hide={!novaEmpresa}>
            <CadastrarNovaEmpresa setNovaEmpresa={setNovaEmpresa} />
          </IfComponent>
        </IfComponent>
      </LoadingContent>
    </ContentGeral>
  );
}
