/**
 * LOGIN
 */
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOADER_HEADER = 'LOADER_HEADER';
export const CADASTRO_REQUEST = 'CADASTRO_REQUEST';
export const CADASTRO_SUCCESS = 'CADASTRO_SUCCESS';
export const CADASTRO_FAILED = 'CADASTRO_FAILED';

export const AUTH_PROVIDER_CHANGE = 'AUTH_PROVIDER_CHANGE';

/**
 * BLOCO DE BUSCA DO SITE
 */

export const ATUALIZAR_BLOCO_BUSCA = 'ATUALIZAR_BLOCO_BUSCA';

export const INCLUIR_ITEM_CARRINHO_LOCAL = 'INCLUIR_ITEM_CARRINHO_LOCAL';
export const REMOVER_ITEM_CARRINHO_LOCAL = 'REMOVER_ITEM_CARRINHO_LOCAL';

export const NOVO_PEDIDO_PROCEDIMENTOS_REQUEST =
  'NOVO_PEDIDO_PROCEDIMENTOS_REQUEST';
export const NOVO_PEDIDO_PROCEDIMENTOS_FAILED =
  'NOVO_PEDIDO_PROCEDIMENTOS_FAILED';
export const NOVO_PEDIDO_PROCEDIMENTOS_SUCCESS =
  'NOVO_PEDIDO_PROCEDIMENTOS_SUCCESS';
export const CLEAR_PEDIDO_PROCEDIMENTOS = 'CLEAR_PEDIDO_PROCEDIMENTOS';

export const CADASTRAR_CARTAO_REQUEST = 'CADASTRAR_CARTAO_REQUEST';
export const CADASTRAR_CARTAO_SUCCESS = 'CADASTRAR_CARTAO_SUCCESS';
export const CADASTRAR_CARTAO_FAILED = 'CADASTRAR_CARTAO_FAILED';

export const ATUALIZAR_ID_CARRINHO = 'ATUALIZAR_ID_CARRINHO';

export const OPEN_CLOSE_MODAL = 'OPEN_CLOSE_MODAL';

export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const ATUALIZAR_ASSINATURA_ATIVA = 'ATUALIZAR_ASSINATURA_ATIVA';
export const ATUALIZAR_REQUEST_CARRINHO = 'ATUALIZAR_REQUEST_CARRINHO';

export const CARTAO = 'CARTAO';

export const BOLETO = 'BOLETO';

export const CLINICA = 'CLINICA';
export const LABORATORIO = 'LABORATORIO';

export const ASSOCIADA = 'ASSOCIADA';
export const EMPRESARIAL = 'EMPRESARIAL';
export const DISTRIBUIDORA = 'DISTRIBUIDORA';

export const ASSINATURA_REQUEST = 'ASSINATURA_REQUEST';
export const ASSINATURA_SUCCESS = 'ASSINATURA_SUCCESS';
export const ASSINATURA_FAILED = 'ASSINATURA_FAILED';

export const ATUALIZAR_EMPRESA_ATIVA = 'ATUALIZAR_EMPRESA_ATIVA';
export const ATUALIZAR_CONTRATO_ATIVO = 'ATUALIZAR_CONTRATO_ATIVO';

export const CAD_COLABORADOR_REQUEST = 'CAD_COLABORADOR_REQUEST';
export const CAD_COLABORADOR_SUCCESS = 'CAD_COLABORADOR_SUCCESS';
export const CAD_COLABORADOR_FAILED = 'CAD_COLABORADOR_FAILED';

export const CAD_DEPENDENTE_REQUEST = 'CAD_DEPENDENTE_REQUEST';
export const CAD_DEPENDENTE_SUCCESS = 'CAD_DEPENDENTE_SUCCESS';
export const CAD_DEPENDENTE_FAILED = 'CAD_DEPENDENTE_FAILED';

export const INCLUIR_ITEM_CARRINHO_REQUEST = 'INCLUIR_ITEM_CARRINHO_REQUEST';
export const INCLUIR_ITEM_CARRINHO_SUCCESS = 'INCLUIR_ITEM_CARRINHO_SUCCESS';
export const INCLUIR_ITEM_CARRINHO_FAILED = 'INCLUIR_ITEM_CARRINHO_FAILED';

export const REMOVER_ITEM_CARRINHO_REQUEST = 'REMOVER_ITEM_CARRINHO_REQUEST';
export const REMOVER_ITEM_CARRINHO_SUCCESS = 'REMOVER_ITEM_CARRINHO_SUCCESS';
export const REMOVER_ITEM_CARRINHO_FAILED = 'REMOVER_ITEM_CARRINHO_FAILED';

export const NOVO_SALDO_REQUEST = 'NOVO_SALDO_REQUEST';
export const NOVO_SALDO_SUCCESS = 'NOVO_SALDO_SUCCESS';
export const NOVO_SALDO_FAILED = 'NOVO_SALDO_FAILED';

export const REALIZAR_COMPRA_PROC_REQUEST = 'REALIZAR_COMPRA_PROC_REQUEST';
export const REALIZAR_COMPRA_PROC_SUCCESS = 'REALIZAR_COMPRA_PROC_SUCCESS';
export const REALIZAR_COMPRA_PROC_FAILED = 'REALIZAR_COMPRA_PROC_FAILED';

export const IMPORTACAO_USUARIO_REQUEST = 'IMPORTACAO_USUARIO_REQUEST';
export const IMPORTACAO_USUARIO_SUCCESS = 'IMPORTACAO_USUARIO_SUCCESS';
export const IMPORTACAO_USUARIO_FAILED = 'IMPORTACAO_USUARIO_FAILED';

export const SOLICITAR_SENHA_REQUEST = 'SOLICITAR_SENHA_REQUEST';
export const SOLICITAR_SENHA_SUCCESS = 'SOLICITAR_SENHA_SUCCESS';
export const SOLICITAR_SENHA_FAILED = 'SOLICITAR_SENHA_FAILED';

export const CAD_USUARIO_REQUEST = 'CAD_USUARIO_REQUEST';
export const CAD_USUARIO_SUCCESS = 'CAD_USUARIO_SUCCESS';
export const CAD_USUARIO_FAILED = 'CAD_USUARIO_FAILED';

export const UPDATE_USUARIO_REQUEST = 'UPDATE_USUARIO_REQUEST';
export const UPDATE_USUARIO_SUCCESS = 'UPDATE_USUARIO_SUCCESS';
export const UPDATE_USUARIO_FAILED = 'UPDATE_USUARIO_FAILED';

export const CAD_USUARIO_CARGO_REQUEST = 'CAD_USUARIO_CARGO_REQUEST';
export const CAD_USUARIO_CARGO_SUCCESS = 'CAD_USUARIO_CARGO_SUCCESS';
export const CAD_USUARIO_CARGO_FAILED = 'CAD_USUARIO_CARGO_FAILED';

export const UPDATE_USUARIO_DADOS_REQUEST = 'UPDATE_USUARIO_DADOS_REQUEST';
export const UPDATE_USUARIO_DADOS_SUCCESS = 'UPDATE_USUARIO_DADOS_SUCCESS';
export const UPDATE_USUARIO_DADOS_FAILED = 'UPDATE_USUARIO_DADOS_FAILED';

export const UPDATE_USUARIO_FOTO_REQUEST = 'UPDATE_USUARIO_FOTO_REQUEST';
export const UPDATE_USUARIO_FOTO_SUCCESS = 'UPDATE_USUARIO_FOTO_SUCCESS';
export const UPDATE_USUARIO_FOTO_FAILED = 'UPDATE_USUARIO_FOTO_FAILED';

export const UPDATE_USUARIO_SENHA_REQUEST = 'UPDATE_USUARIO_SENHA_REQUEST';
export const UPDATE_USUARIO_SENHA_SUCCESS = 'UPDATE_USUARIO_SENHA_SUCCESS';
export const UPDATE_USUARIO_SENHA_FAILED = 'UPDATE_USUARIO_SENHA_FAILED';

export const ALTERAR_SENHA_SUCCESS = 'ALTERAR_SENHA_SUCCESS';

/**
 * BUSCA DE HORARIOS
 */
export const REQUEST_AGENDAMENTO_HORARIOS = 'REQUEST_AGENDAMENTO_HORARIOS';
export const SUCCESS_AGENDAMENTO_HORARIOS = 'SUCCESS_AGENDAMENTO_HORARIOS';
export const FAILED_AGENDAMENTO_HORARIOS = 'FAILED_AGENDAMENTO_HORARIOS';

export const CHECK_USUARIO_REQUEST = 'CHECK_USUARIO_REQUEST';
export const CHECK_USUARIO_SUCCESS = 'CHECK_USUARIO_SUCCESS';
export const CHECK_USUARIO_FAILED = 'CHECK_USUARIO_FAILED';

export const ADMIN_PARCIAL = 'Admin Parcial';
export const CADASTRO = 'Cadastro';

export const CADASTRO_CORRETOR_REQUEST = 'CADASTRO_CORRETOR_REQUEST';
export const CADASTRO_CORRETOR_SUCCESS = 'CADASTRO_CORRETOR_SUCCESS';
export const CADASTRO_CORRETOR_FAILED = 'CADASTRO_CORRETOR_FAILED';

export const ENVIAR_EMAIL_REQUEST = 'ENVIAR_EMAIL_REQUEST';
export const ENVIAR_EMAIL_SUCCESS = 'ENVIAR_EMAIL_SUCCESS';
export const ENVIAR_EMAIL_FAILED = 'ENVIAR_EMAIL_FAILED';
