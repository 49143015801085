/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { persistor } from '~/modules/index';
import api from '~/config/api';
import { AuthContext } from './contexto';
import {
  LoginRequest,
  ProviderAuthChange,
  AtualizarEmpresaAtiva,
} from '~/modules/auth/actions';
import {
  capturarEmpresasRepresentante,
  capturarDadosEmpresaSelecionada,
  capturarCargoRepresentante,
  capturarRepresentanteLogado,
  capturarEquipesCorretor,
} from './actions';
import { ADMIN_PARCIAL, ASSOCIADA, CADASTRO } from '../constantes';
import Routes from '../routes_config';
import { findCPF } from '../utils';

/**
 * Esse provider, fornece informações do usuário para as demais paginas do sistema,
 */
export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { alert_message } = useSelector((st) => st.util);
  const auth = useSelector((authItem) => authItem.auth);
  api.defaults.headers.common.Authorization = auth.access_token;

  const {
    data: dataRep,
    refetch: refetchRep,
    loading: loadingRep,
    error: errReprese,
  } = capturarEmpresasRepresentante(auth);

  /**
   * Cadastrar equipes corretor
   */

  const {
    data: dataEquipeCorretor,
    refetch: refetchEquipeCorretor,
    loading: loadingEquipeCorretor,
    error: errEquipeCorretor,
  } = capturarEquipesCorretor(auth);
  /**
   * Captura as informações da empresa selecionada
   */
  const {
    data: dataEmpresaSelecionada,
    loading: LoadingEmpresa,
    refetch: RefetchEmpresa,
  } = capturarDadosEmpresaSelecionada({
    empresa_ativa: auth.empresa_ativa?.id,
    auth: auth.access_token,
  });

  /**
   * Quando selecionado uma empresa e um representante ativo
   */
  const {
    data: dataCargoRepre,
    loading: LoadingCargRepre,
    refetch: refetchCargRepre,
  } = capturarCargoRepresentante({
    auth: auth?.access_token,
    empresa_id: auth?.empresa_ativa?.id,
    user_id: auth?.user_id,
  });

  const me = capturarRepresentanteLogado(auth);

  useEffect(() => {
    if (!auth.empresa_ativa?.id && dataRep.length > 0) {
      dispatch(AtualizarEmpresaAtiva(dataRep[0]?.empresa));
    }
    if (auth.empresa_ativa?.id && dataRep.length === 0) {
      dispatch(AtualizarEmpresaAtiva({}));
    }
  }, [auth, dataRep]);

  // Se o usuário estiver logado e a requisição de verificação falhar é pra ser redirecionado para login
  useEffect(() => {
    if (auth.logged && errReprese) {
      dispatch(ProviderAuthChange({ logged: false, access_token: null }));
      api.defaults.headers.common.Authorization = '';
      localStorage.clear();
      persistor.purge();
    }
  }, [errReprese]);

  const login = async ({ cpf, password, token }) => {
    dispatch(
      LoginRequest({
        cpf,
        password,
        token,
      })
    );
  };

  const logout = () => {
    persistor.purge();
    localStorage.clear();
    api.defaults.headers.common.Authorization = '';
  };

  const renderRoutes = (rts, dataPayload, loadingPayload) => {
    const routesDefault = [];

    routesDefault.push(...rts);
    routesDefault.map((item, position) => {
      if (item.empresa && !dataPayload?.id && !loadingPayload) {
        routesDefault.splice(position, 1);
      }
      return item;
    });
    return [
      ...new Map(
        routesDefault?.sortElement?.('ordem')?.map((item) => [item.name, item])
      ).values(),
    ];
  };

  const renderPaths = (routesDefault) => {
    const paths = [];
    routesDefault.map((rout) => paths.push(rout.route));
    return paths;
  };
  const checkCustomPerfil = (cargos, accept) =>
    cargos?.filter?.((item) => item.cargo.cargo === accept)?.length > 0;

  const AuthProviderItem = {
    ...me,
    equipe_vendas: {
      showEmpresa: dataEquipeCorretor?.length > 0,
      firstElement: dataEquipeCorretor?.[0]?.empresa || {},
      admin:
        dataEquipeCorretor?.filter((adm) =>
          adm?.cargo?.cargo?.includes?.('Administrador')
        ).length > 0 || dataEquipeCorretor?.length === 0,
      equipes: dataEquipeCorretor,
      refetchEquipeCorretor,
      loadingEquipeCorretor,
      errEquipeCorretor,
    },
    isAuthenticated: auth.logged,
    auth: auth.access_token,
    empresa_ativa: dataEmpresaSelecionada,
    contrato_ativo: auth.contrato_ativo,
    auth_object: auth,
    login,
    loading: auth.loading,
    logout,
    // remove empresas duplicadas por causa dos cargos atribuidos a pessoa responsável
    dataRep: [
      ...new Map(dataRep?.map((item) => [item?.empresa?.id, item])).values(),
    ],
    refetchRep,
    loadingRep,
    dispatch,
    LoadingEmpresa,
    RefetchEmpresa,
    dataEmpresaSelecionada,
    dataCargoRepre,
    LoadingCargRepre,
    refetchCargRepre,
    tipoUsuario:
      auth.contrato_ativo?.tipo_empresa?.tipo !== ASSOCIADA
        ? { plural: 'Clientes', singular: 'Cliente' }
        : { plural: 'Clientes', singular: 'Cliente' },
    routes: {
      routes: renderRoutes(Routes, dataEmpresaSelecionada, LoadingEmpresa),
      paths: renderPaths(
        renderRoutes(Routes, dataEmpresaSelecionada, LoadingEmpresa)
      ),
      loading: LoadingCargRepre,
    },
    consultor_admin: checkCustomPerfil(dataCargoRepre, ADMIN_PARCIAL),
    consultor: checkCustomPerfil(dataCargoRepre, CADASTRO),
    user_logged_cpf: findCPF(dataCargoRepre?.[0]?.user?.documentos || [])?.[0]
      ?.nr_documento,
    user_logged: dataCargoRepre?.[0]?.user || null,
    consultorCheck:
      !checkCustomPerfil(dataCargoRepre, ADMIN_PARCIAL) &&
      checkCustomPerfil(dataCargoRepre, CADASTRO)
        ? findCPF(dataCargoRepre?.[0]?.user?.documentos || [])?.[0]
            ?.nr_documento
        : '',
  };

  return (
    <>
      <AuthContext.Provider value={AuthProviderItem}>
        <>
          {alert_message.alert && (
            <div className="alert">
              <Alert severity="info">{alert_message.message || ''}</Alert>
            </div>
          )}
        </>
        {children}
      </AuthContext.Provider>
    </>
  );
};
