import React from 'react';
import DadosEmpresa from './dados-empresa';
import Header from '../../layouts/header';

import { ContainerEmpresa } from '../styles';

export default function CadastrarNovaEmpresa({ setNovaEmpresa }) {
  return (
    <>
      <Header
        selectEmpresa
        card
        title="Cadastrar Corretora"
        texto="Cadastre sua empresa para que você possa realizar o cadastro equipes de vendas."
      />
      <main>
        <ContainerEmpresa>
          <DadosEmpresa setNovaEmpresa={setNovaEmpresa} />
        </ContainerEmpresa>
      </main>
    </>
  );
}
