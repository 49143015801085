import React, { useState } from 'react';
import InputForm from '../input_form';
import { BoxInputs, TextField } from './styles';

export default function InputFilter({
  callback = () => {},
  placeholder,
  id,
  type,
  className,
  clear,
  labelName,
}) {
  const [filterText, setFilterText] = useState('');

  return (
    <BoxInputs>
      <InputForm
        flex={1}
        labelName={labelName}
        clear={clear}
        onClear={() => {
          setFilterText('');
        }}
      >
        <TextField
          id={id}
          className={className}
          type={type}
          value={filterText}
          placeholder={placeholder}
          onChange={(e) => {
            setFilterText(e.target.value);
            callback(e.target.value);
          }}
        />
      </InputForm>
    </BoxInputs>
  );
}
