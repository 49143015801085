/* eslint-disable func-names */
/* eslint-disable import/no-cycle */
/* eslint-disable no-extend-native */
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { validaCPF, validaCNPJ } from './utils';

export const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);

export const checkDate = (item, format = 'YYYY-MM-DD') =>
  moment(item, format).isValid();

export const getCleanedItemSize = (e) => ({
  size: e?.replace?.(/[^\d]+/g, '')?.length,
  clean: e?.replace?.(/[^\d]+/g, ''),
});

export const checkIsCelular = (e) => {
  const { clean } = getCleanedItemSize(e);
  return clean?.length < 11 || e === undefined
    ? 'O número de celular é obrigatório.'
    : undefined;
};

export const checkIsTelefone = (e) => {
  const { clean } = getCleanedItemSize(e);
  return clean?.length < 10 || e === undefined
    ? 'O número de telefone é obrigatório.'
    : undefined;
};

export const checkIsCPF = (e) => {
  const { clean } = getCleanedItemSize(e);
  return !validaCPF(clean) ? 'Informe um CPF válido.' : undefined;
};

export const checkIsCNPJ = (e) => {
  const { clean } = getCleanedItemSize(e);
  return !validaCNPJ(clean) ? 'Informe um CNPJ válido.' : undefined;
};
export const diff_minutes = (dt2, dt1) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

export function DownloadExcelItems({ title, arrayItem }) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(arrayItem);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `${title}.${fileExtension}`);
}

DownloadExcelItems.propTypes = {
  title: PropTypes.string.isRequired,
};
