import React from 'react';
import ReactLoading from 'react-loading';

// import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Button({
  children,
  type,
  className,
  onClick,
  padding,
  radius,
  bgColor,
  largura,
  border,
  load = true,
  ...props
}) {
  const { disabled } = props;
  return (
    <Container
      border={border}
      type={type}
      disabled={disabled}
      className={className}
      padding={padding}
      bgColor={bgColor}
      radius={radius}
      onClick={!disabled && onClick}
      largura={largura}
      {...props}
    >
      {disabled && load ? (
        <ReactLoading type="bubbles" color="#ffff" />
      ) : (
        children
      )}
    </Container>
  );
}
