import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(180deg, #7f0753 0%, #eb0732 100%);

  .msg-obrigatorio {
    text-align: left;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export const ContentCentral = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100vh;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 840px) {
    flex-wrap: wrap;
    height: 100%;
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  @media (max-height: 720px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;

  div {
    max-width: 600px;
  }

  @media (max-height: 800px) {
    align-items: flex-start;

    div {
      h2 {
        margin-top: 82px;
      }
    }
  }

  @media (max-width: 1500px) {
    align-items: flex-start;

    div {
      h2 {
        margin-top: 82px;
      }
    }
  }

  div {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    p.ballon {
      background: rgba(110, 0, 35, 0.42);
      border: 2px solid #6e0023;
      padding: 16px;
      border-radius: 16px;
    }

    strong {
      color: #fff;
      margin: 16px 0;
    }

    ul {
      color: #fff;
      display: flex;
      flex-direction: column;

      li {
        small {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 1440px) {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 20px !important;
        span {
          font-size: 20px !important;
        }

        & + p {
          margin-top: 143px;
          color: #fff;
          font-size: 20px !important;
        }
      }

      span {
        font-size: 14px;
      }
    }

    figure {
      width: 152px;
      height: 152px;
      margin: 0;

      img {
        width: 100%;
        display: block;
        max-width: 199px;
        height: 161px;
      }
    }

    span {
      color: #fff;
      font-weight: 300;
      font-size: 16px;

      i {
        color: #fff;
        margin-right: 8px;
      }
    }

    h2 {
      color: #fff;
      font-size: 42px;
      font-weight: 300;
      margin-bottom: 0;

      b {
        font-size: 42px;

        font-weight: 700;
      }
    }

    p {
      color: #fff;
      font-size: 20px;

      b {
        font-size: 20px;
      }

      span {
        font-weight: 600;
        font-size: 20px;
      }

      & + p {
        margin-top: 52px;
        color: #fff;

        font-size: 20px;
      }
    }
  }
`;

export const ContentCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 0;

  @media (max-width: 840px) {
    width: 100%;
    padding: 42px 16px;
  }

  p {
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
  }

  .tag_cdst_proposta {
    color: #ffffff;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 510px;
  box-sizing: border-box;
  border: 1px solid #ededed;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 8px 6px -6px grey;

  @media (max-width: 1440px) {
    max-width: 410px;
  }

  @media (max-width: 760px) {
    margin: 0;
    max-width: 600px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: 100%;

  figure {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
    z-index: 1;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1280px) {
    display: none;
  }
`;

export const BallonCard = styled.article`
  background: #070387;
  border-radius: 16px;
  padding: 18px;

  strong {
    color: #fff;
  }

  p {
    margin: 0;
    color: #fff;
  }
`;
