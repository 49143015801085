/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import Header from '~/views/layouts/header';
import Container from '~/components/container';
import InputRound from '~/components/input_round';
import LoadingContent from '~/components/loading';

import useAuth from '~/config/auth_provider/contexto';
import { LISTAR_COLABORADORES, CARGOS_GRAPHQL } from '~/config/gql';
import { CadUsuarioRequest } from '~/modules/usuarios/actions';
import InputMasked from '~/components/InputMask';
import { validaCPF } from '~/config/validate';

import Button from '~/components/button';
import GlobalModal from '~/components/global_modal';

import {
  InfoFiltro,
  Busca,
  TabelaFiltro,
  Actions,
  FooterForm,
  ButtonControl,
} from './styles';

const columns = [
  {
    name: 'ID#',
    selector: 'id',
    width: '130px',
  },
  {
    name: 'Usuário',
    selector: 'usuario',
    sortable: true,
    cell: (data) => <div key={data.id}>{data?.user?.nome_completo}</div>,
  },
  {
    name: 'E-mail',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'CPF',
    selector: 'cpf',
    sortable: true,
    cell: (data) => (
      <div key={data.id}>{data?.user?.documentos?.[0]?.nr_documento}</div>
    ),
  },
  {
    name: 'Telefone',
    selector: 'telefone',
    sortable: true,
    cell: (data) => <div key={data.id}>{data?.user?.telefone}</div>,
  },
  {
    name: 'Celular',
    selector: 'celular',
    sortable: true,
    cell: (data) => <div key={data.id}>{data?.user?.celular}</div>,
  },
  {
    name: 'Ações',
    selector: 'actions',
    sortable: true,
    cell: (data) => (
      <Actions>
        <Link to={`/editar-usuario/${data?.user?.id}`}>Exibir/Editar</Link>
      </Actions>
    ),
  },
];

const Usuarios = () => {
  const [showModalCadastro, setShowModalCadastro] = useState(false);

  const dispatch = useDispatch();
  const { auth_object: auth, empresa_ativa } = useAuth();
  const [filtro, setFiltro] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [CPF, setCPF] = useState('');

  const { data: cargos } = useQuery(CARGOS_GRAPHQL, {
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth.logged || !auth.access_token || !empresa_ativa?.id,
  });

  const { loading, data: colaboradores, refetch: atualiarUsuarios } = useQuery(
    LISTAR_COLABORADORES,
    {
      variables: {
        empresa_id: empresa_ativa?.id,
        filtro,
      },
      context: {
        headers: {
          Authorization: auth.access_token,
        },
      },
      fetchPolicy: 'no-cache',
      skip: !auth.logged || !auth.access_token || !empresa_ativa?.id,
    }
  );

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {},
  });

  const onSubmit = (data) => {
    if (validaCPF(data.cpf)) {
      setShowModalCadastro(false);
      dispatch(CadUsuarioRequest({ ...data, atualiarUsuarios }));
    }
  };

  function removeDuplicates() {
    const limpo = [];
    let ultimo;
    colaboradores?.usuarios.map((item) => {
      if (item?.user?.id !== ultimo?.user?.id) {
        limpo.push(item);
      }
      ultimo = item;
      return false;
    });
    return limpo;
  }

  return (
    <Container>
      <Header title="Usuários" texto="" />
      <InfoFiltro>
        <Busca>
          <form action="">
            <span>
              <strong>Buscar por</strong>
              <InputRound label="Usuario">
                <input
                  type="text"
                  placeholder="digite o nome do usuario"
                  value={filtro}
                  onChange={(e) => {
                    setFiltro(e.target.value);
                  }}
                />
              </InputRound>
            </span>
            <ButtonControl>
              <Button bgColor="darkBlue" width="150px" radius="24px">
                Buscar
              </Button>
            </ButtonControl>
          </form>
          <ButtonControl>
            <Button
              bgColor="darkBlue"
              width="200px"
              radius="24px"
              onClick={() => setShowModalCadastro(true)}
            >
              <i className="fal fa-plus" /> Novo Usuario
            </Button>
          </ButtonControl>

          <GlobalModal
            handleShow={showModalCadastro}
            setHandleShow={setShowModalCadastro}
            titleModal="Dados pessoais"
            dialogClassName="modal-50w"
          >
            <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
              <h1>Cadastrar usuário</h1>

              <InputRound label="Cargo">
                <select
                  ref={register({ required: true })}
                  name="cargo_id"
                  id="cargo_id"
                  invalid={!!errors?.cargo_id}
                  required
                >
                  <option value="">Selecione</option>
                  {cargos?.cargoEmpresa?.map((cargo) => {
                    return cargo?.ds_cargo === 'Prestador' ? (
                      <option key={cargo.id} value={cargo.id}>
                        {' '}
                        {cargo.cargo}{' '}
                      </option>
                    ) : (
                      ''
                    );
                  })}
                </select>
              </InputRound>
              {errors?.cargo_id && <small>* Cargo é obrigatório!</small>}

              <InputRound label="Nome">
                <input
                  ref={register({ required: true })}
                  type="text"
                  placeholder="Nome completo"
                  id="nome_completo"
                  name="nome_completo"
                  invalid={!!errors?.nome_completo}
                />
              </InputRound>
              {errors?.nome_completo && <small>* Nome obrigatório!</small>}

              <InputRound label="E-mail de contato">
                <input
                  ref={register({ required: true })}
                  type="email"
                  name="email"
                  placeholder="exemplo@gmail.com"
                  invalid={!!errors?.email}
                  required
                />
              </InputRound>
              {errors?.email && <small>* E-mail obrigatório!</small>}

              <InputRound label="CPF" required>
                <InputMasked
                  mask="cpf"
                  ref={register({ required: true })}
                  onChange={(e) => setCPF(e.target.value)}
                  value={CPF}
                  type="text"
                  name="cpf"
                />
              </InputRound>
              {errors?.cpf && <small>* CPF obrigatório!</small>}
              {!validaCPF(CPF) && <small>* CPF Inválido!</small>}

              <InputRound label="Telefone">
                <InputMasked
                  mask="telefone"
                  ref={register({ required: false })}
                  onChange={(e) => setTelefone(e.target.value)}
                  value={telefone}
                  type="text"
                  name="telefone"
                />
              </InputRound>

              <InputRound label="Celular">
                <InputMasked
                  mask="celular"
                  ref={register({ required: true })}
                  onChange={(e) => setCelular(e.target.value)}
                  value={celular}
                  type="text"
                  name="celular"
                  invalid={!!errors?.celular}
                />
              </InputRound>
              {errors?.celular && <small>* Celular obrigatório!</small>}

              <InputRound label="Data de nascimento" htmlFor="dt_nascimento">
                <input
                  ref={register({ required: true })}
                  type="date"
                  name="dt_nascimento"
                  id="dt_nascimento"
                  invalid={!!errors?.dt_nascimento}
                />
              </InputRound>
              {errors?.dt_nascimento && (
                <small>* Data Nascimento obrigatório!</small>
              )}

              <InputRound label="Sexo">
                <select
                  ref={register({ required: true })}
                  name="sexo"
                  id="sexo"
                  invalid={!!errors?.sexo}
                  required
                >
                  <option value="" disabled selected>
                    {' '}
                    Selecionar{' '}
                  </option>
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                  <option value="O">Outros</option>
                </select>
              </InputRound>
              {errors?.sexo && <small>* Sexo obrigatório!</small>}

              <input
                type="hidden"
                name="cpf"
                value={CPF}
                ref={register({ required: true })}
              />
              <input
                type="hidden"
                name="telefone"
                value={telefone}
                ref={register({ required: false })}
              />
              <input
                type="hidden"
                name="celular"
                value={celular}
                ref={register({ required: true })}
              />
              <input
                type="hidden"
                name="empresa_id"
                value={auth?.empresa_ativa?.id}
                ref={register({ required: true })}
              />

              <FooterForm>
                <Button bgColor="darkBlue" width="150px">
                  Cadastrar
                </Button>
              </FooterForm>
            </form>
          </GlobalModal>
        </Busca>
      </InfoFiltro>
      <LoadingContent loading={loading}>
        <TabelaFiltro>
          <DataTable
            noHeader
            pagination
            paginationComponentOptions={{
              rowsPerPageText: 'linhas por página:',
              rangeSeparatorText: 'de',
              noRowsPerPage: true,
              selectAllRowsItem: false,
              selectAllRowsItemText: 'All',
            }}
            columns={columns}
            data={removeDuplicates() || []}
          />
        </TabelaFiltro>
      </LoadingContent>
    </Container>
  );
};

export default Usuarios;
