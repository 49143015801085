// main colors
export const dark = '#212121';
export const darkGray = '#6B6B6B';
export const gray = '#A1A1A1';
export const lightGray = '#CCCCCC';
export const lighter = '#E0E0E0';
export const blue = '#008BFF';
export const darkBlue = '#0439D6';
export const secondaryBlue = '#0779AB';
export const lightBlue = '#3AC7FF';
export const green = '#75BC1E';
export const orange = '#FF9D1E';
export const yellow = '#ffbb00';
export const red = '#c10740';

// select colours
export const selectBlue = '#004CFF';
export const selectRed = '#F13900';
export const selectGreen = '#00FC86';
export const selectPurple = '#5300FC';
export const selectPink = '#FC0056';
export const selectDarkGreen = '#33A79D';

export const background = '#f9f9f9';
export const divider = lighter;
export const error = red;
export const required = '#D7D7D7';
export const textColor = '#000000DE';
export const textColor2 = '#00000094';
export const link = blue;

// form components
export const borderInput = lightGray;
export const borderBtnRadio = '#D7D7D7';
export const labelInput = dark;
export const borderFocus = lightGray;
export const labelColor = '#6B6B6B';
export const placeholder = gray;
export const fieldValue = dark;
export const checkboxColor = blue;
export const disabledInput = '#D7D7D7';

// bullet component
export const bulletBorder = '#E8E9E9';
export const bulletHover = '#E8E9E9';
export const bulletActive = blue;

// Buttons component
export const defaultButton = blue;
export const btnEncerrarContrato = red;

// sidebar component
export const sidebarBackground = dark;
export const sidebarList = '#FFFFFF0F';
export const sidebarText = '#000000A8';
export const sidebarSelectedText = '#166DEF';

// pagination component
export const BgActivePagination = blue;
export const PaginationColor = darkGray;

// status icon
export const iconSync = yellow;
export const iconExclamation = red;

// accordion component
export const bgAccordion = lighter;
export const bgAccordionSelected = '#404040';
export const accordionColor = dark;

// ativar/desativar regra
export const ativarRegra = '#16E280';
export const desativarRegra = red;
