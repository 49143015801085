import React from 'react';
import FormVendas from '~/components/form-vendas';
import { Empresarial } from '../precificacao';

export default function ViewEmpresarial() {
  return (
    <FormVendas
      titleForm="Cadastro Empresarial"
      subTitle="a partir de 100 vidas"
      type="empresarial"
      produtosOption={Empresarial()}
    />
  );
}
