/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */

import React, { useRef, useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  BoxInputs,
  BoxTabs,
  ColaboradoresTabela,
  ContentGeral,
  CustomStyleDataTable,
  Form,
} from './styles';
import { checkIsCPF } from '~/config/utils';
import Header from '../layouts/header';
import Button from '~/components/button';

import InputFilter from '~/components/input_filter';
import {
  CADASTRAR_EQUIPE,
  REMOVER_CORRETOR,
  BUSCAR_EQUIPES,
  BUSCAR_TODOS_CORRETORES,
  EDITAR_EQUIPE,
  REMOVER_EQUIPE,
} from '~/config/gql';
import useAuth from '~/config/auth_provider/contexto';

import {
  RequestAlertConfirm,
  RequestSuccess,
  RequestFailed,
} from '~/config/alert';
import ButtonsAcaoDatatable from '~/components/buttons_acao_datatable';
import ModalBase from '~/components/ModalBase';
import InputRound from '~/components/input_round';
import CustomInputMask from '~/components/mask_input';
import { getColumns } from './contantes';
import IfComponent from '~/components/if_component';

import ExportarCorretoresExcel from './excel/corretores';
import ExportarEquipesExcel from './excel/equipes';

export default function Corretores() {
  const page = useRef(1);
  const [request, setRequest] = useState(BUSCAR_EQUIPES);
  const [filtro, setFiltro] = useState('EQUIPES');
  const [filterText, setFilterText] = useState('');
  const [novaEquipe, setNovaEquipe] = useState({ show: false, edit: false });
  const { auth, dispatch, empresa_ativa } = useAuth();
  const { handleSubmit, register, control } = useForm();

  const [removerCorretor] = useMutation(REMOVER_CORRETOR);
  const [cadastrarEquipe, { loading: loadCadEquipe }] = useMutation(
    CADASTRAR_EQUIPE
  );
  const [editarEquipe] = useMutation(EDITAR_EQUIPE);
  const [removerEquipe] = useMutation(REMOVER_EQUIPE);

  const [getEquipes, { data, loading, refetch: listarEquipes }] = useLazyQuery(
    request,
    {
      context: {
        headers: {
          Authorization: auth,
        },
      },
      variables: {
        empresa_id: empresa_ativa.id,
      },
      fetchPolicy: 'no-cache',
      skip: !auth || !empresa_ativa.id,
    }
  );

  useEffect(() => {
    if (filtro.includes('EQUIPES')) {
      setRequest(BUSCAR_EQUIPES);
      page.current = 1;
      getEquipes({
        variables: {
          first: 15,
          page: page.current,
          empresa_id: empresa_ativa.id,
        },
      });
    }

    if (filtro.includes('CONSULTORES')) {
      setRequest(BUSCAR_TODOS_CORRETORES);
      page.current = 1;
      getEquipes({
        variables: {
          first: 15,
          page: page.current,
          empresa_id: empresa_ativa.id,
        },
      });
    }
  }, [filtro]);

  useEffect(() => {
    if (empresa_ativa.id) {
      getEquipes({
        variables: {
          first: 15,
          page: page.current,
          empresa_id: empresa_ativa.id,
        },
      });
    }
  }, [request]);

  useEffect(() => {
    if (empresa_ativa.id) {
      getEquipes({
        variables: {
          first: 15,
          page: page.current,
          empresa_id: empresa_ativa.id,
        },
      });
    }
  }, [empresa_ativa]);

  const onChagePage = (pg) => {
    page.current = pg;
    getEquipes({
      variables: {
        first: 15,
        page: page.current,
        empresa_id: empresa_ativa.id,
      },
    });
  };

  const removerCorretorBtn = (row) => {
    const check = (call) => {
      if (call) {
        removerCorretor({
          variables: { id: row?.id },
          context: {
            headers: {
              Authorization: auth,
            },
          },
          skip: !row?.id || auth,
        })
          .then(() => {
            listarEquipes();
            dispatch(RequestSuccess('Corretor excluído com sucesso.'));
          })
          .catch(() => {
            dispatch(
              RequestFailed(
                'Não conseguimos realizar a exclusão do corretor, tente novamente.'
              )
            );
          });
      }
    };
    dispatch(
      RequestAlertConfirm(
        `Você deseja remover o corretor ${row?.user?.nome_completo}, deseja continuar?`,
        '',
        true,
        check
      )
    );
  };

  const handleRemoverEquipe = (row) => {
    const check = (call) => {
      if (call) {
        removerEquipe({
          variables: { id: row?.id },
          context: {
            headers: {
              Authorization: auth,
            },
          },
          skip: !row?.id || auth,
        })
          .then(() => {
            listarEquipes();
            dispatch(RequestSuccess('Equipe excluída com sucesso!'));
          })
          .catch(() => {
            dispatch(
              RequestFailed(
                'Não conseguimos realizar a exclusão da aquipe, tente novamente.'
              )
            );
          });
      }
    };

    dispatch(
      RequestAlertConfirm(
        `Você vai remover a equipe ${row?.ds_equipe}, deseja continuar?`,
        '',
        true,
        check
      )
    );
  };

  const edit = (row) => {
    setNovaEquipe({ show: true, edit: true, data: { ...row } });
  };

  const ActionList = (row) => {
    return (
      <ButtonsAcaoDatatable>
        <Link
          to={`/corretor/equipe-vendas/${row.id}/${btoa(
            JSON.stringify({
              ds_esquipe: row.ds_esquipe,
              id: row.id,
              ds_equipe: row.ds_equipe,
              nm_gestor: row.nm_gestor,
              documento: row.documento,
              celular: row.celular,
              email: row.email,
            })
          )}`}
        >
          <button type="button" className="edit">
            <i className="ver fas fa-users" />
          </button>
        </Link>

        <button
          type="button"
          className="edit"
          onClick={() => {
            edit(row);
          }}
        >
          <i className="ver fas fa-edit" />
        </button>

        <button
          type="button"
          className="remove"
          onClick={() => {
            handleRemoverEquipe(row);
          }}
        >
          <i className="ver fas fa-trash" />
        </button>
      </ButtonsAcaoDatatable>
    );
  };

  const ActionListCorretores = (row) => {
    return (
      <ButtonsAcaoDatatable>
        <Link
          to={`/corretor/links/${row.user.id}/vendas/${row.id}/${btoa(
            JSON.stringify({
              ...row,
              equipe: {
                ds_esquipe: row?.equipe_vendas?.ds_esquipe,
                id: row?.equipe_vendas?.id,
                ds_equipe: row?.equipe_vendas?.ds_equipe,
                nm_gestor: row?.equipe_vendas?.nm_gestor,
                documento: row?.equipe_vendas?.documento,
                celular: row?.equipe_vendas?.celular,
                email: row?.equipe_vendas?.email,
              },
            })
          )}`}
        >
          <button type="button" className="edit">
            <i className="fas fa-external-link" />
          </button>
        </Link>
        <Link
          to={`/corretor/${row.equipe_vendas.id}/edit/${row.id}/${btoa(
            JSON.stringify({
              ...row,
            })
          )}`}
        >
          <button type="button" className="edit">
            <i className="fas fa-pencil" />
          </button>
        </Link>

        <button
          type="button"
          className="remove"
          onClick={() => {
            removerCorretorBtn(row);
          }}
        >
          <i className="ver fas fa-trash" />
        </button>
      </ButtonsAcaoDatatable>
    );
  };

  const aplicarFiltro = () => {
    if (filterText.length <= 0) {
      return false;
    }
    page.current = 1;
    getEquipes({
      variables: {
        first: 15,
        page: page.current,
        empresa_id: empresa_ativa.id,
        filtro: filterText,
      },
    });
  };
  return (
    <ContentGeral>
      <Header
        title="Equipe Vendas / Corretores"
        texto="Nesse ambiente é possível: Criar e editar equipe de vendas / cadastrar e editar vendedores / verificar links de vendas exclusivo para cada vendedor."
      />
      <ModalBase
        className="modal"
        title={
          (novaEquipe.edit && 'Atualizar Equipe') ||
          (!novaEquipe.edit && 'Nova Equipe')
        }
        showButton
        loading={loadCadEquipe}
        nameBtn={
          (novaEquipe.edit && 'Atualizar') || (!novaEquipe.edit && 'Cadastrar')
        }
        callback={(payload) => {
          if (!payload.id) {
            cadastrarEquipe({
              variables: { ...payload },
              context: {
                headers: {
                  Authorization: auth,
                },
              },
              skip: auth,
            }).then(() => {
              dispatch(RequestSuccess('Equipe Cadastrada com sucesso!'));
              listarEquipes();
              setNovaEquipe({ show: false, edit: false });
            });
          }

          if (payload.id) {
            // implementar atualizacao
            editarEquipe({
              variables: { ...payload },
              context: {
                headers: {
                  Authorization: auth,
                },
              },
              skip: auth,
            }).then(() => {
              dispatch(RequestSuccess('Equipe Editada com sucesso!'));
              listarEquipes();
              setNovaEquipe({ show: false, edit: false });
            });
          }
        }}
        close={() => {
          setNovaEquipe({ show: false, edit: false });
        }}
        show={novaEquipe.show}
        handleSubmit={handleSubmit}
      >
        <Form>
          <IfComponent hide={!novaEquipe?.data?.id}>
            <input
              className="form-control"
              type="hidden"
              name="id"
              defaultValue={novaEquipe?.data?.id}
              ref={register({ required: true })}
            />
          </IfComponent>
          <InputRound label="Informe o nome da equipe">
            <input
              className="form-control"
              type="string"
              maxLength="100"
              placeholder="digite aqui"
              defaultValue={novaEquipe?.data?.ds_equipe || ''}
              name="ds_equipe"
              ref={register({ required: true })}
            />
            <input
              className="form-control"
              type="hidden"
              value={empresa_ativa.id || null}
              name="empresa_id"
              ref={register({ required: true })}
            />
          </InputRound>

          <InputRound label="Informe o nome do gestor">
            <input
              className="form-control"
              type="string"
              maxLength="100"
              defaultValue={novaEquipe?.data?.nm_gestor || ''}
              placeholder="digite aqui"
              name="nm_gestor"
              ref={register({ required: true })}
            />
          </InputRound>

          <InputRound label="Informe o CPF">
            <Controller
              as={<CustomInputMask />}
              onChange={([e]) => ({ value: e })}
              rules={{
                validate: {
                  inputTelRequired: checkIsCPF,
                },
              }}
              name="documento"
              defaultValue={novaEquipe?.data?.documento || ''}
              mask="999.999.999-99"
              maskChar="_"
              placeholder="ex. 999.999.999-99"
              className="form-control"
              control={control}
            />
          </InputRound>

          <InputRound label="Informe o celular">
            <CustomInputMask
              name="celular"
              mask="(99) 9 9999-9999"
              defaultValue={novaEquipe?.data?.celular || ''}
              register={register({ required: true })}
              maskChar="_"
              placeholder="ex. (99) 9 9999-9999"
            />
          </InputRound>

          <InputRound label="Informe o e-mail">
            <input
              className="form-control"
              type="email"
              defaultValue={novaEquipe?.data?.email || ''}
              maxLength="100"
              placeholder="digite aqui"
              name="email"
              ref={register({ required: true })}
            />
          </InputRound>
        </Form>
      </ModalBase>
      <ColaboradoresTabela>
        <CustomStyleDataTable>
          <div className="control-input">
            <Button
              className="button-nova-equipe"
              load={false}
              disabled={false}
              type="button"
              bgColor="gradient"
              radius="22px"
              onClick={() => {
                setNovaEquipe({ show: true, edit: false });
              }}
            >
              Nova Equipe
            </Button>
          </div>
          <div className="control-input">
            <BoxTabs>
              <BoxInputs>
                <InputRound label="FILTRO">
                  <select
                    onChange={(e) => {
                      setFiltro(e.target.value);
                    }}
                  >
                    <option value="EQUIPES">EQUIPES</option>
                    <option value="CONSULTORES">CONSULTORES</option>
                  </select>
                </InputRound>

                <InputFilter
                  style={{ width: '100%' }}
                  labelName="Pesquisar por: "
                  placeholder="Nome Consultor, CPF"
                  id="buscar-usuarios"
                  type="text"
                  className="input-cpf"
                  clear
                  callback={(datas) => {
                    setFilterText(datas);
                  }}
                />
                <Button
                  // disabled={LoadingAdesao || LoadingTotalAdesao}
                  type="button"
                  radius="22px"
                  bgColor="red"
                  onClick={() => {
                    aplicarFiltro();
                  }}
                >
                  aplicar
                </Button>
              </BoxInputs>
            </BoxTabs>
          </div>
          <ExportarCorretoresExcel
            auth={auth}
            hide={filtro.includes('EQUIPES')}
            first={15}
            colaboradorFilters={{
              empresa_id: empresa_ativa.id,
              filtro: filterText,
            }}
          />
          <ExportarEquipesExcel
            auth={auth}
            hide={!filtro.includes('EQUIPES')}
            first={15}
            colaboradorFilters={{
              empresa_id: empresa_ativa.id,
              filtro: filterText,
            }}
          />

          <DataTable
            noHeader
            pagination
            noDataComponent="Nenhuma equipe encontrada, tente cadastrar uma para ser listada aqui."
            columns={getColumns(
              filtro,
              ActionList,
              data?.getTodosCorretores?.data?.length > 0,
              data?.getEquipes?.data?.length > 0,
              ActionListCorretores
            )}
            paginationTotalRows={
              data?.getTodosCorretores?.paginatorInfo?.total ||
              data?.getEquipes?.paginatorInfo?.total ||
              0
            }
            data={
              data?.getEquipes?.data || data?.getTodosCorretores?.data || []
            }
            dense
            paginationServer
            progressPending={loading}
            onChangePage={onChagePage}
            paginationPerPage={15}
          />
        </CustomStyleDataTable>
      </ColaboradoresTabela>
    </ContentGeral>
  );
}
