import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  align-self: center;
  height: 300px;

  ${(props) =>
    props.centralizado &&
    css`
      height: 100vh;
    `}
`;

export const Loading = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
`;
