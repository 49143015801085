/* eslint-disable radix */
import moment from 'moment';
import {
  findCPF,
  formatarCPF,
  translatePaymentStatus,
  translateSignatureStatus,
} from '~/config/utils';

export const getColumns = (ActionList) => {
  return [
    {
      name: 'Responsável',
      cell: (row) => row?.pedido?.user?.nome_completo?.capitalize?.(),
      sortable: true,
      wrap: true,
    },
    {
      name: 'CPF',
      cell: (row) => {
        return formatarCPF(
          findCPF(row.pedido?.user?.documentos || [])?.[0]?.nr_documento
        );
      },
      sortable: true,
    },
    {
      name: 'Consultor',
      sortable: true,
      cell: (row) => {
        return (
          row?.corretor?.nome_completo ||
          row?.corretor_equipe?.user?.nome_completo ||
          '-'
        );
      },
    },
    {
      name: 'Vigência',
      cell: (row) => {
        return moment(row?.created_at || '').format('DD/MM/YYYY');
      },
      sortable: true,
    },
    {
      name: 'Produto',
      cell: (row) => {
        return row?.pedido?.itempedido?.assinatura?.subcontrato?.plano?.plano;
      },
      sortable: true,
    },
    {
      name: 'Vidas',
      cell: (row) => {
        return row?.pedido?.itempedido?.assinatura?.versao?.totalAssinantes;
      },
      sortable: true,
    },
    {
      name: 'Valor',
      selector: 'id',
      cell: (row) => {
        return (parseInt(row?.total) / 100)?.applyBRLNumber?.();
      },
      sortable: true,
    },
    {
      name: 'Pagamento',
      cell: (row) => {
        return row?.metodo?.metodo;
      },
      sortable: true,
    },
    {
      name: 'Equipe',
      cell: (row) => {
        return row?.corretor_equipe?.equipe_vendas?.ds_equipe || '-';
      },
      sortable: true,
    },
    {
      name: 'Status do pagamento',
      cell: (row) => {
        return translatePaymentStatus(row?.atualizacao_pagamento[0]?.status);
      },
      sortable: true,
    },
    {
      name: 'Status da assinatura',
      cell: (row) => {
        return translateSignatureStatus(
          row?.pedido?.itempedido?.assinatura?.suspenso
        );
      },
      sortable: true,
    },
    {
      name: 'Ação',
      cell: (row) => ActionList(row),
    },
  ];
};
