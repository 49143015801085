import styled from 'styled-components';
import { darken } from 'polished';

import {
  defaultButton,
  red,
  green,
  lightBlue,
  blue,
  darkBlue,
} from '~/styles/colors';

function getBgColor(bgColor) {
  switch (bgColor) {
    case 'red':
      return red;
    case 'green':
      return green;
    case 'blue':
      return blue;
    case 'lightBlue':
      return lightBlue;
    case 'darkBlue':
      return darkBlue;
    case 'gray':
      return '#b9b9b9';
    case 'transparent':
      return 'transparent';

    default:
      return defaultButton;
  }
}

export const Container = styled.button`
  display: flex;
  width: ${(props) => props.largura || 'auto'};
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 40px;
  padding: ${(props) => props.padding || '0 15px'};
  background: ${(props) => getBgColor(props.bgColor)};
  border: ${(props) => props.border || 'none'};
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.6px;
  color: ${(props) => props.color || '#FFF'};
  border-radius: ${(props) => props.radius || '4px'};
  transition: all 0.5s;
  cursor: pointer;
  font-weight: 600;
  justify-content: center;

  &:hover,
  &:active {
    color: white;
    background-color: ${(props) => darken(0.1, getBgColor(props.bgColor))};
  }

  .btn-rounded {
    justify-content: center;
    border-radius: 22px;
  }
`;
