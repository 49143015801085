/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-unused-expressions */

import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import {
  Content,
  SectionFormPaciente,
  ButtonsGroup,
  ButtonControl,
} from './styles';

import InputRound from '~/components/input_round';
import ButtonRound from '~/components/button_round';
import CustomInputMask from '~/components/mask_input';
import Header from '../../../layouts/header';
import IfComponent from '~/components/if_component';
import { checkIsCelular } from '~/config/util';
import { ATUALIZAR_CORRETOR, BUSCAR_DADOS_CORRETOR } from '~/config/gql';
import useAuth from '~/config/auth_provider/contexto';
import { RequestSuccess, RequestFailed } from '~/config/alert';
import LoadingContent from '~/components/loading';
import { findCPF, formatarCPF } from '~/config/utils';

const EditarCorretor = () => {
  const { corretor_id, equipe_venda_id, content } = useParams();
  const item = JSON.parse(atob(content));
  console.log(item);
  const { goBack } = useHistory();

  const { register, handleSubmit, control, errors } = useForm({
    defaultValues: {},
  });
  const { auth, dispatch } = useAuth();

  const [atualizarCorretor, { loading }] = useMutation(ATUALIZAR_CORRETOR, {
    context: {
      headers: {
        Authorization: auth,
      },
    },
    skip: !equipe_venda_id || !auth,
  });

  const { data, loading: LoadList } = useQuery(BUSCAR_DADOS_CORRETOR, {
    variables: {
      id: corretor_id,
      equipe_venda_id,
    },
    context: {
      headers: {
        Authorization: auth,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth || !corretor_id,
  });

  const onSubmit = (data) => {
    atualizarCorretor({
      variables: { data_user: { ...data }, corretor_id },
    })
      .then(() => {
        goBack();
        dispatch(RequestSuccess('Corretor atualizado com sucesso!'));
      })
      .catch((e) => {
        dispatch(
          RequestFailed(
            'Não conseguimos atualizar o corretor, tente novamente!'
          )
        );
        console.log(e);
      });
  };

  return (
    <Content>
      <LoadingContent loading={LoadList} centralizado>
        <Header
          title="Atualizar Corretor"
          texto="Cadastre corretor na empresa previamente selecionada. Você também pode acompanhar o extrato de procedimentos utilizados pelos ."
        />

        <SectionFormPaciente>
          <strong role="button" onClick={() => goBack()}>
            <i className="fas fa-arrow-left" />
            Dados do corretor
          </strong>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="double-input">
              <InputRound
                label="CPF"
                required
                error={errors?.documento || false}
                message={
                  errors?.documento?.message ||
                  'Você deve informar um CPF válido.'
                }
                disabled
              >
                <input
                  defaultValue={formatarCPF(
                    findCPF(
                      data?.getCorretorDetalhe?.user.documentos || []
                    )?.[0]?.nr_documento || ''
                  )}
                  disabled
                />
              </InputRound>
            </div>
            <div className="group-input-row">
              <div className="group-input">
                <InputRound
                  label="Nome Completo"
                  error={errors?.nome_completo || false}
                  message={
                    errors?.nome_completo?.message ||
                    'Você deve informar o nome completo.'
                  }
                >
                  <input
                    type="text"
                    name="nome_completo"
                    maxLength="100"
                    defaultValue={
                      data?.getCorretorDetalhe?.user.nome_completo || ''
                    }
                    ref={register({ required: true, mix: 2, max: 100 })}
                  />
                </InputRound>
                <div className="double-input margin">
                  <InputRound
                    label="Data de Nasc"
                    error={errors?.dt_nascimento || false}
                    message={
                      errors?.dt_nascimento?.message ||
                      'Você deve informar a data de nascimento.'
                    }
                  >
                    <input
                      type="date"
                      name="dt_nascimento"
                      defaultValue={
                        data?.getCorretorDetalhe?.user.dt_nascimento || ''
                      }
                      ref={register({ required: true })}
                    />
                  </InputRound>
                  <InputRound label="Gênero">
                    <select
                      defaultValue={data?.getCorretorDetalhe?.user.sexo}
                      name="sexo"
                      ref={register({ required: true })}
                    >
                      <option value="F">Feminino</option>
                      <option value="M">Masculino</option>
                    </select>
                  </InputRound>
                </div>
              </div>
              <div className="espaco" />

              <div className="group-input">
                <div className="double-input flex-1">
                  <InputRound
                    label="E-mail"
                    required
                    error={errors?.email || false}
                    message={
                      errors?.email?.message ||
                      'Você deve informar um e-mail válido.'
                    }
                  >
                    <input
                      type="text"
                      name="email"
                      defaultValue={data?.getCorretorDetalhe?.email}
                      ref={register({ required: true })}
                    />
                  </InputRound>
                </div>
                <div className="double-input  flex-1">
                  <InputRound
                    label="Celular"
                    error={errors?.celular || false}
                    message={
                      errors?.celular?.message ||
                      'Você deve informar um celular válido.'
                    }
                  >
                    <Controller
                      as={<CustomInputMask />}
                      onChange={([e]) => ({ value: e })}
                      rules={{
                        validate: {
                          inputTelRequired: checkIsCelular,
                        },
                        required: true,
                      }}
                      name="celular"
                      mask="(99) 9 9999-9999"
                      maskChar="_"
                      defaultValue={data?.getCorretorDetalhe?.contato_cargo}
                      placeholder="ex. (99) 9 9999-9999"
                      control={control}
                    />
                  </InputRound>
                </div>
              </div>
            </div>
            <ButtonsGroup>
              <div>
                <ButtonControl>
                  <IfComponent hide={loading}>
                    <ButtonRound
                      bgColor="gray"
                      onClick={() => {
                        goBack();
                      }}
                    >
                      Cancelar
                    </ButtonRound>
                  </IfComponent>
                </ButtonControl>
              </div>
              <ButtonControl>
                <ButtonRound
                  type="submit"
                  disabled={loading}
                  className="gradient"
                >
                  atualizar
                </ButtonRound>
              </ButtonControl>
            </ButtonsGroup>
          </form>
        </SectionFormPaciente>
      </LoadingContent>
    </Content>
  );
};

export default EditarCorretor;
