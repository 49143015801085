/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import LoadingContent from '~/components/loading';
import Header from '~/views/layouts/header';
import Container from '~/components/container';
import GlobalModal from '~/components/global_modal';
import InputRound from '~/components/input_round';

import InputMasked from '~/components/InputMask';
import useAuth from '~/config/auth_provider/contexto';
import { GET_USUARIO, GET_CARGOS_USUARIO, CARGOS_GRAPHQL } from '~/config/gql';
import Button from '~/components/button';
import {
  UpdateUsuarioRequest,
  CadUsuarioCargoRequest,
} from '~/modules/usuarios/actions';

import {
  ContentCardDados,
  ContentDadosLine,
  GroupList,
  CardDados,
  GroupListPerfis,
  CardHeaderdados,
  Form,
  FooterForm,
} from './styles';

const DadosClinica = () => {
  const [showModalEditar, setShowModalEditar] = useState(false);
  const [showModalPerfil, setShowModalPerfil] = useState(false);
  const [perfil, setPerfil] = useState([]);
  const { auth_object: auth, empresa_ativa } = useAuth();
  const { user_id } = useParams();
  const dispatch = useDispatch();

  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');

  const {
    loading: loadUsuario,
    data: usuario,
    refetch: atualiarUsuario,
  } = useQuery(GET_USUARIO, {
    variables: {
      empresa_id: empresa_ativa?.id,
      user_id,
    },
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth.logged || !auth.access_token || !empresa_ativa?.id,
  });

  const { data: cargos, refetch: atualiarCargos } = useQuery(
    GET_CARGOS_USUARIO,
    {
      variables: {
        empresa_id: empresa_ativa?.id,
        user_id,
      },
      context: {
        headers: {
          Authorization: auth.access_token,
        },
      },
      fetchPolicy: 'no-cache',
      skip: !auth.logged || !auth.access_token || !empresa_ativa?.id,
    }
  );

  const { data: cargos_disponiveis } = useQuery(CARGOS_GRAPHQL, {
    context: {
      headers: {
        Authorization: auth.access_token,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !auth.logged || !auth.access_token || !empresa_ativa?.id,
  });

  useEffect(() => {
    if (cargos?.cargosUsuario.length > 0) {
      const temp = cargos.cargosUsuario.map((item) => ({
        cargoEmpresa_id: item.id,
        empresa_id: empresa_ativa?.id,
        user_id,
        cargo_id: item.cargo.id,
        email: item.email,
        contato: item.contato_cargo,
        descricao: item.cargo.cargo,
      }));
      setPerfil(temp);
    }
  }, [cargos]);

  function removerPerfil(cargo_id) {
    const removido = perfil.filter((a) => a.cargo_id !== cargo_id);
    setPerfil(removido);
  }

  function addPerfil(event) {
    const obj = JSON.parse(event.target.value);

    const temp = {
      cargoEmpresa_id: null,
      empresa_id: empresa_ativa?.id,
      user_id,
      cargo_id: obj?.id,
      email: cargos?.cargosUsuario?.[0]?.email,
      contato: cargos?.cargosUsuario?.[0]?.contato_cargo,
      descricao: obj?.cargo,
    };

    const index = perfil.findIndex((val) => val.cargo_id === temp.cargo_id);
    if (index < 0) {
      setPerfil((old) => [...old, temp]);
    }
  }

  function salvarPerfils() {
    dispatch(
      CadUsuarioCargoRequest({ cargo: JSON.stringify(perfil), atualiarCargos })
    );
    setShowModalPerfil(false);
  }

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {},
  });

  // Salva os dados
  const onSubmit = (data) => {
    dispatch(UpdateUsuarioRequest({ ...data, atualiarUsuario }));
    setShowModalEditar(false);
  };

  return (
    <Container>
      <Header
        title="Editar usuário"
        texto="Faça aqui a alteração de dados do usuario."
      />
      <ContentDadosLine>
        <ContentCardDados>
          <LoadingContent loading={loadUsuario}>
            <CardDados>
              <CardHeaderdados>
                <span>Dados do Usuário</span>

                <a href="#senha" onClick={() => setShowModalEditar(true)}>
                  Editar
                </a>
                <GlobalModal
                  handleShow={showModalEditar}
                  setHandleShow={setShowModalEditar}
                  titleModal="Dados pessoais"
                  dialogClassName="modal-50w"
                >
                  <form id="update-form" onSubmit={handleSubmit(onSubmit)}>
                    <h1>Editar usuário</h1>
                    <InputRound label="Nome">
                      <input
                        ref={register({ required: true })}
                        type="text"
                        placeholder="Nome completo"
                        id="nome_completo"
                        name="nome_completo"
                        defaultValue={usuario?.usuario?.user?.nome_completo}
                        invalid={!!errors?.nome_completo}
                      />
                    </InputRound>
                    {errors?.nome_completo && (
                      <small>* Nome obrigatório!</small>
                    )}

                    <InputRound label="E-mail de contato">
                      <input
                        ref={register({ required: true })}
                        type="email"
                        name="email"
                        placeholder="exemplo@gmail.com"
                        invalid={!!errors?.email}
                        defaultValue={usuario?.usuario?.email}
                        required
                      />
                    </InputRound>
                    {errors?.email && <small>* E-mail obrigatório!</small>}

                    <InputRound label="CPF" required>
                      <InputMasked
                        mask="cpf"
                        ref={register({ required: false })}
                        value={
                          usuario?.usuario?.user?.documentos?.[0]?.nr_documento
                        }
                        type="text"
                        name="cpf"
                        readonly
                        disabled
                      />
                    </InputRound>

                    <InputRound label="Telefone">
                      <InputMasked
                        mask="telefone"
                        ref={register({ required: false })}
                        onChange={(e) => setTelefone(e.target.value)}
                        placeHolder={usuario?.usuario?.user?.telefone}
                        value={telefone}
                        type="text"
                        name="telefoneTemp"
                      />
                    </InputRound>

                    <InputRound label="Celular">
                      <InputMasked
                        mask="celular"
                        ref={register({ required: false })}
                        onChange={(e) => setCelular(e.target.value)}
                        placeHolder={usuario?.usuario?.contato_cargo}
                        value={celular}
                        type="text"
                        name="celularTemp"
                        invalid={!!errors?.celular}
                      />
                    </InputRound>
                    {errors?.celular && <small>* Celular obrigatório!</small>}

                    <InputRound
                      label="Data de nascimento"
                      htmlFor="dt_nascimento"
                    >
                      <input
                        ref={register({ required: true })}
                        type="date"
                        name="dt_nascimento"
                        id="dt_nascimento"
                        defaultValue={usuario?.usuario?.user?.dt_nascimento}
                        invalid={!!errors?.dt_nascimento}
                      />
                    </InputRound>
                    {errors?.dt_nascimento && (
                      <small>* Data Nascimento obrigatório!</small>
                    )}

                    <InputRound label="Sexo">
                      <select
                        ref={register({ required: true })}
                        name="sexo"
                        id="sexo"
                        invalid={!!errors?.sexo}
                        required
                      >
                        <option value={usuario?.usuario?.user?.sexo} selected>
                          {usuario?.usuario?.user?.sexo}
                        </option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                        {/* <option value="O">Outros</option> */}
                      </select>
                    </InputRound>
                    {errors?.sexo && <small>* Sexo obrigatório!</small>}

                    <input
                      type="hidden"
                      name="cpf"
                      value={
                        usuario?.usuario?.user?.documentos?.[0]?.nr_documento
                      }
                      ref={register({ required: true })}
                    />
                    <input
                      type="hidden"
                      name="telefone"
                      value={
                        telefone.length === 0
                          ? usuario?.usuario?.user?.telefone
                          : telefone
                      }
                      ref={register({ required: false })}
                    />
                    <input
                      type="hidden"
                      name="celular"
                      value={
                        celular.length === 0
                          ? usuario?.usuario?.contato_cargo
                          : celular
                      }
                      ref={register({ required: true })}
                    />
                    <input
                      type="hidden"
                      name="empresa_id"
                      value={auth?.empresa_ativa?.id}
                      ref={register({ required: true })}
                    />
                    <input
                      type="hidden"
                      name="cargo_empresa_id"
                      value={usuario?.usuario?.id}
                      ref={register({ required: true })}
                    />
                    <input
                      type="hidden"
                      name="cargo_id"
                      value={cargos?.cargosUsuario?.[0]?.cargo?.id}
                      ref={register({ required: true })}
                    />
                    <input
                      type="hidden"
                      name="user_id"
                      value={user_id}
                      ref={register({ required: true })}
                    />

                    <FooterForm>
                      <Button bgColor="darkBlue" width="150px" disabled={false}>
                        Salvar
                      </Button>
                    </FooterForm>
                  </form>
                </GlobalModal>
              </CardHeaderdados>
              <hr />
              <GroupList>
                <ul>
                  <li>CPF</li>
                  <li>Nome </li>
                  <li>Data de nascimento</li>
                  <li>Sexo</li>
                  <li>E-mail</li>
                  <li>Telefone</li>
                  <li>Celular</li>
                </ul>
                <ul>
                  <li>
                    {usuario?.usuario?.user?.documentos?.[0]?.nr_documento}
                  </li>
                  <li>{usuario?.usuario?.user?.nome_completo}</li>
                  <li>
                    {moment(usuario?.usuario?.user?.dt_nascimento || '').format(
                      'DD/MM/YYYY'
                    )}
                  </li>

                  <li>
                    {usuario?.usuario?.user?.sexo
                      ? usuario?.usuario?.user?.sexo
                      : ' --- '}
                  </li>
                  <li>{usuario?.usuario?.email}</li>
                  <li>{usuario?.usuario?.user?.telefone}</li>
                  <li>{usuario?.usuario?.contato_cargo}</li>
                </ul>
              </GroupList>
            </CardDados>

            <CardDados>
              <CardHeaderdados>
                <span>Perfis</span>
                <a href="#senha" onClick={() => setShowModalPerfil(true)}>
                  Editar
                </a>

                <GlobalModal
                  handleShow={showModalPerfil}
                  setHandleShow={setShowModalPerfil}
                  titleModal="Dados pessoais"
                  dialogClassName="modal-50w"
                >
                  <Form>
                    <h1>Editar perfil do usuário</h1>
                    <InputRound label="Senha atual">
                      <select name="" id="" onChange={addPerfil}>
                        {cargos_disponiveis?.cargoEmpresa?.map((item) => {
                          return item?.ds_cargo === 'Prestador' ? (
                            <option key={item?.id} value={JSON.stringify(item)}>
                              {' '}
                              {item.cargo}{' '}
                            </option>
                          ) : (
                            ''
                          );
                        })}
                      </select>
                    </InputRound>
                    {perfil?.map((c) => {
                      return (
                        <ul key={c?.id}>
                          {' '}
                          <li>{c?.descricao}</li>{' '}
                          <li>
                            {' '}
                            <span onClick={() => removerPerfil(c.cargo_id)}>
                              Remover
                            </span>{' '}
                          </li>{' '}
                        </ul>
                      );
                    })}
                    <FooterForm>
                      <Button
                        bgColor="darkBlue"
                        width="150px"
                        disabled={false}
                        onClick={() => salvarPerfils()}
                      >
                        Salvar
                      </Button>
                    </FooterForm>
                  </Form>
                </GlobalModal>
              </CardHeaderdados>
              <hr />
              <GroupListPerfis>
                {cargos?.cargosUsuario?.map((item) => (
                  <span key={item?.id}>{item?.cargo?.cargo}</span>
                ))}
              </GroupListPerfis>
            </CardDados>
          </LoadingContent>
        </ContentCardDados>
      </ContentDadosLine>
    </Container>
  );
};

export default DadosClinica;
