/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { ContentTermo } from './styles';

const Termos = (props) => (
  <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <strong className="text-center">
            CONDIÇÕES GERAIS DE PARCERIA COMERCIAL
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContentTermo>
          <p>
            Pelo presente instrumento particular, DOUTOR HOJE TECNOLOGIA, GESTÃO
            E SERVIÇOS LTDA., nome fantasia: DR HOJE, pessoa jurídica de direito
            privado, sociedade empresária limitada inscrita no CNPJ/MF sob o
            número 21.520.255/0001-55, com sede no SCS, Quadra 03, Bloco A, n°
            107, 1° andar, Sala 103, Edifício: ANTONIA ALVES PEREIRA DE
            SOUSA-Asa Sul, CEP 70.303-907, na cidade de Brasília, Distrito
            Federal, neste ato representada Por sua Diretora, JANAINA PEREIRA DE
            SOUSA QUEIROZ, CPF nº 832.461.341-20, adiante designada DR HOJE; e
            de outro lado , o “PARCEIRO COMERCIAL”, designado e qualificado no
            cadastro realizado no site da DR HOJE, adiante designado PARCEIRO
            COMERCIAL, firmam parceria conforme condições que seguem.
          </p>
          <strong>CLÁUSULA PRIMEIRA – DO OBJETO DA PARCERIA COMERCIAL</strong>
          <p>
            1.1. A parceria entre as Partes tem como objeto a oferta pelo
            PARCEIRO COMERCIAL dos produtos e serviços da DR HOJE à sua carteira
            de clientes.
          </p>
          <p>
            1.2. Ao se cadastrar como parceiro pessoa física ou jurídica, o
            PARCEIRO COMERCIAL terá acesso aos produtos, assinaturas e serviços
            da DR HOJE, os quais poderão ser oferecidos aos seus Clientes, desde
            que respeitada a formatação do produto ou serviços, sempre atrelado
            à marca DR HOJE.
          </p>
          <p>
            1.3. A apresentação de documentos pelo PARCEIRO COMERCIAL à DR HOJE
            poderá ser solicitada a qualquer tempo, o que deverá ser apresentado
            conforme prazo indicado pela DR HOJE.
          </p>
          <p>
            1.4. Caso tais Clientes se tornem contratantes dos produtos,
            assinaturas e serviços da DR HOJE, será devida uma remuneração ao
            PARCEIRO COMERCIAL, estabelecida neste instrumento.
          </p>
          <p>
            1.5. Uma vez que o cliente se torne Contratante, a DR HOJE será
            responsável pela implantação e toda operação dos produtos e
            serviços, bem como pelo atendimento ao cliente Contratante.
          </p>
          <strong>CLÁUSULA SEGUNDA – DA PARCERIA</strong>
          <p>
            2.1. Ao se cadastrar junto à DR HOJE como PARCEIRO COMERCIAL, este
            deve observar todas as disposições do presente instrumento.
          </p>
          <p>
            2.2. Cada PARCEIRO COMERCIAL, Pessoa Física que pode ou não estar
            vinculados à um PARCEIRO COMERCIAL Pessoa Jurídica, terá um link
            individualizado, por CPF (cadastro de pessoa física), onde haverá a
            formalização ou cadastro das vendas realizadas.
          </p>
          <p>
            2.3. O PARCEIRO COMERCIAL Pessoa Jurídica, após seu cadastro, poderá
            incluir todas as Pessoas Físicas à ela vinculada, as quais também
            terão seu link individualizado por CPF.
          </p>
          <p>
            2.4. Os produtos e serviços objeto da comercialização serão
            prestados exclusivamente pela DR HOJE à Contratante, sem
            interferência do PARCEIRO COMERCIAL.
          </p>
          <p>
            2.5. O PARCEIRO COMERCIAL não poderá elaborar propostas ou
            apresentações dos produtos ou serviços da DR HOJE sem seu
            conhecimento, o que será obrigatoriamente formalizado.
          </p>
          <p>
            2.6. A assinatura deste Contrato não pressupõe exclusividade de uma
            Parte a outra, podendo as Partes firmar Contratos similares a este
            ou atuar diretamente no mesmo mercado, a seu exclusivo critério.
          </p>
          <strong>CLÁUSULA TERCEIRA – OBRIGAÇÕES DA DR HOJE</strong>
          <p>
            3.1. São obrigações da DR HOJE, além de outras previstas neste
            instrumento:
          </p>
          <p>
            a) Disponibilizar o material digital contendo os produtos e a marca
            DR HOJE, necessário ao oferecimento pelo PARCEIRO COMERCIAL aos
            Clientes;
          </p>
          <p>
            b) Prestar os serviços contratados com zelo e estrutura técnica
            especializada;
          </p>
          <p>
            c) Assessorar o PARCEIRO COMERCIAL na venda dos produtos e serviços
            nos termos do presente Instrumento.
          </p>

          <strong>CLÁUSULA QUARTA – OBRIGAÇÕES DO PARCEIRO COMERCIAL</strong>

          <p>
            4.1 – São obrigações do PARCEIRO COMERCIAL, além de outras previstas
            neste Contrato:
          </p>

          <p>
            a) Utilizar com zelo e diligência o material fornecido e a marca DR
            HOJE;
          </p>
          <p>
            b)Oferecer a Proposta referente aos produtos e serviços da DR HOJE
            exatamente da forma em que foi disponibilizada;
          </p>

          <p>
            c) Prestar os serviços, por meio de mão-de-obra tecnicamente
            habilitada e capacitada para exercer as suas funções no fiel
            cumprimento das condições deste contrato.
          </p>
          <p>
            d) Arcar com todos os ônus e encargos, inclusive tributos,
            contribuições federais, estaduais, municipais e previdenciárias,
            relacionados com sua atividade e aos profissionais por ela
            utilizados na prestação de serviços objeto deste contrato, sem
            direito a qualquer tipo de ressarcimento, reembolso ou remuneração
            adicional, comprometendo-se, ainda, a fornecer, sempre que
            solicitado pela DR HOJE comprovante de pagamento de tais encargos.
          </p>

          <p>
            e) O PARCEIRO COMERCIAL, Pessoa Jurídica, será responsável por
            remunerar sua equipe de vendas, sendo que os parceiros desta equipe
            deverão ser cadastrados pelo PARCEIRO COMERCIAL em seu painel na
            área logada da DR HOJE.
          </p>

          <p>
            f) Cada parceiro cadastrado pelo PARCEIRO COMERCIAL terá o registro
            de suas vendas identificadas por seu CPF, em seu link
            individualizado.
          </p>

          <strong>CLÁUSULA QUINTA– REMUNERAÇÃO</strong>

          <p>
            5.1. A remuneração do PARCEIRO COMERCIAL, Pessoa Física diretamente
            cadastrada ou Pessoa Jurídica, quando houver contratação dos
            produtos e serviços pelos Clientes por ele indicados formalmente e
            aceitos pela DR HOJE, será realizada e devida após o recebimento
            pela DR HOJE dos valores pactuados junto aos clientes.
          </p>

          <p>
            5.1.1. A DR HOJE pagará ao PARCEIRO COMERCIAL comissão pelas vendas
            aos clientes, pessoas físicas, dos produtos TODOS, MAIS e TOTAL,
            realizadas nos percentuais abaixo, calculados sobre as mensalidades
            efetivamente pagas pelos CLIENTES que efetivarem a assinatura dos
            referidos produtos da DR HOJE:
          </p>

          <p>
            a) 100% (cem por cento) de agenciamento da primeira mensalidade;
          </p>

          <p>
            b) 5% (cinco por cento) de comissão mensal, a partir da segunda
            mensalidade;
          </p>

          <p>
            5.2. A comissão acima ajustada será paga mediante a apresentação de
            nota fiscal, por meio de depósito bancário em conta indicada pelo
            PARCEIRO COMERCIAL e conforme calendário abaixo:
          </p>

          <p>
            5.3. Os pagamentos estabelecidos serão efetuados mediante a previa
            apresentação de nota fiscal emitida pelo PARCEIRO COMERCIAL, e serão
            efetuados mediante depósito em conta corrente de titularidade deste.
          </p>

          <p>
            5.4. O comprovante do depósito valerá como recibo e prova de
            pagamento, operando-se a quitação com a regular disponibilização do
            crédito ao PARCEIRO COMERCIAL.
          </p>

          <p>
            5.5. A comissão para a comercialização de produtos empresariais será
            definida posteriormente e formalizado pela DR HOJE.
          </p>

          <strong>CLÁUSULA SEXTA – DA VIGÊNCIA E RESCISÃO</strong>

          <p>
            6.1. A presente parceria comercial vigorará por 12 (doze) meses a
            contar do cadastro do PARCEIRO COMERCIAL, ficando automaticamente
            renovado por iguais e sucessivos períodos, até que uma das Partes
            notifique a outra com 60 (sessenta) dias de antecedência a respeito
            da rescisão, sem qualquer ônus às Partes.
          </p>

          <p>
            6.2. A presente parceria comercial fica imediatamente rescindido,
            mediante simples notificação, nos seguintes casos:
          </p>

          <p>
            a) Descumprimento das obrigações previstas neste instrumento, caso
            não seja sanada a falta no prazo de 30 (trinta) dias a contar do
            recebimento da notificação, ressalvadas as indenizações pelas perdas
            e danos à parte prejudicada;
          </p>

          <p>
            b) Declaração de estado de insolvência, dissolução, liquidação,
            falência ou recuperação judicial de qualquer das Partes;
          </p>

          <p>
            c) Venda, cessão ou transferência desta parceria por qualquer das
            Partes sem o consentimento prévio da outra Parte.
          </p>

          <p>
            6.3. Todas as cláusulas e condições desse instrumento permanecerão
            válidas e em vigor até o seu término efetivo, com exceção das
            condições que por sua natureza devam permanecer em vigor mesmo após
            o término de vigência, tais como as condições de Confidencialidade,
            dentre outras.
          </p>

          <p>
            6.4. Qualquer que seja o motivo do término da presente parceria, o
            PARCEIRO COMERCIAL deverá cessar imediatamente o uso da marca,
            logomarca, material fornecido ou qualquer outra propriedade
            intelectual da DR HOJE, bem como deixar de utilizar os produtos e
            serviços da DR HOJE, acarretando na perda pelos Contratantes dos
            eventuais benefícios negociados junto à DR HOJE.
          </p>

          <strong>CLÁUSULA SÉTIMA – DA RESPONSABILIDADE E INDENIZAÇÃO</strong>
          <p>
            7.1. As Partes serão responsáveis pelo cumprimento e observância das
            obrigações imputadas a cada uma delas pela legislação em vigor,
            especialmente, o Código Civil, o Código de Defesa do Consumidor e as
            normas editadas pelos órgãos reguladores, inclusive dentro dos
            preceitos da boa-fé e ética profissional, arcando isoladamente, com
            as cominações legais e regulatórias decorrentes do descumprimento e
            inobservância das referidas disposições a que derem causa.
          </p>

          <p>
            7.2 Cada uma das Partes será responsável, exclusivamente, pela
            manutenção dos seus livros sociais, estatutos e pela sua
            escrituração mercantil, inclusive pela exatidão e regularidade dos
            registros e lançamentos contábeis respectivos, incluindo as
            operações que forem efetivadas no âmbito deste Contrato, tudo de
            acordo com as normas legais e regulamentares e seguindo os
            princípios contábeis usualmente aceitos.
          </p>

          <p>
            7.3. Cada Parte será responsável, civil e criminalmente, por todo e
            qualquer dano ou prejuízo causado à outra Parte ou a terceiros, em
            decorrência de atos ou omissões de seus respectivos prepostos ou
            subcontratados, obrigando-se, inclusive a indenizar a Parte
            prejudicada. Tal obrigação se estende às informações repassadas de
            forma equivocada e errôneas de uma Parte à outra, desde que
            comprovada a sua culpa exclusiva.
          </p>

          <p>
            7.4. Cada uma das Partes deverá defender, indenizar e eximir a outra
            parte (inclusive seus sucessores e respectivos diretores,
            administradores, gerentes, funcionários, prepostos, subcontratados
            ou representantes) de quaisquer danos, perdas, custos, despesas,
            dívidas, passivos, responsabilidades ou obrigações de natureza
            civil, comercial, trabalhista, tributária, previdenciária, bem como
            de naturezas diversas, relacionadas, direta ou indiretamente, ao
            objeto do presente Contrato, incluindo, entre outros, honorários
            advocatícios, custas e despesas judiciais, juros e multas incorridos
            por uma das Partes como resultado de atos ou omissões exclusivos da
            outra Parte e/ou seus contratados, inclusive no caso de
            reivindicações ou ações judiciais sofridas em consequência de
            qualquer inadimplemento, violação deste Contrato ou de quaisquer
            normas aplicáveis.
          </p>

          <p>
            7.5. Caso uma das Partes seja demandada judicialmente ou
            extrajudicialmente por responsabilidades da outra Parte, a Parte
            inocente notificará a Parte responsável, no prazo de 48 (quarenta e
            oito) horas a contar do recebimento da demanda para que esta ocupe o
            polo passivo da demanda de forma imediata, sendo que não sendo
            possível sua exclusão, haverá reembolso das despesas pela Parte
            inocente arcada. Da mesma forma, qualquer desembolso financeiro
            realizado pela Parte inocente de responsabilidade exclusiva da Parte
            responsável deverá ocorrer no prazo máximo de 5 (cinco) dias úteis a
            contar do recebimento dos comprovantes de pagamento.
          </p>

          <strong>CLÁUSULA OITAVA – DA CONFIDENCIALIDADE</strong>

          <p>
            8.1. O PARCEIRO COMERCIAL reconhece que, em virtude da natureza do
            presente instrumento, poderá ter acesso, voluntária ou
            involuntariamente, a informações exclusivas ou confidenciais da DR
            HOJE, de seus clientes ou de terceiros e, portanto, obriga-se, por
            si e pela equipe de trabalho a manter o mais absoluto sigilo,
            abstendo-se de copiar, reproduzir, vender, ceder, licenciar,
            comercializar, alienar, transferir ou dispor toda e qualquer
            operação, dados, materiais, desenhos, fotografias, gráficos,
            projetos, plantas, informações, documentos, especificações técnicas
            ou comerciais, inovações e aperfeiçoamento tecnológico ou comercial
            da DR HOJE, de clientes ou de terceiros ligados à DR HOJE, inclusive
            quaisquer programas, rotinas, arquivos relativos à organização
            interna da DR HOJE, métodos de trabalho desenvolvidos ou utilizados
            em decorrência deste Contrato, estratégias e metodologias de
            negócios da DR HOJE, seus parceiros e/ou clientes que o PARCEIRO
            COMERCIAL venha a ter acesso por força do cumprimento do objeto
            deste Contrato ou que seja revelada, fornecida, comunicada,
            adquirida, seja verbalmente ou por escrito ou em forma eletrônica
            (“Informações Confidenciais”) sob pena de arcar com as perdas e
            danos que der causa, por infringência das disposições dessa
            Cláusula.
          </p>

          <p>
            8.2. O dever de sigilo aqui previsto não será aplicável a
            Informações Confidenciais que: (i) sejam de domínio público antes de
            sua revelação ao PARCEIRO COMERCIAL; (ii) torne-se de domínio
            público, após o seu recebimento pelo PARCEIRO COMERCIAL, por
            qualquer meio que não uma violação das obrigações previstas neste
            Contrato; ou (iii) devam ser reveladas por força de ordem judicial
            expedida pelo juízo competente, devendo, neste caso notificar a DR
            HOJE e no caso de não ser possível impedir a divulgação,
            disponibilizar tão somente a informação exigida.
          </p>

          <p>
            8.3. O dever de sigilo e confidencialidade aqui previstos
            permanecerá em vigor mesmo após o término ou extinção da presente
            parceria pelo prazo de 05 (cinco) anos contados da data de seu
            término ou extinção.
          </p>

          <p>
            8.4. O PARCEIRO COMERCIAL compromete-se também a (i) fazer constar
            idêntica obrigação prevista nesta instrumento em todos os contratos
            que celebrar para a execução dos serviços aqui acordados, inclusive
            nos contratos de trabalho individuais ou termo de confidencialidade
            próprios, neles incluindo cláusula de sigilo e confidencialidade que
            obrigue os profissionais ou pessoas envolvidas no Contrato, a não
            transmitir as Informações Confidenciais, direta ou indiretamente, a
            quem quer que seja, nos termos deste Contrato.
          </p>

          <strong>CLÁUSULA NONA – DA RESPONSABILIDADE TRABALHISTA</strong>

          <p>
            9.1 Fica estipulado que por força deste contrato não se estabelece
            qualquer espécie de vínculo empregatício de responsabilidade da DR
            HOJE, com relação aos funcionários, corretores e prestadores do
            PARCEIRO COMERCIAL, correndo por conta exclusiva desta última todas
            as despesas com esse pessoal, sejam ou não empregados seus,
            inclusive encargos decorrentes da legislação vigente seja
            trabalhista, previdenciária, securitária, fundiária ou qualquer
            outra, além de quaisquer obrigações não pecuniárias decorrentes da
            legislação trabalhista e previdenciária em vigor.
          </p>

          <p>
            9.2 O PARCEIRO COMERCIAL, neste ato, responsabiliza-se em caráter
            irretratável e irrevogável, por quaisquer reclamações trabalhistas
            ou qualquer outro ato de natureza administrativa ou judicial,
            inclusive de acidente de trabalho que venham a ser intentadas por
            seus respectivos empregados, prepostos e/ou colaboradores contra a
            DR HOJE, em especial aqueles destacados para a execução dos serviços
            objeto deste contrato, a qualquer tempo, seja a que título for,
            respondendo integralmente pelo pagamento de eventuais condenações,
            indenizações, multas, honorários advocatícios, custas processuais e
            demais encargos que houver, ingressando na ação, mediante aviso
            expresso da DR HOJE, podendo, caso não tenham ingressado
            espontaneamente, ser denunciada em qualquer ação que for proposta
            para indenizar seus autores, aplicando-se ao presente contrato o
            disposto no Código de Processo Civil Brasileiro.
          </p>

          <p>
            9.3 O PARCEIRO COMERCIAL não poderá, no presente ou no futuro,
            alegar em juízo, para se eximir de suas responsabilidades, que a
            defesa promovida pela DR HOJE foi malfeita ou que o acompanhamento
            foi insatisfatório.
          </p>

          <p>
            9.4 O PARCEIRO COMERCIAL reconhece, desde já, como seu débito
            líquido e certo para com a DR HOJE, o valor que for apurado em
            execução de sentença de processo trabalhista eventualmente impetrado
            por ex-empregado ou preposto, ou o valor que for ajustado entre a DR
            HOJE e o reclamante, na hipótese de acordo efetuado nos autos de
            processo trabalhista.
          </p>

          <p>
            9.5 As despesas processuais e honorários advocatícios despendidos
            pela DR HOJE nas ações decorrentes deste contrato serão única e
            exclusivamente suportadas pelo PARCEIRO COMERCIAL, servindo os
            comprovantes, guias ou notas, como valor de débito líquido e certo
            em favor da DR HOJE.
          </p>

          <strong>
            CLÁUSULA DÉCIMA – CESSÃO DE DIREITOS, INALIENABILIDADE E NÃO
            ONERAÇÃO
          </strong>

          <p>
            10.1. O PARCEIRO COMERCIAL não poderá transferir, vender ou ceder
            direitos oriundos deste instrumento sem o prévio consentimento da DR
            HOJE. Qualquer tentativa de cessão de direitos sem a prévia
            aprovação por escrito da outra parte será considerada nula de pleno
            direito.
          </p>
          <p>
            10.2. A presente parceria poderá ser cedida ou transferida, no todo
            ou em parte, temporária ou permanentemente, pela DR HOJE, a empresa
            de seu grupo empresarial, assim entendido como conjunto de empresas
            e sociedades controladas direta ou indiretamente pelos controladores
            diretos e indiretos da DR HOJE, devendo assim informar ao PARCEIRO
            COMERCIAL a ocorrência deste evento.
          </p>
          <p>
            10.3. Os direitos ora outorgados pelas partes por este instrumento,
            são concedidos em caráter inalienável. O PARCEIRO COMERCIAL não
            poderá alienar, dar em garantia ou gravar qualquer ônus a este
            instrumento, sendo vedado todo e qualquer direito ou vantagem
            proveniente deste instrumento que não esteja escrito e firmado entre
            as partes, pois caso contrário, tais atos serão nulos de pleno
            direito, facultando a invalidação desses atos e consequentemente do
            contrato ora firmado.
          </p>
          <strong>
            CLÁSULA DÉCIMA PRIMEIRA – ENTENDIMENTO TOTAL E MODIFICAÇÃO
          </strong>

          <p>
            11.1. Este instrumento contém o total entendimento entre as partes,
            com relação ao objeto do mesmo, sendo que qualquer prévio acordo ou
            entendimento com relação ao mesmo objeto será substituído pelos
            termos e condições deste instrumento a partir do início da sua
            vigência.
          </p>

          <p>
            11.2. Este contrato contém todas as avenças firmadas entre as partes
            e não há outras representações, garantias, entendimentos ou acertos,
            verbais ou escritos, de qualquer natureza, fora do mesmo.
          </p>

          <p>
            11.3. Nenhuma modificação, de qualquer natureza, de qualquer parte
            deste instrumento será eficaz a menos que tenha sido realizada por
            escrito e devidamente assinada por todas as partes; o mesmo se
            aplica para qualquer adição ou subtração de qualquer dispositivo
            deste acordo. Nenhum termo ou dispositivo deste instrumento será
            alterado ou modificado, por qualquer conduta ou ato de quaisquer das
            partes, anterior, contemporâneo ou posterior, com exceção de que as
            partes poderão, doravante, alterar este contrato por escrito,
            referindo-se especificamente a este instrumento aqui firmado pelas
            partes, não podendo ser realizados outros instrumentos que de alguma
            forma alterem ou modifiquem este instrumento.
          </p>

          <strong>CLÁUSULA DÉCIMA SEGUNDA – RENÚNCIA</strong>

          <p>
            12.1 Qualquer falha de qualquer das partes em fazer cumprir, a
            qualquer tempo, algum dispositivo deste instrumento, ou ainda,
            quaisquer direitos ou reparações com respeito ao mesmo em exercer
            qualquer eleição nele prevista, constituirá renúncia a este
            dispositivo, direito, reparação ou eleição, nem afetará a validade
            de qualquer outro dispositivo deste contrato.
          </p>

          <p>
            12.2 O exercício, por qualquer das partes, de qualquer de seus
            direitos, reparação ou eleições, nos termos deste contrato, não
            prejudicará ou tornará precluso o direito da outra parte em exercer,
            a qualquer tempo, o mesmo direito ou outro direito, reparação ou
            eleição, que ele possa ter nos termos do quanto pactuado.
          </p>

          <strong>
            CLÁUSULA DÉCIMA TERCEIRA – DA PROPRIEDADE INTELECTUAL E USO DAS
            MARCAS
          </strong>

          <p>
            13.1. A DR HOJE é a proprietária exclusiva e única possuidora dos
            direitos referentes às marcas, logotipos, patentes, signos,
            símbolos, tecnologia, métodos e sistemas relativos à comercialização
            dos produtos e serviços, e quaisquer sinais distintivos empregados
            para a divulgação e promoção dos produtos e serviços objeto deste
            Instrumento, ficando o PARCEIRO COMERCIAL proibido de utilizá-los
            para qualquer outra finalidade que não a sua comercialização ou
            intermediação, e obrigando-se, em caso de término da presente
            avença, a não mais utilizá-los para qualquer finalidade, sob pena de
            responder pelas perdas e danos daí decorrentes, sem prejuízo de
            outros consectários nas esferas civil, criminal e administrativa.
          </p>
          <p>
            13.2. Durante a vigência deste contrato, a DR HOJE concede ao
            PARCEIRO COMERCIAL, em caráter temporário e precário, e
            especificamente para os fins previstos, o direito de uso das marcas,
            logotipos, patentes, signos, símbolos e quaisquer sinais distintivos
            que sejam de sua propriedade.
          </p>

          <p>
            13.3. A utilização de qualquer material promocional para a
            comercialização ou intermediação dos produtos e serviços aqui
            previstos está condicionada à prévia e expressa autorização da DR
            HOJE.
          </p>

          <strong>CLÁUSULA DÉCIMA QUARTA – DA POLÍTICA ANTICORRUPÇÃO</strong>

          <p>
            14.1. As Partes seus diretores, empregados, agentes, representantes,
            subcontratados, consultores e outras pessoas que agem em nome das
            Partes, direta e indiretamente, cumprirão as diretrizes da Lei n.
            12.846/2013, declarando não oferecer, prometer ou pagar, dar, ou
            autorizar o pagamento de qualquer valor a qualquer funcionário
            público, servidor, pessoas equiparadas (“Agente Governamental”),
            governo ou autoridade pública nacional ou estrangeira, com a
            finalidade de influenciar qualquer ato ou decisão do referido Agente
            Governamental no exercício de suas funções em relação ao presente
            contrato, seus aditivos ou qualquer outra questão que possa
            influenciar o cumprimento deste instrumento.
          </p>

          <strong>
            CLÁUSULA DÉCIMA QUINTA – DAS RESPONSABILIDADES DAS PARTES NO
            TRATAMENTO E NA PROTEÇÃO DOS DADOS PESSOAIS
          </strong>

          <p>
            15.1 As PARTES atestam o entendimento da corresponsabilidade na
            proteção de dados pessoais dos titulares vinculados a prestação de
            serviços regida por este Instrumento, perante a Lei Geral de
            Proteção de Dados (LGPD) e as demais legislações aplicáveis;
          </p>

          <p>
            15.2 As PARTES declaram, por este Instrumento, por si, por seus
            representantes, colaboradores e por quaisquer terceiros que por sua
            determinação participem da prestação de serviços objeto desta
            relação, que cumpre toda a legislação aplicável sobre privacidade e
            proteção de dados, inclusive (sempre e quando aplicáveis) a
            Constituição Federal, o Código de Defesa do Consumidor, o Código
            Civil, o Marco Civil da Internet (Lei Federal n. 12.965/2014), seu
            decreto regulamentador (Decreto 8.771/2016), a Lei Geral de Proteção
            de Dados (Lei Federal n. 13.709/2018), e demais normas setoriais ou
            gerais sobre o tema e assume as seguintes responsabilidades:
          </p>

          <p>
            a) Tratar os dados pessoais/pessoais sensíveis atendendo aos
            princípios da finalidade, adequação, necessidade, transparência,
            livre acesso, segurança, prevenção e não discriminação.
          </p>

          <p>
            b) Manter absoluto sigilo sobre quaisquer dados, documentos,
            informações técnicas, comerciais ou pessoais que venha a ter
            conhecimento, acesso, ou que lhes venha a ser confiados, não
            podendo, sob qualquer pretexto, direta ou indiretamente, divulgar,
            revelar, reproduzir, utilizar ou dar conhecimento de tais
            informações a terceiros, ressalvados os casos definidos em lei ou
            por expressa determinação judicial, inclusive firmando um termo de
            compromisso com todos os seus colaboradores que vierem a ter acesso
            a dados pessoais de titulares.
          </p>

          <p>
            c) Atender solicitações de tratamento de dados feitas pelo TITULAR,
            e, em caso de qualquer solicitação do TITULAR que requeira ação das
            PARTES, a PARTE que receber a solicitação deve informar o fato à
            outra PARTE, em prazo não superior a quarenta e oito horas úteis,
            garantindo a ciência da mesma, a fim de garantir os direitos do
            titular.
          </p>

          <p>
            d) Responder pelos danos que eventualmente vir a causar, devidamente
            comprovada a sua responsabilidade, decorrente do descumprimento das
            instruções lícitas dadas entre as PARTES e/ou deste
            contrato/convênio, em relação às cláusulas da Lei Geral de Proteção
            de Dados (LGPD) e demais legislações aplicáveis;
          </p>

          <p>
            e) Indenizar a outra PARTE contra qualquer responsabilidade, dano,
            prejuízo, custo, e despesas, incluindo, mas não se limitando, os
            devidos honorários advocatícios, as multas, e penalidades, ou custos
            investigativos relativos a demandas que surgirem em razão do
            descumprimento das instruções lícitas dadas entre as PARTES e/ou
            deste contrato, em relação às cláusulas da LGPD e demais legislações
            aplicáveis;
          </p>

          <p>
            f) Considerar como parte integrante do presente instrumento
            eventuais regulamentações, atos normativos, procedimentos,
            orientações ou pareceres expedidos pela Autoridade Nacional de
            Proteção de Dados.
          </p>

          <p>
            g) Em caso de modificação em norma que regule o tratamento de dados
            pessoais objeto do presente contrato, capaz de afetar a estrutura da
            prestação de serviços ou a execução das atividades ligadas a este
            Contrato, as PARTES deverão adotar as medidas necessárias, para
            adequar-se às condições vigentes.
          </p>

          <p>
            15.3 A DR HOJE, na prestação de serviço que é objeto deste
            instrumento, e em conformidade com a Lei Geral de Proteção de Dados
            (LGPD), assume as seguintes responsabilidades no papel de agente
            CONTROLADOR dos dados dos titulares vinculados ao PARCEIRO
            COMERCIAL:
          </p>

          <p>
            a) Tratar os dados pessoais fornecidos pelo PARCEIRO COMERCIAL, a
            que tiverem acesso, nos estritos limites previstos pela legislação,
            unicamente para os fins e pelo tempo necessário para o cumprimento
            das suas obrigações e para a adequada execução do objeto contratual,
            ou ainda, com fundamento em outra base legal válida e específica,
            não devendo praticar qualquer tipo de tratamento alheio ou estranho
            às suas funções estabelecidas, sem a prévia e expressa autorização
            ou solicitação junto ao PARCEIRO COMERCIAL.
          </p>
          <p>
            b) Considerar que as estipulações e obrigações constantes do
            presente Instrumento serão aplicadas a todos os dados que a DR HOJE
            venha a tomar conhecimento no curso das atividades prestadas, assim
            como, a toda e qualquer informação de natureza pessoal, técnica,
            administrativa, operacional, comercial, e/ou jurídica que seja
            revelada entre as PARTES do presente Contrato.
          </p>

          <p>
            c) Manter o registro das atividades de Tratamento de Dados Pessoais
            decorrentes deste instrumento;
          </p>

          <p>
            d) Não compartilhar os dados pessoais dos clientes do PARCEIRO
            COMERCIAL com terceiros ou com outras partes para finalidades que
            não façam parte da prestação dos serviços contratados ou das suas
            obrigações legais decorrentes.
          </p>

          <p>
            e) Excluir, corrigir, anonimizar e/ou bloquear o acesso aos dados
            pessoais, em caráter definitivo ou não, a critério do PARCEIRO
            COMERCIAL, ou ainda, atendendo eventual solicitação do TITULAR dos
            dados, ressalvadas as hipóteses de exceção previstas na lei, como
            por exemplo, cumprimento de obrigação legal ou regulatória ou para o
            exercício do direito de defesa em processos judiciais e
            administrativos.
          </p>

          <p>
            f) Após atingida a finalidade do Tratamento dos Dados Pessoais no
            âmbito do Contrato, eliminar os Dados Pessoais ou garantir a sua
            efetiva anonimização, salvo se, por obrigação legal, tiver que
            mantê-los ou se puder se valer de outra Base Legal adequada que
            autorize o Tratamento.
          </p>
          <p>
            g) Prover assistência ao PARCEIRO COMERCIAL, em caso de requisições,
            comunicações, esclarecimentos ou quaisquer outras ações necessárias
            perante os titulares e/ou a ANPD;
          </p>
          <p>
            h) Adotar medidas técnicas e administrativas de segurança,
            periodicamente atualizadas, aptas a proteger os dados pessoais de
            acessos não autorizados ou de situações acidentais ou ilícitas que
            possam provocar perdas, destruição, alteração ou comunicação
            indevida durante o período de transferência ou de armazenamento dos
            dados.
          </p>
          <p>
            i) A DR HOJE deverá realizar avaliações de risco e impacto próprias
            e independentes para tratamento de dados pessoais/pessoais
            sensíveis, compartilhando os riscos e medidas para mitigá-los,
            juntamente com o PARCEIRO COMERCIAL.
          </p>

          <p>
            j) Fornecer ao PARCEIRO COMERCIAL, quando solicitado, toda a
            documentação necessária a fim de demonstrar o cumprimento da LGPD e
            das demais legislações aplicáveis à proteção de dados, bem como os
            relatórios que demonstrem as atividades de tratamento de dados
            realizados em nome do PARCEIRO COMERCIAL.
          </p>

          <p>
            15.4 Caso a DR HOJE venha a contratar figura a ser equiparada a
            OPERADOR, ou seja, um subcontratado, com objetivo de realizar
            atividades relacionadas ao objeto contratado, a DR HOJE assume as
            seguintes responsabilidades:
          </p>
          <p>
            a) Estabelecer que a mesma proteção de dados e obrigações definidas
            nas cláusulas deste contrato serão impostas a esse subcontratado por
            meio de um contrato ou outro ato jurídico nos termos da legislação
            vigente, fornecendo, em particular, garantias suficientes para
            implementar medidas técnicas e organizacionais apropriadas, de modo
            que o processamento atenderá aos requisitos das cláusulas pactuadas
            entre as PARTES.
          </p>

          <p>
            b) Caso entenda necessário, o PARCEIRO COMERCIAL poderá solicitar à
            DR HOJE uma cópia do contrato pactuado junto ao subcontratado, dando
            assim a oportunidade de avaliar se as mesmas obrigações de proteção
            de dados estabelecidas entre as PARTES são impostas ao
            subcontratado, não exonerando ou atenuando o dever de cuidado da DR
            HOJE ou de seu(s) SUBCONTRATADO(s).
          </p>

          <p>
            c) No caso de descumprimento das obrigações relacionadas ao
            tratamento de dados por PARTE do subcontratado, a DR HOJE
            permanecerá totalmente responsável perante o PARCEIRO COMERCIAL
            pelas atividades de tratamento de dados exercidas pelo SUBCONTRATADO
            em todas as esferas em direito admitidas.
          </p>

          <p>
            15.5 O PARCEIRO COMERCIAL, na relação de prestação de serviços que é
            objeto deste contrato, quando a natureza do contrato assim exigir, e
            em conformidade com a LGPD, assume as seguintes responsabilidades no
            papel de agente CONTROLADOR dos dados de seus titulares:
          </p>

          <p>
            a) Garantir a autenticidade da identidade do titular de dados e
            controlar as autorizações e os consentimentos necessários junto aos
            seus titulares dos dados, inclusive no que se refere à coleta de
            dados de crianças e adolescentes, para que o tratamento de dados
            possa ser executado pela DR HOJE.
          </p>

          <p>
            b) Fornecer à DR HOJE os dados dos seus titulares suficientemente
            necessários ao escopo da prestação de serviço definido neste
            instrumento e em conformidade com as regras previstas na LGPD.
          </p>

          <p>
            c) Determinar as finalidades para o tratamento e uso dos dados
            pessoais, a base legal utilizada bem como os meios e a forma pela
            qual os dados pessoais serão tratados;
          </p>

          <p>
            d) Assumir as medidas necessárias para manutenção da qualidade dos
            dados pessoais que virão a ser compartilhados, devendo-lhes
            preservar a exatidão, clareza, atualização e relevância;
          </p>

          <p>
            e) Manter o registro das atividades de tratamento de dados pessoais
            decorrentes deste instrumento;
          </p>
          <p>
            f) Elaborar, sempre que necessário, relatório de impacto à proteção
            de dados pessoais em conformidade com as regulamentações da LGPD;
          </p>

          <p>
            g) Incluir, em suas políticas de privacidade ou outros instrumentos
            firmados com os Titulares, referências claras quanto ao tratamento e
            ao compartilhamento dos dados pessoais;
          </p>

          <p>
            h) Manter um canal de comunicação para que o titular possa exercer,
            a qualquer momento e mediante requisição, todos os seus direitos; e
          </p>
          <p>
            i) Comunicar os Titulares e a ANPD em caso de ocorrência ou suspeita
            de um Incidente que possa acarretar risco ou dano relevante aos
            Titulares.
          </p>
          <p>
            15.6 Caso o PARCEIRO COMERCIAL seja demandado por qualquer pessoa,
            autoridade ou entidade, pública ou privada, em razão de incidente
            causado pela DR HOJE ou em virtude de descumprimento das obrigações
            estabelecidas na Lei 13.709/2018 e outras regulamentações
            pertinentes, fica garantido a ENTIDADE o direito de denunciação da
            lide, ação de regresso e demais medidas necessárias para assegurar
            os seus direitos.
          </p>
          <p>
            15.7 O presente instrumento não exclui qualquer outra obrigação,
            ainda que não prevista neste termo, mas que decorra diretamente da
            LGPD e demais diplomas legais incidentes;
          </p>
          <p>
            15.8 Em caso de descumprimento de qualquer obrigação prevista neste
            contrato, ficará a PARTE infringente sujeita ao pagamento da
            competente indenização pelos prejuízos que causar, a serem apurados
            na forma da legislação vigente.
          </p>

          <strong>CLÁUSULA DÉCIMA SEXTA – DAS DISPOSIÇÕES GERAIS</strong>
          <p>
            16.1. O presente instrumento será regido e interpretado de acordo
            com a legislação brasileira vigente.
          </p>
          <p>
            16.2. A tolerância das Partes não implica em renúncia, perdão,
            novação ou alteração do pactuado neste instrumento, bem como na
            desistência de exigir o cumprimento das disposições aqui contidas ou
            do direito de requerer futuramente a total execução de cada uma das
            obrigações estabelecidas neste Contrato.
          </p>

          <p>
            16.3. Caso qualquer disposição contida neste Contrato seja
            posteriormente considerada nula, ilícita ou inexequível, a
            exequibilidade das disposições remanescentes não ficará afetada ou
            prejudicada. A disposição considerada nula, ilícita ou inexequível
            será, conforme o disposto em lei, substituída por outra, válida,
            lícita e/ou exequível, que produzirá efeitos do modo mais próximo
            possível da disposição que substituir.
          </p>

          <p>
            16.4. As condições do presente instrumento são válidas para os
            sucessores das Partes.
          </p>
        </ContentTermo>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  </>
);

export default Termos;
