export const Empresarial = () => [
  {
    produto: 'Telemedicina - Dr online',
    identificador: 'dr_online',
    tipo: 'família',
    value: 7.9,
  },
  {
    produto: 'Todos',
    identificador: 'todos',
    tipo: 'família',
    value: 21.9,
  },
  {
    produto: 'Mais',
    identificador: 'mais',
    tipo: 'pessoa',
    value: 26.5,
  },
  {
    produto: 'Total',
    identificador: 'total',
    tipo: 'pessoa',
    value: 45.5,
  },
  {
    produto: 'Saúde integral',
    identificador: 'saude_integral',
    tipo: 'pessoa',
    value: 45.5,
  },
];
export const PME = () => [
  {
    produto: 'Telemedicina - Dr online',
    identificador: 'dr_online',
    tipo: 'família',
    value: 8.9,
  },
  {
    produto: 'Todos',
    identificador: 'todos',
    tipo: 'família',
    value: 22.9,
  },
  {
    produto: 'Mais',
    identificador: 'mais',
    tipo: 'pessoa',
    value: 27.5,
  },
  {
    produto: 'Total',
    identificador: 'total',
    tipo: 'pessoa',
    value: 47.5,
  },
  {
    produto: 'Saúde integral',
    identificador: 'saude_integral',
    tipo: 'pessoa',
    value: 49.5,
  },
];
