/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import { getCleanedItemSize } from '../../config/util';

export default function InputMask({
  mask,
  onChange = () => {},
  onKeyDown = () => {},
  onPaste = () => {},
  name,
  className,
  disabled = false,
  placeHolder = '',
  value = '',
}) {
  const [checkMask, setCheckMask] = useState('');

  const maskCheck = (type) => {
    switch (type) {
      case 'cpf':
        return [
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
        ];
      case 'cnpj':
        return [
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '/',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
        ];
      case 'cep':
        return [
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ];
      case 'celular':
        return [
          '(',
          /[0-9]/,
          /[0-9]/,
          ')',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ];
      case 'telefone':
        return [
          '(',
          /[0-9]/,
          /[0-9]/,
          ')',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ];

      case 'celular_telefone': {
        const item = getCleanedItemSize(checkMask);
        if (item.size === 11) {
          return [
            '(',
            /[0-9]/,
            /[0-9]/,
            ')',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
          ];
        }
        if (item.size >= 10) {
          return [
            '(',
            /[0-9]/,
            /[0-9]/,
            ')',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            '-',
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
            /[0-9]/,
          ];
        }
        return [
          '(',
          /[0-9]/,
          /[0-9]/,
          ')',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ];
      }
      default:
        return [
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '.',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          '-',
          /[0-9]/,
          /[0-9]/,
        ];
    }
  };
  return (
    <MaskedInput
      mask={maskCheck(mask)}
      className={className}
      keepCharPositions={false}
      placeholder={placeHolder}
      value={value}
      disabled={disabled}
      onChange={(e) => {
        setCheckMask(e.target.value);
        onChange(e);
      }}
      onPaste={(e) => {
        setCheckMask(e.target.value);
        onPaste(e);
      }}
      onKeyDown={(e) => {
        onKeyDown(e);
      }}
      name={name}
      render={(refs, props) => <input ref={refs} name={name} {...props} />}
    />
  );
}
