import { PURGE } from 'redux-persist';
import {
  ENVIAR_EMAIL_REQUEST,
  ENVIAR_EMAIL_SUCCESS,
  ENVIAR_EMAIL_FAILED,
} from '~/config/constantes';

const INITIAL_STATE = {
  error: null,
  success: null,
  loading: false,
};

function EmpresaReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PURGE:
      return INITIAL_STATE;

    case ENVIAR_EMAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ENVIAR_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.payload.data,
      };
    }
    case ENVIAR_EMAIL_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload.data,
      };
    }

    default:
      return state;
  }
}

export default EmpresaReducer;
