import { all, takeLatest, put, call } from 'redux-saga/effects';
import { ENVIAR_EMAIL_REQUEST } from '~/config/constantes';
import api from '~/config/api';
import { enviarEmailFailed } from './actions';
import { RequestFailed, RequestSuccess } from '~/config/alert';

export function* EnviarEmail(payload) {
  try {
    const response = yield call(
      api.post,
      '/empresarial/vendas/enviar-email',
      payload?.payload
    );
    console.log('res', response);
    if (response?.data?.status === 200) {
      yield put(RequestSuccess(response?.data?.message));
    } else {
      yield put(RequestFailed(response?.data?.message));
    }
  } catch (error) {
    yield put(RequestFailed(error));
    yield put(enviarEmailFailed());
  }
}
export default all([takeLatest(ENVIAR_EMAIL_REQUEST, EnviarEmail)]);
