/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  FullInput,
  Label,
  ErrorMessage,
  ClearButton,
} from './styles';

export default function InputForm({
  labelName,
  children,
  className,
  required,
  validacao,
  mensagemDeErro,
  boxClass,
  disabled = false,
  loading = false,
  msgAlerta = 'Obrigatório',
  flex = null,
  onClear = () => {},
  clear = false,
}) {
  return (
    <Container flex={flex} className={boxClass}>
      <FullInput
        loading={loading}
        validacao={validacao}
        className={`full-input ${className} ${validacao && 'error'} ${
          disabled && 'disabled'
        }`}
      >
        <Label className={validacao && 'error'}>{labelName}</Label>
        {children}
        <div className="icon-container">
          <i className="loader" />
        </div>
        {clear ? (
          <ClearButton type="button" onClick={onClear}>
            Limpar
          </ClearButton>
        ) : (
          ''
        )}
      </FullInput>
      {required && (
        <>
          {validacao ? (
            <ErrorMessage>{mensagemDeErro}</ErrorMessage>
          ) : (
            <span className="msg-obrigatorio">{msgAlerta}</span>
          )}
        </>
      )}
    </Container>
  );
}

InputForm.propTypes = {
  labelName: PropTypes.string.isRequired,
  // children: PropTypes.element.isRequired,
  // eslint-disable-next-line react/require-default-props
  boxClass: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  validacao: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
  mensagemDeErro: PropTypes.string,
};
InputForm.defaultProps = {
  className: '',
  required: false,
  validacao: false,
  mensagemDeErro: 'Este campo é obrigatório',
};
