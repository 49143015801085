import React from 'react';
import { Container, Content, LabelError } from './styles';

const InputRound = ({
  label,
  children,
  error,
  warning,
  message = 'Esse campo é obrigatório',
}) => {
  return (
    <Container>
      <Content error={error} warning={warning}>
        <label htmlFor={label}>{label}</label>
        {[children]}
      </Content>
      {(error || warning) && (
        <LabelError warning={warning}>{message}</LabelError>
      )}
    </Container>
  );
};
export default InputRound;
