import styled from 'styled-components';

export const TermoInput = styled.label`
  input {
    margin-top: 4px;
    margin-right: 8px;
  }

  display: flex;
  p {
    text-align: left;
  }

  @media (max-width: 1440px) {
    p {
      text-align: left;
    }
  }
`;
