import { AlertMessageAction } from '../modules/utils/actions';
import { ExportCSV } from './excel';
import TimeQueue from './TimeQueue';

export default function GenExcel({
  hasMorePages,
  total,
  excelItem,
  objectExport,
  setDownloading,
  setPorcentagem,
  pageExcel,
  setexcelItem,
  itemMapeadoExcel,
  getItenExcel,
  variables = {},
  headers = {},
  skip = false,
  timeLapse = 10,
  authorization,
  seeMessage = false,
  custom = false,
  customFunction = () => false,
  dispatch = () => {},
}) {
  const msg = [
    'Não faça nenhuma alteração no filtro, aguarde.. ',
    'Estamos realizando o download do relatório, aguarde... ',
    'Em alguns instantes seu relatório estará disponível, aguarde... ',
    'Aguarde mais alguns instantes até que finalizamos o download do relatório, aguarde... ',
    'Não realize nenhuma ação na interface do sistema até que o download do relatório esteja completa, aguarde... ',
  ];
  const check = custom
    ? customFunction()
    : !hasMorePages && excelItem.length >= total;

  if (check) {
    pageExcel.current = 1;
    ExportCSV(objectExport);
    TimeQueue.clear();
    setDownloading(false);
    setexcelItem([]);
    setPorcentagem('...aguarde');
    dispatch(
      AlertMessageAction({
        alert: false,
        message: '',
      })
    );
  } else {
    pageExcel.current += 1;
    const item = Number(excelItem.length / total).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 2,
    });
    if (seeMessage) {
      dispatch(
        AlertMessageAction({
          alert: true,
          message: msg[Math.floor(Math.random() * 5)],
        })
      );
    }
    setPorcentagem(`${item} - ${excelItem.length} de ${total}`);

    setexcelItem((old) => [...old, ...itemMapeadoExcel]);

    TimeQueue.add(() => {
      getItenExcel({
        variables: {
          page: pageExcel.current,
          ...variables,
        },
        context: {
          headers: {
            Authorization: `${authorization}`,
            ...headers,
          },
        },
        fetchPolicy: 'no-cache',
        skip,
      });
    }, timeLapse);

    TimeQueue.start();
  }
}
