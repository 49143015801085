import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const ModalCard = styled(Modal)`
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: ${(props) => props.tamanhoCard};
      margin: 1.75rem auto;
    }
  }
`;
