import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { getCleanedItemSize } from '~/config/util';

export default function CustomInputMask({
  name,
  mask,
  maskChar = '',
  defaultValue = '',
  value = '',
  placeholder,
  className,
  onChange = () => {},
  customChange = () => {},
  customChangeDefault = () => {},
  onFocus = () => {},
  register = () => {},
}) {
  const [initValue, setInitValue] = useState(defaultValue || value);

  return (
    <InputMask
      mask={mask}
      maskChar={maskChar}
      placeholder={placeholder}
      className={className}
      onChange={(e) => {
        e.persist();
        setInitValue(e.target.value);

        onChange(e);
        customChangeDefault(e);
        customChange(getCleanedItemSize(e?.target?.value || ''));
      }}
      value={initValue}
      onFocus={onFocus}
    >
      {(props) => <input {...props} ref={register} name={name} />}
    </InputMask>
  );
}
