import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from 'apollo-link-error';
import api from '~/config/api';
import { persistor } from '~/modules';

const cache = new InMemoryCache();
export const urls = {
  test: process.env.REACT_APP_URL_API_APOLLO_TEST,
  development: process.env.REACT_APP_URL_API_APOLLO_HOMO,
  production: process.env.REACT_APP_URL_API_APOLLO_PROD,
};

export const uriCliente = {
  test: process.env.REACT_APP_URL_CLIENTE_TEST,
  development: process.env.REACT_APP_URL_CLIENTE_HOMO,
  production: process.env.REACT_APP_URL_CLIENTE_PROD,
};

const httpLink = new HttpLink({ uri: urls[process.env.NODE_ENV] });

const logoutLink = onError(({ networkError }) => {
  if (networkError?.statusCode && networkError?.statusCode === 401) {
    persistor.purge();
    localStorage.clear();
    api.defaults.headers.common.Authorization = '';
  }
});

const ClienteApollo = new ApolloClient({
  link: logoutLink.concat(httpLink),
  cache,
});

export default ClienteApollo;
