/* eslint-disable no-self-compare */
/* eslint-disable no-extend-native */

/* eslint-disable func-names */
Array.prototype.documentosProfissional = function () {
  return this.filter(
    (v, i, a) =>
      a[i].grupo_profissional &&
      a[i].grupo_profissional.indexOf(v.grupo_profissional) !== i
  );
};

String.prototype.capitalize = function () {
  return this.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

Number.prototype.applyBRLNumber = function () {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(this);
};

String.prototype.applyBRLString = function () {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(this);
};

Array.prototype.sortElement = function (key) {
  return this.sort(function compare(a, b) {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
};

Date.prototype.validateDate = function () {
  // An invalid date object returns NaN for getTime() and NaN is the only
  // object not strictly equal to itself.
  return this.getTime() === this.getTime();
};

Array.prototype.SumItemProcedimentos = function () {
  let total = 0;

  // eslint-disable-next-line no-return-assign
  this.map((item) => (total += parseFloat(item.valor)));
  return total.applyBRLNumber();
};

Array.prototype.Somaritens = function () {
  let total = 0;

  // eslint-disable-next-line no-return-assign
  this.map((item) => (total += parseFloat(item.valor)));
  return total;
};
Array.range = function (n) {
  // Array.range(5) --> [0,1,2,3,4]
  // eslint-disable-next-line prefer-spread
  return Array.apply(null, Array(n)).map((x, i) => i);
};

Object.defineProperty(Array.prototype, 'chunk', {
  value(n) {
    // ACTUAL CODE FOR CHUNKING ARRAY:
    return Array.range(Math.ceil(this.length / n)).map((x, i) =>
      this.slice(i * n, i * n + n)
    );
  },
});

String.prototype.GetQueryParams = function () {
  const args = this.substring(1).split('&');

  const argsParsed = {};

  let arg;
  let kvp;
  let key;
  let value;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < args.length; i++) {
    arg = args[i];

    if (arg.indexOf('=') === -1) {
      argsParsed[decodeURIComponent(arg).trim()] = true;
    } else {
      kvp = arg.split('=');

      key = decodeURIComponent(kvp[0]).trim();

      value = decodeURIComponent(kvp[1]).trim();

      argsParsed[key] = value;
    }
  }

  return argsParsed;
};

String.prototype.GetQueryParams = function () {
  const args = this.substring(1).split('&');

  const argsParsed = {};

  let arg;
  let kvp;
  let key;
  let value;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < args.length; i++) {
    arg = args[i];

    if (arg.indexOf('=') === -1) {
      argsParsed[decodeURIComponent(arg).trim()] = true;
    } else {
      kvp = arg.split('=');

      key = decodeURIComponent(kvp[0]).trim();

      value = decodeURIComponent(kvp[1]).trim();

      argsParsed[key] = value;
    }
  }

  return argsParsed;
};
