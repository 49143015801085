import {
  ENVIAR_EMAIL_REQUEST,
  ENVIAR_EMAIL_SUCCESS,
  ENVIAR_EMAIL_FAILED,
} from '~/config/constantes';

export const enviarEmailRequest = (payload) => ({
  type: ENVIAR_EMAIL_REQUEST,
  payload,
});

export const enviarEmailSuccess = (payload) => ({
  type: ENVIAR_EMAIL_SUCCESS,
  payload,
});
export const enviarEmailFailed = (payload) => ({
  type: ENVIAR_EMAIL_FAILED,
  payload,
});
