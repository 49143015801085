import styled from 'styled-components';

export const InfoFiltro = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 0;
  margin-top: 48px;
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
export const Busca = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  form {
    width: 100%;
    display: flex;
    align-items: flex-end;

    span {
      width: 30%;
      margin-right: 8px;
    }
  }

  @media (max-width: 1200px) {
    width: 100%;

    form {
      width: 100%;
    }
  }
`;

export const TabelaFiltro = styled.div`
  width: 100%;
  border: 1px solid #afe7f552;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #afe7f552;

  .rdt_TableCol_Sortable {
    color: #fff;
  }

  .rdt_Table .rdt_TableHeadRow .rdt_TableCol {
    background: #0439d6;
  }
`;

export const Actions = styled.div`
  display: flex;
  display: grid;
  padding: 8px;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  a:nth-child(1n) {
    color: #4260e2 !important;
  }

  a:nth-child(2n) {
    color: #4260e2 !important;
  }
`;

export const FooterForm = styled.form`
  display: flex;
  justify-content: center;

  margin-top: 16px;
`;

export const Form = styled.form`
  padding: 0 16px;

  h1 {
    font-size: 32px;
    margin-bottom: 16px;
  }

  ul {
    margin: 16px 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;

    li {
      border-bottom: 1px solid #e2e2e2;
      span {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        color: #ee0731;
        cursor: pointer;
      }
    }
  }
`;

export const ButtonControl = styled.div`
  margin: 8px 0;
`;
