import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 62px;
  width: 100%;

  @media (max-width: 800px) {
    margin: 0;
    padding: 0 12px;
  }
`;

export const Content = styled.div`
  padding-bottom: 32px;
`;

export const LineChart = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 1124px) {
    flex-wrap: wrap;
  }
`;

export const CardChart = styled.div`
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  width: 100%;
  padding: 16px;
`;

export const ChartVendas = styled.div`
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  width: 100%;

  margin-top: 40px;
  padding: 16px;
`;

export const Filtro = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  margin-bottom: 40px;

  button {
    background-size: 300% 100%;

    background-image: linear-gradient(
      to right,
      #7e0754,
      #ed0732,
      #7e0754,
      #ed0732
    );

    border-radius: 50px;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &:hover {
      background-position: 100% -100%;

      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
    }
  }

  @media (max-width: 1124px) {
    flex-wrap: wrap;
  }
`;

/** Estilizaçao de acordo com o Tipo de grafico */

/** Donut/pizza */
export const ContentDonut = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  span {
    margin-left: auto;
    font-size: 32px;
    margin-right: 8px;
    font-weight: 700;

    i {
      font-size: 32px;
      color: #00bb7b;
      margin-left: 16px;
    }
  }
`;

export const InfoDonut = styled.div`
  p {
    display: flex;
    align-items: baseline;

    h1 {
      margin-right: 8px;
      font-weight: 700;
      font-size: 32px;
    }

    strong {
      margin-left: 8px;
    }
  }
`;

/** Quantidade de corretor por equipe */

export const DadosEquipe = styled.div`
  ul {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
    margin: 16px auto;
    display: block;

    li {
      strong {
        color: #076cf1;
      }

      p {
        color: #747474;
      }
    }
  }
`;
export const HeaderCard = styled.div`
  h1 {
    font-weight: 700;
    font-size: 22px;
  }
`;
