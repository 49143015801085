import styled from 'styled-components';

export const Content = styled.div`
  margin-left: 62px;
  margin-right: 62px;
  font-size: 16px;
  width: 100%;

  h6 {
    border-bottom: 1px solid #ededed;
    line-height: 2;
    margin-top: 35px;
  }

  @media (max-width: 1440px) {
    margin: 0 32px;
  }

  @media (max-width: 700px) {
    margin: 0 16px;
  }
`;

export const ContentDadosLine = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  margin-bottom: 48px;

  @media (max-width: 1440px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
`;

export const ContentCardDados = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 94px;
  padding: 42px 33px 33px 42px;
  box-shadow: 0px 1px 4px #00000029;
  background: #fff;

  span {
    margin: 0;
    line-height: 0;
    font-size: 16px;
    color: #000000de;
  }

  @media (max-width: 1550px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 700px) {
    padding: 16px;
    gap: 32px;
  }
`;

export const ContentCardImagem = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  padding: 38px 33px 0 33px;
  box-shadow: 0px 1px 4px #00000029;
  background: #fff;

  @media (max-width: 700px) {
    padding: 16px;
  }
`;

export const CardHeaderdados = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: #707070;
  }

  a {
    margin: 0;
  }
`;

export const GroupList = styled.div`
  display: flex;
  margin-bottom: 24px;

  ul {
    list-style: none;

    li {
      width: 150px;
      padding: 2px 0;

      strong {
        i {
          margin-right: 8px;
        }
      }
    }
  }

  ul:last-child {
    list-style: none;

    li {
      width: 100%;
      padding: 2px 0;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const FotoUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 48px 0;

  figure {
    width: 91px;
    height: 91px;
    img {
      display: block;
      max-width: 100%;
    }
  }
`;

export const Form = styled.form`
  h1 {
    font-size: 22px;
    margin-bottom: 16px;
  }
`;

export const FooterForm = styled.form`
  display: flex;
  justify-content: center;

  margin-top: 16px;
`;

export const CardDados = styled.div``;
