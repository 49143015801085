import styled from 'styled-components';

export const BoxInputs = styled.div`
  display: flex;
  margin: 5px;
  width: 100%;
  justify-content: flex-end;
  align-items: right;
  flex: 1;

  span {
    color: gray;
  }
`;
export const TextField = styled.input`
  height: 36px;
  margin: 2px;
  border: none;
  background: transparent;
  flex: 1;
`;
