const routes = [
  {
    route: '/empresa',
    ordem: 1,
    name: 'Dados da Corretora',
    icon: 'far fa-building',
    empresa: false,
    perfis: [
      'Responsável Financeiro',
      'Administrador',
      'Gerente',
      'Admin Parcial',
    ],
  },
  {
    route: '/equipe-vendas',
    ordem: 2,
    name: 'Equipe de Vendas',
    icon: 'far fa-users',
    empresa: true,
    perfis: ['Responsável Financeiro', 'Administrador', 'Gerente'],
  },
  {
    route: '/acompanhamento-vendas',
    ordem: 3,
    empresa: false,
    name: 'Acompanhar Vendas',
    icon: 'far fa-money-check-edit-alt',
    perfis: ['Responsável Financeiro', 'Administrador', 'Gerente'],
  },
  {
    route: '/material-apoio',
    ordem: 4,
    empresa: false,
    name: 'Material de apoio',
    icon: 'far fa-file-invoice',
    perfis: ['Responsável Financeiro', 'Administrador', 'Gerente'],
  },
];
export default routes;
