import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import InputRound from '~/components/input_round';
import Button from '~/components/button';
import Header from '../layouts/header';
import {
  Container,
  Content,
  LineChart,
  CardChart,
  ChartVendas,
  Filtro,
  ContentDonut,
  InfoDonut,
  DadosEquipe,
  HeaderCard,
} from './styles';
import InputFilter from '~/components/input_filter';

const Dashboard = () => {
  // eslint-disable-next-line no-unused-vars
  const [dataDonutChart, setDataDonutChart] = useState({
    options: {
      Header,
    },
    series: [100, 10],
    labels: ['asdasdsa', 'E'],
    background: 'red',
  });

  const chartOptions = {
    chartLine: {
      chart: {
        type: 'bar',
        height: 350,
      },
      colors: ['#076cf1'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: ['Alex', 'Joao', 'Hugo', 'Bia'],
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter(val) {
            return `R$ ${val} Reais`;
          },
        },
      },
    },
  };

  return (
    <Container>
      <Header
        title="Dashboard"
        texto="Aqui você consegue obter os dados de vendas da sua equipe, por produto. "
      />

      <Content>
        <Filtro>
          <InputRound label="Período">
            <select>
              <option value="30">últimos 30 dias</option>
              <option value="60">últimos 60 dias</option>
              <option value="90">últimos 90 dias</option>
            </select>
          </InputRound>

          <InputRound label="Equipe">
            <select>
              <option value="1">Time 1</option>
            </select>
          </InputRound>

          <InputRound label="Produto">
            <select>
              <option value="familia">Família</option>
            </select>
          </InputRound>
          <InputFilter
            labelName="Pesquisar por: "
            placeholder=" Nome do corretor"
            type="text"
            // clear
          />
          <Button
            // disabled={LoadingAdesao || LoadingTotalAdesao}
            type="button"
            radius="22px"
          >
            aplicar
          </Button>
        </Filtro>

        <LineChart>
          <CardChart>
            <ContentDonut>
              <span>
                40%
                <i className="fad fa-chart-line" />
              </span>

              <Chart
                options={dataDonutChart.options}
                series={dataDonutChart.series}
                type="donut"
                width="350"
              />

              <InfoDonut>
                <p>
                  <h1>250</h1> vendas pela equipe
                  <strong> Time 1</strong>
                </p>
              </InfoDonut>
            </ContentDonut>
          </CardChart>

          <CardChart>
            <Chart
              options={chartOptions.chartLine}
              series={[
                {
                  name: 'Vendas',
                  type: 'column',
                  data: [80, 88, 50, 60],
                },
              ]}
            />
          </CardChart>
          <CardChart>
            <DadosEquipe>
              <HeaderCard>
                <h1>Quantidade de corretor por equipe: </h1>
              </HeaderCard>

              <ul>
                <li>
                  <strong>
                    Time 1 <i className="fad fa-users" />
                  </strong>
                  <p>68 corretores</p>
                </li>
                <li>
                  <strong>
                    Time 1 <i className="fad fa-users" />
                  </strong>
                  <p>68 corretores</p>
                </li>
                <li>
                  <strong>
                    Time 1 <i className="fad fa-users" />
                  </strong>
                  <p>68 corretores</p>
                </li>
                <li>
                  <strong>
                    Time 1 <i className="fad fa-users" />
                  </strong>
                  <p>68 corretores</p>
                </li>
                <li>
                  <strong>
                    Time 1 <i className="fad fa-users" />
                  </strong>
                  <p>68 corretores</p>
                </li>
                <li>
                  <strong>
                    Time 1 <i className="fad fa-users" />
                  </strong>
                  <p>68 corretores</p>
                </li>
              </ul>
            </DadosEquipe>
          </CardChart>
        </LineChart>

        <ChartVendas>
          <Chart
            type="area"
            options={dataDonutChart.options}
            series={[
              {
                name: 'Mais',
                data: [45, 100, 38, 45, 19, 23, 2],
              },
            ]}
            height={280}
            colors={['red', 'red', '#CED4DC']}
            dataLabels={{
              enabled: false,
            }}
            fill={{
              type: 'gradient',
              gradient: {
                background: '#323232',
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
              },
            }}
          />
        </ChartVendas>
      </Content>
    </Container>
  );
};

export default Dashboard;
