import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  @media (max-width: 700px) {
    padding-top: 4rem;
  }
`;

export const Content = styled.div`
  margin-left: 62px;
  margin-right: 48px;
  padding: 2rem 0;

  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: 1333px) {
    align-items: flex-start;

    margin-left: 32px;
    margin-right: 32px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;

    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #585858;
  }

  a {
    text-decoration: none !important;

    button {
      padding: 0.2rem 1rem;
      border: 0;
      color: #fff;
      background: red;
      border-radius: 24px;
      transition: 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  figure {
    img {
      width: 100%;
      max-width: 324px;
      height: 220px;
      display: block;
      border-radius: 1rem;
      box-shadow: 2px 2px 5px #58585863;
      border: 1px solid #a3a3a3;
    }
  }
`;
