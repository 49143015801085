import styled from 'styled-components';

export const Content = styled.div`
  margin: 16px 62px;
  padding-top: 32px;
  width: 100%;

  @media (max-width: 1600px) {
    margin: 16px 32px;
  }

  @media (max-width: 700px) {
    margin: 0;
    padding: 32px 16px;
  }
`;
